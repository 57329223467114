import React, { useState, useEffect } from "react";
import Quill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "../../css/post-job.css";
import DashboardLayout from "./DashboardLayout";

const jobModeOptions = ["Remote", "Onsite", "Hybrid"];

const skillOptions = [
  "Human Resources",
  "Recruitment",
  "Employee Relations",
  "Payroll",
  "Training and Development",
  "Compliance",
  "Finance",
  "Financial Analysis",
  "Budgeting",
  "Accounting",
  "Auditing",
  "Financial Reporting",
  "Marketing",
  "SEO",
  "Content Marketing",
  "Social Media Marketing",
  "Email Marketing",
  "PPC",
  "Branding",
  "Sales",
  "Lead Generation",
  "CRM",
  "Negotiation",
  "Sales Strategy",
  "Customer Relationship Management",
  "Information Technology (IT)",
  "Network Administration",
  "Cybersecurity",
  "Software Development",
  "Database Management",
  "Technical Support",
  "Customer Service",
  "Communication",
  "Problem-Solving",
  "CRM",
  "Conflict Resolution",
  "Customer Support",
  "Operations",
  "Process Improvement",
  "Supply Chain Management",
  "Inventory Management",
  "Operations Strategy",
  "Logistics",
  "Research and Development (R&D)",
  "Product Development",
  "Market Research",
  "Innovation",
  "Data Analysis",
  "Project Management",
  "Product Management",
  "Product Strategy",
  "Market Analysis",
  "Agile Methodology",
  "Product Lifecycle Management",
  "Roadmapping",
  "Engineering",
  "Mechanical Engineering",
  "Electrical Engineering",
  "Civil Engineering",
  "Quality Control",
  "Project Management",
  "Supply Chain Management",
  "Procurement",
  "Logistics",
  "Supplier Management",
  "Demand Planning",
  "Inventory Control",
  "Logistics",
  "Telecommunication",
  "Transportation Management",
  "Warehouse Management",
  "Distribution Planning",
  "Supply Chain Coordination",
  "Legal",
  "Contract Law",
  "Compliance",
  "Intellectual Property",
  "Litigation",
  "Corporate Law",
  "Public Relations (PR)",
  "Media Relations",
  "Press Release Writing",
  "Crisis Management",
  "Event Planning",
  "Communications",
  "Quality Assurance (QA)",
  "Quality Control",
  "Testing",
  "Compliance",
  "Auditing",
  "Process Improvement",
  "Administration",
  "Office Management",
  "Scheduling",
  "Administrative Support",
  "Document Management",
  "Communication",
  "Design",
  "Graphic Design",
  "UX/UI Design",
  "Visual Communication",
  "Adobe Creative Suite",
  "Prototyping",
  "Project Management",
  "Project Planning",
  "Risk Management",
  "Stakeholder Communication",
  "Budgeting",
  "Agile Methodology",
  "Compliance",
  "Regulatory Knowledge",
  "Risk Assessment",
  "Auditing",
  "Policy Development",
  "Reporting",
  "Training and Development",
  "Training Program Design",
  "Instructional Design",
  "E-learning",
  "Coaching",
].sort();

const experienceOptions = [
  "Fresher",
  "Early Professional",
  "Mid Level",
  "Senior",
  "Super Senior",
];

const jobTypeOptions = [
  "Full Time",
  "Part Time",
  "Contract",
  "Consultant",
  "Freelancer",
  "Internship",
];

const departmentOptions = [
  "Human Resources",
  "Finance",
  "Marketing",
  "Sales",
  "Information Technology (IT)",
  "Customer Service",
  "Operations",
  "Research and Development (R&D)",
  "Product Management",
  "Engineering",
  "Supply Chain Management",
  "Logistics",
  "Legal",
  "Public Relations (PR)",
  "Quality Assurance (QA)",
  "Administration",
  "Design",
  "Project Management",
  "Compliance",
  "Training and Development",
];

const PostJob = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [experience, setExperience] = useState("");
  const [skillset, setSkillset] = useState("");
  const [jobType, setJobType] = useState("");
  const [department, setDepartment] = useState("");
  const [jobMode, setJobMode] = useState("");
  const [state, setState] = useState("");
  const [priority, setPriority] = useState(false);
  const [numberOfOpenings, setNumberOfOpenings] = useState(1);
  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTag] = useState("");

  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((res) => res.json())
      .then((data) => {
        const countryList = data.map((country) => country.name.common).sort();
        setCountries(countryList);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  const handleAddTag = (e) => {
    // Add tag when Enter or Comma is pressed
    if (
      (e.key === "Enter" || e.key === ",") &&
      currentTag.trim() &&
      tags.length < 5
    ) {
      e.preventDefault();
      const newTag = currentTag.trim();
      if (!tags.includes(newTag)) {
        setTags([...tags, newTag]);
        setCurrentTag("");
      }
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const validateForm = () => {
    const errors = {};

    if (!title.trim()) errors.title = "Job title is required";
    if (!description.trim()) errors.description = "Job description is required";
    if (!location) errors.location = "Location is required";
    if (!experience) errors.experience = "Experience level is required";
    if (!skillset) errors.skillset = "Skillset is required";
    if (!jobType) errors.jobType = "Job type is required";
    if (!department) errors.department = "Department is required";
    if (!jobMode) errors.jobMode = "Job mode is required";
    if (numberOfOpenings < 1)
      errors.numberOfOpenings = "At least one opening is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handlePostJob = async () => {
    if (!validateForm()) {
      window.scrollTo(0, 0);
      return;
    }

    setLoading(true);

    const jobData = {
      title,
      description,
      location,
      experience,
      skillset,
      jobType,
      department,
      jobMode,
      state,
      priority,
      numberOfOpenings,
      tags,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASEURL}/api/post-job`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(jobData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Show success message
        const successMessage = document.getElementById("success-message");
        successMessage.classList.add("visible");
        setTimeout(() => {
          successMessage.classList.remove("visible");
        }, 3000);

        // Reset form
        setTitle("");
        setDescription("");
        setLocation("");
        setExperience("");
        setSkillset("");
        setJobType("");
        setDepartment("");
        setFormErrors({});
      } else {
        // Show error message
        const errorMessage = document.getElementById("error-message");
        errorMessage.textContent = data.message || "Failed to post job.";
        errorMessage.classList.add("visible");
        setTimeout(() => {
          errorMessage.classList.remove("visible");
        }, 3000);
      }
    } catch (error) {
      console.error("Error posting job:", error);
      const errorMessage = document.getElementById("error-message");
      errorMessage.textContent = "An error occurred. Please try again.";
      errorMessage.classList.add("visible");
      setTimeout(() => {
        errorMessage.classList.remove("visible");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <div className="post-job-container">
        <div id="success-message" className="notification success">
          🎉 Job posted successfully!
        </div>
        <div id="error-message" className="notification error">
          Error posting job.
        </div>

        <div className="post-job-card">
          <div className="post-job-header">
            <h2 className="post-job-heading">Post a New Job</h2>
            <p className="post-job-subheading">
              Complete all fields to create your job listing
            </p>
          </div>

          <div className="form-section">
            <h3 className="section-title">Basic Information</h3>

            <div className="form-group">
              <label htmlFor="job-title">
                Job Title <span className="required">*</span>
              </label>
              <input
                id="job-title"
                type="text"
                className={`job-input ${formErrors.title ? "error" : ""}`}
                placeholder="e.g. Senior Frontend Developer"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {formErrors.title && (
                <span className="error-text">{formErrors.title}</span>
              )}
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="job-location">
                  Location <span className="required">*</span>
                </label>
                <select
                  id="job-location"
                  className={`job-select ${formErrors.location ? "error" : ""}`}
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <option value="">Select a country</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
                {formErrors.location && (
                  <span className="error-text">{formErrors.location}</span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="job-type">
                  Job Type <span className="required">*</span>
                </label>
                <select
                  id="job-type"
                  className={`job-select ${formErrors.jobType ? "error" : ""}`}
                  value={jobType}
                  onChange={(e) => setJobType(e.target.value)}
                >
                  <option value="">Select job type</option>
                  {jobTypeOptions.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                {formErrors.jobType && (
                  <span className="error-text">{formErrors.jobType}</span>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="job-mode">
                  Job Mode <span className="required">*</span>
                </label>
                <select
                  id="job-mode"
                  className={`job-select ${formErrors.jobMode ? "error" : ""}`}
                  value={jobMode}
                  onChange={(e) => setJobMode(e.target.value)}
                >
                  <option value="">Select job mode</option>
                  {jobModeOptions.map((mode, index) => (
                    <option key={index} value={mode}>
                      {mode}
                    </option>
                  ))}
                </select>
                {formErrors.jobMode && (
                  <span className="error-text">{formErrors.jobMode}</span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="state">State (Optional)</label>
                <input
                  id="state"
                  type="text"
                  className="job-input"
                  placeholder="Enter state"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="number-of-openings">
                  Number of Openings <span className="required">*</span>
                </label>
                <input
                  id="number-of-openings"
                  type="number"
                  min="1"
                  className={`job-input ${
                    formErrors.numberOfOpenings ? "error" : ""
                  }`}
                  value={numberOfOpenings}
                  onChange={(e) =>
                    setNumberOfOpenings(parseInt(e.target.value))
                  }
                />
                {formErrors.numberOfOpenings && (
                  <span className="error-text">
                    {formErrors.numberOfOpenings}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="priority">Priority</label>
                <div className="checkbox-container">
                  <input
                    id="priority"
                    type="checkbox"
                    checked={priority}
                    onChange={(e) => setPriority(e.target.checked)}
                  />
                  <span>Mark as High Priority</span>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="tags">Tags (Max 5)</label>
              <div className="tags-container">
                <div className="tags-input-wrapper">
                  {tags.map((tag, index) => (
                    <span key={index} className="tag">
                      {tag}
                      <button
                        type="button"
                        className="tag-remove"
                        onClick={() => handleRemoveTag(tag)}
                      >
                        ×
                      </button>
                    </span>
                  ))}
                  <input
                    type="text"
                    placeholder="Add tags (Press Enter or , to add)"
                    value={currentTag}
                    onChange={(e) => setCurrentTag(e.target.value)}
                    onKeyDown={handleAddTag}
                    disabled={tags.length >= 5}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="department">
                  Department <span className="required">*</span>
                </label>
                <select
                  id="department"
                  className={`job-select ${
                    formErrors.department ? "error" : ""
                  }`}
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                >
                  <option value="">Select department</option>
                  {departmentOptions.map((dept, index) => (
                    <option key={index} value={dept}>
                      {dept}
                    </option>
                  ))}
                </select>
                {formErrors.department && (
                  <span className="error-text">{formErrors.department}</span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="experience">
                  Experience Level <span className="required">*</span>
                </label>
                <select
                  id="experience"
                  className={`job-select ${
                    formErrors.experience ? "error" : ""
                  }`}
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                >
                  <option value="">Select experience level</option>
                  {experienceOptions.map((exp, index) => (
                    <option key={index} value={exp}>
                      {exp}
                    </option>
                  ))}
                </select>
                {formErrors.experience && (
                  <span className="error-text">{formErrors.experience}</span>
                )}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="skillset">
                Required Skills <span className="required">*</span>
              </label>
              <select
                id="skillset"
                className={`job-select ${formErrors.skillset ? "error" : ""}`}
                value={skillset}
                onChange={(e) => setSkillset(e.target.value)}
              >
                <option value="">Select primary skillset</option>
                {skillOptions.map((skill, index) => (
                  <option key={index} value={skill}>
                    {skill}
                  </option>
                ))}
              </select>
              {formErrors.skillset && (
                <span className="error-text">{formErrors.skillset}</span>
              )}
            </div>
          </div>

          <div className="form-section">
            <h3 className="section-title">Job Description</h3>
            <p className="section-description">
              Provide a detailed description of the role, responsibilities, and
              requirements.
            </p>

            <div className="form-group">
              <label htmlFor="description">
                Description <span className="required">*</span>
              </label>
              <div
                className={`editor-container ${
                  formErrors.description ? "error" : ""
                }`}
              >
                <Quill
                  id="description"
                  className="job-description-editor"
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, false] }],
                      ["bold", "italic", "underline", "strike"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["blockquote", "code-block"],
                      [{ align: [] }],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                />
              </div>
              {formErrors.description && (
                <span className="error-text">{formErrors.description}</span>
              )}
            </div>
          </div>

          <div className="form-actions">
            <button
              className="secondary-button"
              onClick={() => {
                if (
                  window.confirm("Are you sure you want to clear the form?")
                ) {
                  setTitle("");
                  setDescription("");
                  setLocation("");
                  setExperience("");
                  setSkillset("");
                  setJobType("");
                  setDepartment("");
                  setFormErrors({});
                }
              }}
              disabled={loading}
            >
              Clear Form
            </button>

            <button
              className="primary-button"
              onClick={handlePostJob}
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="spinner"></span>
                  Posting...
                </>
              ) : (
                "Post Job"
              )}
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PostJob;
