import React, { useState } from "react";
import { Search, Filter, X } from "lucide-react";

const JobSearchFilter = ({
  jobs,
  onFilteredJobsChange,
  jobModeOptions,
  jobTypeOptions,
  departmentOptions,
  experienceOptions,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState({
    jobMode: "",
    jobType: "",
    department: "",
    experience: "",
    priority: "",
    startDate: "",
    endDate: "",
    location: "",
  });

  // Existing methods remain the same (applyFilters, handleFilterChange, resetFilters)
  const applyFilters = () => {
    let filteredJobs = jobs.filter((job) => {
      const matchesSearch =
        searchTerm === "" ||
        job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.skillset.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesJobMode =
        !filters.jobMode || job.jobMode === filters.jobMode;
      const matchesJobType =
        !filters.jobType || job.jobType === filters.jobType;
      const matchesDepartment =
        !filters.department || job.department === filters.department;
      const matchesExperience =
        !filters.experience || job.experience === filters.experience;
      const matchesPriority =
        filters.priority === "" ||
        (filters.priority === "high" && job.priority) ||
        (filters.priority === "normal" && !job.priority);
      const matchesLocation =
        !filters.location ||
        job.location.toLowerCase().includes(filters.location.toLowerCase());

      const jobDate = new Date(job.createdAt);
      const startDate = filters.startDate ? new Date(filters.startDate) : null;
      const endDate = filters.endDate ? new Date(filters.endDate) : null;

      const matchesDateRange =
        (!startDate || jobDate >= startDate) &&
        (!endDate || jobDate <= endDate);

      return (
        matchesSearch &&
        matchesJobMode &&
        matchesJobType &&
        matchesDepartment &&
        matchesExperience &&
        matchesPriority &&
        matchesLocation &&
        matchesDateRange
      );
    });

    onFilteredJobsChange(filteredJobs);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetFilters = () => {
    setSearchTerm("");
    setFilters({
      jobMode: "",
      jobType: "",
      department: "",
      experience: "",
      priority: "",
      startDate: "",
      endDate: "",
      location: "",
    });
    onFilteredJobsChange(jobs);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 space-y-6">
      <div className="flex items-center space-x-4">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Search jobs by title, location, or skills"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-[1300px] pl-10 pr-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm"
          />
          <Search
            size={20}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          />
        </div>

        <button
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          className="p-3 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
        >
          <Filter size={20} />
        </button>
      </div>

      {isFilterOpen && (
        <div className="bg-white border rounded-lg p-6 space-y-6">
          <div className="flex space-x-4">
            {/* Job Mode */}
            <div className="flex-1">
              <label className="block text-xs font-medium text-gray-700 mb-2">
                Job Mode
              </label>
              <select
                name="jobMode"
                value={filters.jobMode}
                onChange={handleFilterChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              >
                <option value="">All Job Modes</option>
                {jobModeOptions.map((mode, index) => (
                  <option key={index} value={mode}>
                    {mode}
                  </option>
                ))}
              </select>
            </div>

            {/* Job Type */}
            <div className="flex-1">
              <label className="block text-xs font-medium text-gray-700 mb-2">
                Job Type
              </label>
              <select
                name="jobType"
                value={filters.jobType}
                onChange={handleFilterChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              >
                <option value="">All Job Types</option>
                {jobTypeOptions.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            {/* Department */}
            <div className="flex-1">
              <label className="block text-xs font-medium text-gray-700 mb-2">
                Department
              </label>
              <select
                name="department"
                value={filters.department}
                onChange={handleFilterChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              >
                <option value="">All Departments</option>
                {departmentOptions.map((dept, index) => (
                  <option key={index} value={dept}>
                    {dept}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex space-x-4">
            {/* Experience */}
            <div className="flex-1">
              <label className="block text-xs font-medium text-gray-700 mb-2">
                Experience Level
              </label>
              <select
                name="experience"
                value={filters.experience}
                onChange={handleFilterChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              >
                <option value="">All Experience Levels</option>
                {experienceOptions.map((exp, index) => (
                  <option key={index} value={exp}>
                    {exp}
                  </option>
                ))}
              </select>
            </div>

            {/* Priority */}
            <div className="flex-1">
              <label className="block text-xs font-medium text-gray-700 mb-2">
                Priority
              </label>
              <select
                name="priority"
                value={filters.priority}
                onChange={handleFilterChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              >
                <option value="">All Priorities</option>
                <option value="high">High</option>
                <option value="normal">Normal</option>
              </select>
            </div>

            {/* Location */}
            <div className="flex-1">
              <label className="block text-xs font-medium text-gray-700 mb-2">
                Location
              </label>
              <input
                type="text"
                name="location"
                value={filters.location}
                onChange={handleFilterChange}
                placeholder="Filter by location"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              />
            </div>
          </div>

          <div className="flex space-x-4">
            {/* Start Date */}
            <div className="flex-1">
              <label className="block text-xs font-medium text-gray-700 mb-2">
                Start Date
              </label>
              <input
                type="date"
                name="startDate"
                value={filters.startDate}
                onChange={handleFilterChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              />
            </div>

            {/* End Date */}
            <div className="flex-1">
              <label className="block text-xs font-medium text-gray-700 mb-2">
                End Date
              </label>
              <input
                type="date"
                name="endDate"
                value={filters.endDate}
                onChange={handleFilterChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              onClick={resetFilters}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors flex items-center text-sm"
            >
              <X size={16} className="mr-2" /> Clear All
            </button>
            <button
              onClick={applyFilters}
              className="px-4 py-2 bg-blue-600 text-black rounded-md hover:bg-blue-700 transition-colors text-sm"
            >
              Apply Filters
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobSearchFilter;
