import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import DashboardLayout from "./DashboardLayout";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const Dashboard = () => {
  const stats = {
    usersVisited: 12500,
    openJobs: 8,
    careerApplications: 450,
    newsletters: 1200,
    contactsReceived: 250,
    BlogsPosted: 30, // ✅ Post Blogs count added
  };

  const demographicData = {
    labels: ["Asia", "Europe", "North America", "South America", "Africa"],
    datasets: [
      {
        data: [40, 25, 15, 10, 10],
        backgroundColor: [
          "#ff9999",
          "#99ccff",
          "#ffcc99",
          "#99ff99",
          "#cc99ff",
        ],
        hoverBackgroundColor: [
          "#ff7777",
          "#77bfff",
          "#ffbb77",
          "#77ff77",
          "#bb77ff",
        ],
      },
    ],
  };

  const genderData = {
    labels: ["Male", "Female", "Other"],
    datasets: [
      {
        label: "Gender Distribution",
        data: [60, 35, 5],
        backgroundColor: ["#81c784", "#ff8a65", "#ba68c8"],
      },
    ],
  };

  const statsData = [
    { label: "Users Visited", value: stats.usersVisited, color: "#f8bbd0" },
    { label: "Open Jobs", value: stats.openJobs, color: "#b3e5fc" },
    { label: "Career Applications", value: stats.careerApplications, color: "#ffccbc" },
    { label: "Newsletters", value: stats.newsletters, color: "#d1c4e9" },
    { label: "Contacts Received", value: stats.contactsReceived, color: "#c5e1a5" },
    { label: "Blogs Posted", value: stats.BlogsPosted, color: "#FFE0B2" }, // ✅ Post Blogs dynamically added
  ];

  return (
    <DashboardLayout>
      <div style={{ overflow: "hidden", minHeight: "100vh", padding: "20px", background: "linear-gradient(135deg, #f3e5f5, #e1f5fe)", color: "#333" }}>
        <h3 style={{ fontSize: "26px", fontWeight: "bold", marginBottom: "30px", color: "#222", textAlign: "center" }}>
          Admin Dashboard
        </h3>

        {/* Stats Section */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(180px, 1fr))",
            gap: "15px",
            marginBottom: "30px",
            justifyContent: "center",
          }}
        >
          {statsData.map((item, index) => (
            <div
              key={index}
              style={{
                background: item.color,
                padding: "15px",
                borderRadius: "10px",
                color: "#333",
                textAlign: "center",
                boxShadow: "0 3px 8px rgba(0, 0, 0, 0.1)",
                transition: "background 0.3s ease, transform 0.2s",
                width: "200px",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.background = "#ddd";
                e.currentTarget.style.transform = "scale(1.05)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.background = item.color;
                e.currentTarget.style.transform = "scale(1)";
              }}
            >
              <h3 style={{ fontSize: "16px", marginBottom: "6px" }}>
                {item.label}
              </h3>
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                {item.value}
              </p>
            </div>
          ))}
        </div>

        {/* Charts Section */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            flexWrap: "wrap",
            marginTop: "30px",
          }}
        >
          {/* Demographics Pie Chart */}
          <div style={{ width: "35%", background: "#ffffff", padding: "15px", borderRadius: "12px", boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)" }}>
            <h3
              style={{
                fontSize: "16px",
                marginBottom: "10px",
                textAlign: "center",
                color: "#222",
              }}
            >
              Audience Demographics
            </h3>
            <Pie data={demographicData} />
          </div>

          {/* Gender Distribution Bar Chart */}
          <div style={{ width: "35%", background: "#ffffff", padding: "15px", borderRadius: "12px", boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)" }}>
            <h3
              style={{
                fontSize: "16px",
                marginBottom: "10px",
                textAlign: "center",
                color: "#222",
              }}
            >
              Gender Distribution
            </h3>
            <Bar data={genderData} />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
