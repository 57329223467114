import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Chip,
  Paper,
  Pagination,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";
import CodeIcon from "@mui/icons-material/Code";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SEO from "../../components/SEO";

// Function to update job likes on the backend
const updateJobLikeOnServer = async (jobId, action) => {
  try {
    console.log(`Updating job ${jobId} with action: ${action}`);
    if (typeof jobId === "object") {
      jobId = jobId._id || jobId.jobId || "";
    }

    const response = await fetch(`/api/jobs/${jobId}/likes`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ action }), // 'like' or 'unlike'
    });

    if (!response.ok) {
      throw new Error(`Failed to update like status: ${response.status}`);
    }

    const data = await response.json();
    return data.likeCount; // Return the updated like count
  } catch (error) {
    console.error("Error updating like status:", error);
    throw error;
  }
};

const JobCard = ({ job, handleApply, onLikeUpdate }) => {
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(job.like_count || 0);

  // Fetch initial like status from localStorage
  useEffect(() => {
    // Get liked status from localStorage
    const likedJobs = JSON.parse(localStorage.getItem("likedJobs") || "{}");
    setLiked(likedJobs[job.jobId || job._id] || false);

    // Use the server-provided like count initially
    setLikeCount(job.like_count || 0);
  }, [job]);

  const handleLike = async (e) => {
    e.stopPropagation();
    try {
      const jobId = job.jobId || job._id;
      const action = liked ? "unlike" : "like";

      // Update like on server
      const updatedLikeCount = await updateJobLikeOnServer(jobId, action);

      // Update local state
      setLiked(!liked);
      setLikeCount(updatedLikeCount); // FIXED: Use updatedLikeCount instead of data.likeCount

      // Save user like preference to localStorage
      const likedJobs = JSON.parse(localStorage.getItem("likedJobs") || "{}");
      likedJobs[jobId] = !liked;
      localStorage.setItem("likedJobs", JSON.stringify(likedJobs));

      if (onLikeUpdate) {
        onLikeUpdate(jobId, updatedLikeCount); // FIXED: Use updatedLikeCount instead of data.likeCount
      }
    } catch (error) {
      console.error("Failed to update like:", error);
      // Local fallback if server update fails
      const newLikeCount = liked ? Math.max(0, likeCount - 1) : likeCount + 1;

      // Update local state
      setLiked(!liked);
      setLikeCount(newLikeCount);

      // Save to localStorage
      const likedJobs = JSON.parse(localStorage.getItem("likedJobs") || "{}");
      likedJobs[job.jobId || job._id] = !liked;
      localStorage.setItem("likedJobs", JSON.stringify(likedJobs));

      if (onLikeUpdate) {
        onLikeUpdate(job.jobId || job._id, newLikeCount);
      }
    }
  };

  return (
    <Card
      elevation={2}
      sx={{
        height: "100%",
        display: "flex",
        borderRadius: "10px",
        transition: "all 0.3s",
        "&:hover": { transform: "translateY(-4px)", boxShadow: 6 },
        position: "relative",
      }}
    >
      {/* Like Button */}
      <Box
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          display: "flex",
          alignItems: "center",
          zIndex: 1,
        }}
      >
  {/* ///////Number of Job Opening */}
  {job.numberOfOpenings && job.numberOfOpenings > 0 && (
  <Typography
    variant="body2"
    color="text.secondary"
    sx={{ mb: 1 }}
  >
    Openings: {job.numberOfOpenings}
  </Typography>
)}


      </Box>
      

      <CardContent
        sx={{
          flexGrow: 1,
          p: 2.5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          pb: "16px !important",
          width: "100%",
        }}
      >
        <Box>
          <Typography
            variant="h6"
            component="h2"
            noWrap
            sx={{ fontWeight: 600, fontSize: "1.1rem", mb: 1.5, pr: 5 }}
          >
            {job.title}
          </Typography>
          <SEO
            title="Explore Career Opportunities in Software Development & Cybersecurity"
            description="Join UFS Networks and grow your career in software development, AI, DevOps, and cybersecurity."
            url="https://www.ufsnetworks.com/career"
            image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
          />

          <Grid container spacing={1}>
            <Grid item xs={12} sm={7} md={8}>
              <Stack spacing={1}>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                  <Chip
                    icon={<LocationOnIcon sx={{ fontSize: "0.9rem" }} />}
                    // label={job.location}
                    label={`${job.location}${job.state ? `, ${job.state}` : ''}`}
                    size="small"
                    variant="outlined"
                  />

                  <Chip
                    icon={<WorkIcon sx={{ fontSize: "0.9rem" }} />}
                    label={job.experience}
                    size="small"
                    color="primary"
                    variant="outlined"
                  />

                  <Chip
                    icon={<CodeIcon sx={{ fontSize: "0.9rem" }} />}
                    label={job.skillset}
                    size="small"
                    color="secondary"
                    variant="outlined"
                  />
                  <Chip
                    icon={<BusinessCenterIcon sx={{ fontSize: "0.9rem" }} />}
                    label={job.jobType}
                    size="small"
                    color="info"
                    variant="outlined"
                  />

                  {/* job Mode */}

                  {job.jobMode && (
                    <Chip
                     icon={<CodeIcon sx={{ fontSize: "0.9rem" }} />}
                      label={job.jobMode}
                      size="small"
                      color="secondary"
                      variant="outlined"
                  />
                  )}
                </Stack>
              </Stack>
            </Grid>

            <Grid
              item
              xs={12}
              sm={5}
              md={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleApply(job)}
                sx={{
                  py: 0.7,
                  px: 2,
                  borderRadius: "6px",
                  fontSize: "0.85rem",
                  boxShadow: 2,
                }}
              >
                Apply Now
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

const JobListingSection = ({
  jobs,
  handleApply,
  currentPage,
  totalPages,
  handlePageChange,
  onRefreshNeeded,
}) => {
  const [updatedJobs, setUpdatedJobs] = useState({});

  const handleLikeUpdate = (jobId, newLikeCount) => {
    setUpdatedJobs((prev) => ({
      ...prev,
      [jobId]: newLikeCount,
    }));

    if (onRefreshNeeded) {
      onRefreshNeeded();
    }
  };

  const getLikeCount = (job) => {
    const jobId = job.jobId || job._id;
    return updatedJobs[jobId] !== undefined
      ? updatedJobs[jobId]
      : job.like_count || 0;
  };

  return (
    <>
      <Grid container spacing={3}>
        {jobs.length > 0 ? (
          jobs.map((job) => (
            <Grid item xs={12} key={job.jobId || job._id}>
              <JobCard
                job={{ ...job, like_count: getLikeCount(job) }}
                handleApply={handleApply}
                onLikeUpdate={handleLikeUpdate}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Paper
              elevation={2}
              sx={{ p: 4, textAlign: "center", borderRadius: "10px" }}
            >
              <Typography variant="h6" color="textSecondary">
                Please wait.
              </Typography>
            </Paper>
          </Grid>
        )}
      </Grid>

      

      {/* Fixed Pagination */}
      {jobs.length > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 4,
            mb: 2,
            overflowX: "auto",
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(e, value) => handlePageChange(value)}
            color="primary"
            shape="rounded"
            size="large"
          />
        </Box>
      )}
    </>
  );
};

export default JobListingSection;
