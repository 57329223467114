import React, { Fragment, useState } from "react";
import Services from "../../api/service";
import { useParams } from "react-router-dom";
import ModalVideo from "react-modal-video";
// import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import ProcessTechnology from "../../components/software-company-components/ProcessTechnology/ProcessTechnology";
import srImg from "../../images/services/service_details_image_1.webp";
import srImg2 from "../../images/services/service_details_image_2.webp";
import srImg3 from "../../images/services/service_details_image_3.webp";
import srImg4 from "../../images/services/service_details_image_4.webp";
import icon from "../../images/icons/icon_check_3.svg";
import { HelmetProvider } from "react-helmet-async";
import SEO from "../../components/SEO";
import Header2 from "../../components/Header2/Header2";

const ServiceSinglePage = (props) => {
  const { slug } = useParams();

  const ServiceDetails = Services.find((item) => item.slug === slug);

  const [isOpen, setOpen] = useState(false);

  return (
    <Fragment>
      <Header2 />
      <main className="page_content service-single-page">
        <PageTitle
          pageTitle={ServiceDetails.title}
          pagesub={"Details 😍"}
          pageTop={"Services"}
        />
        <SEO
          title={`UFS Networks: Comprehensive ${ServiceDetails.title}`}
          description={`Protect your business with advanced ${ServiceDetails.title} from UFS Networks. We offer tailored solutions for innovative technology`}
          url={`https://www.ufsnetworks.com/service-single/${ServiceDetails.slug}`}
          image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
          canonicalUrl={`https://www.ufsnetworks.com/service-single/${ServiceDetails.slug}`}
        />
        <section className="service_details_section section_space bg-light">
          <div className="container">
            <div className="details_item_image position-relative">
              <img src={srImg} alt="Service Details" />
              <button
                className="video_btn ripple_effect"
                onClick={() => setOpen(true)}
              >
                <span className="btn_icon">
                  <i className="fa-solid fa-play"></i>
                </span>
              </button>
            </div>
            <h2 className="details_item_title">{ServiceDetails.title}</h2>
            <p style={{ textAlign: "justify" }}>
              {ServiceDetails.title} are integral to modern businesses,
              providing the essential foundation for innovation, efficiency, and
              growth. At our IT solution agency, we specialize in delivering
              comprehensive solutions tailored to the unique needs of each
              client, ensuring seamless integration and optimal performance.
            </p>
            <p style={{ textAlign: "justify" }}>
              From small startups to global enterprises, we understand the
              significance of {ServiceDetails.title} in driving success. Our
              expert team collaborates closely with clients to design and
              implement customized strategies that address current challenges
              while preparing for future growth.
            </p>
            <p style={{ textAlign: "justify" }}>
              We take a holistic approach to {ServiceDetails.title}, focusing on
              scalability, security, performance, and adaptability. By
              leveraging industry best practices and advanced technologies, we
              deliver solutions that empower businesses to achieve their
              objectives effectively.
            </p>

            <h3 className="details_item_info_title">Services Outcome</h3>
            <p className="mb-4">
              Key benefits of our {ServiceDetails.title} include:
            </p>
            <div className="row mb-4">
              <div className="col-lg-6">
                <ul className="icon_list unordered_list_block">
                  <li>
                    <span className="icon_list_icon">
                      <img src={icon} alt="Check SVG Icon" />
                    </span>
                    <span className="icon_list_text">
                      Scalability and Flexibility
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_icon">
                      <img src={icon} alt="Check SVG Icon" />
                    </span>
                    <span className="icon_list_text">
                      Security and Compliance
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_icon">
                      <img src={icon} alt="Check SVG Icon" />
                    </span>
                    <span className="icon_list_text">
                      Performance Optimization
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul className="icon_list unordered_list_block">
                  <li>
                    <span className="icon_list_icon">
                      <img src={icon} alt="Check SVG Icon" />
                    </span>
                    <span className="icon_list_text">
                      Enhanced User Experience
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_icon">
                      <img src={icon} alt="Check SVG Icon" />
                    </span>
                    <span className="icon_list_text">
                      Future - Proofing Stratergies
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_icon">
                      <img src={icon} alt="Check SVG Icon" />
                    </span>
                    <span className="icon_list_text">
                      Comprehensive Training and Support
                    </span>
                  </li>
                </ul>
              </div>
              {/* <p>Partner with us to harness the full potential of 
                {ServiceDetails.title} and transform your business 
                for the digital era."</p> */}
            </div>
            <p style={{ textAlign: "justify" }}>
              Partner with us to harness the full potential of{" "}
              {ServiceDetails.title} and transform your business for the digital
              era."
            </p>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="details_item_image m-0">
                  <img src={srImg2} alt="Service Details" />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="details_item_image m-0">
                  <img src={srImg3} alt="Service Details" />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="details_item_image m-0">
                  <img src={srImg4} alt="Service Details" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ProcessTechnology />
      <CtaSection />
      <Footer />
      <Scrollbar />
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="k8lnfswHGKw"
        onClose={() => setOpen(false)}
      />
    </Fragment>
  );
};
export default ServiceSinglePage;
