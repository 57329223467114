import React, { useState } from 'react';
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_space_4.svg'
import shape2 from '../../../images/shapes/shape_angle_3.webp'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="faq_section section_decoration">
            <div className="container">
                <div className="heading_block text-center">
                    <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                        F.A.Q.
                    </div>
                    <h2 className="heading_text mb-0">
                        Need a <mark>Support?</mark>
                    </h2>
                </div>

                <div className="faq_accordion accordion" id="faq_accordion">
                    <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="1">
                                Q. What services does Unified Consultancy Services provide?
                            </AccordionHeader>
                            <AccordionBody accordionId="1" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                  Services Provided by Unified Consultancy Services:
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className="icon_list unordered_list_block">
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                   <strong>Software Development</strong>
                                                   <p style={{ textAlign: 'justify' }}>
                                                      We specialize in software architecture design,
                                                      system integration, data migration, and legacy app modernization to ensure robust and scalable solutions.
                                                  </p>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                   <strong>Artificial Intelligence</strong>
                                                   <p style={{ textAlign: 'justify' }}>Our AI services include automation optimization, data analysis, natural language processing, and computer vision to drive innovation and efficiency. </p>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                   <strong>Consultancy Services</strong>
                                                   <p style={{ textAlign: 'justify' }}>We offer strategic planning, operational efficiency improvements, technological implementation, and risk management advisory.</p>
                                                </span>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="icon_list unordered_list_block">
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    <strong>Cloud Technologies</strong>
                                                    <p style={{ textAlign: 'justify' }}> Our cloud solutions cover seamless cloud migration, scalable infrastructure, security compliance, and disaster recovery.</p>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    <strong>DevOps Control</strong>
                                                    <p style={{ textAlign: 'justify' }}>We provide CI/CD pipelines, infrastructure as code, monitoring, and enhanced team collaboration to streamline development and operations.</p>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    <strong>Cybersecurity</strong>
                                                    <p style={{ textAlign: 'justify' }}>Unified Consultancy Services ensures your system's safety through threat detection, security audits, data protection, and employee training for awareness.</p>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="2">
                                Q. What industries does Unified Consultancy Services serve?
                            </AccordionHeader>
                            <AccordionBody accordionId="2" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p style={{ textAlign: 'justify' }}>
                                    It collaborates with a diverse array of industries to provide tailored IT and networking solutions that meet specific business needs. In the <strong>finance sector</strong>, we help institutions enhance security, streamline operations, and enable robust data handling systems. For the <strong>healthcare industry</strong>, we offer advanced technology solutions to improve patient care, manage data securely, and ensure compliance with regulations. In <strong>education</strong>, our services empower institutions with digital learning platforms, scalable network infrastructures, and secure data management systems to foster innovation and connectivity. For the <strong>retail sector</strong>, we deliver cutting-edge solutions that optimize supply chain operations, enhance customer engagement, and enable seamless e-commerce experiences. In the <strong>technology industry</strong>, we provide high-performance solutions that support innovation, scalability, and efficient operations. Regardless of the industry, our expertise ensures that every client benefits from customized strategies designed to drive growth, efficiency, and reliability.
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="3">
                                Q. Does Unified Consultancy Services provide ongoing support after project implementation?
                            </AccordionHeader>
                            <AccordionBody accordionId="3" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p style={{ textAlign: 'justify' }}>
                                Yes, Unified Consultancy Services is committed to providing comprehensive ongoing support and maintenance after project implementation. We understand that the technology landscape is constantly evolving, and businesses need their systems to remain efficient and adaptable to changing requirements. Our support services include regular system monitoring, troubleshooting, updates, and performance optimization to ensure the implemented solutions continue to deliver maximum value. Additionally, we work closely with clients to address new challenges, integrate emerging technologies, and scale their infrastructure as their business grows. With our dedicated team of experts, clients can focus on their core operations while we handle the technical complexities, ensuring seamless and uninterrupted functionality of their IT systems.
                                </p>     
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="4">
                                Q. What HR consulting services does Unified Consultancy Services offer?
                            </AccordionHeader>
                            <AccordionBody accordionId="4" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p style={{ textAlign: 'justify' }}>
                                  Unified Consultancy Services provides comprehensive HR consulting services designed to streamline and optimize human resource operations for businesses. Our offerings include strategic workforce planning, recruitment process enhancement, employee performance management, and training program development. We focus on aligning HR strategies with overall business goals, ensuring that organizations can attract, retain, and develop top talent. Additionally, we assist in implementing HR technologies and tools to improve operational efficiency and foster a positive workplace culture, helping businesses thrive in a competitive environment.
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="5">
                                Q. How do I get started with Unified Consultancy Services?
                            </AccordionHeader>
                            <AccordionBody accordionId="5" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p style={{ textAlign: 'justify' }}>
                                  Getting started with Unified Consultancy Services is simple and straightforward. You can reach out to us via our website’s “Contact Us” page or call us directly using the contact information provided. Once you connect with our team, we will arrange an initial consultation to thoroughly understand your requirements, challenges, and objectives. Our experts will collaborate with you to analyze your specific needs and propose a customized solution that aligns with your business goals. Whether you are looking for IT solutions, cloud services, or consulting expertise, we ensure a smooth onboarding process and maintain clear communication throughout, helping you embark on a transformative journey with confidence.
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="Techco Shape Angle" />
            </div>
        </section>
    )
};
export default FaqSection;
