import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";

const ContactForm = (props) => {
  const [forms, setForms] = useState({
    FullName: "",
    email: "",
    category: '',
    phone: "",
    message: "",
  });

  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const [submissionState, setSubmissionState] = useState({
    submitting: false,
    succeeded: false,
  });

  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
    // setForms({ ...forms})
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setSubmissionState({ ...submissionState, submitting: true });

     console.log("Submitting form data: ", forms)

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_BASEURL}/api/contact-us`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(forms),
        });

        console.log("Response received:", response);

        const data = await response.json()
        
        if (response.ok) {
          console.log("✅ Form submitted successfully:", data);
          setSubmissionState({ submitting: false, succeeded: true });
        } else {
          console.error("❌ Submission failed:", data);
        }
      } catch (error) {
        console.error("Error submitting the form:", error);
      } finally {
        setSubmissionState({
          submitting: false,
          succeeded: true,
        });
        setForms({
          FullName: "",
          email: "",
          category: "",
          phone: "",
          message: "",
        });
      }
    } else {
      validator.showMessages();
    }
  };

  return (
    <form onSubmit={(e) => submitHandler(e)}>
      <div className="row">
        {submissionState.succeeded ? (
          <div className="col-12">
            <div className="alert alert-success" role="alert">
              Thank you for contacting us! We will get back to you shortly.
            </div>
          </div>
        ) : null}
        <div className="col-md-6">
          <div className="form-group">
            <label className="input_title" htmlFor="input_name">
              First & Last Name
            </label>
            <input
              value={forms.FullName}
              type="text"
              name="FullName"
              className="form-control"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="UFS Networks"
              required
            />
            {validator.message("FullName", forms.FullName, "required|alpha_space")}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="input_title" htmlFor="input_email">
              Business Email
            </label>
            <input
              value={forms.email}
              type="email"
              name="email"
              className="form-control"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="info@example.com"
              required
            />
            {validator.message("email", forms.email, "required|email")}
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label className="input_title" htmlFor="input_phone">
              Contact Number
            </label>
            <input
              value={forms.phone}
              type="phone"
              name="phone"
              className="form-control"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="+91 976542389"
              required
            />
            {validator.message("phone", forms.phone, "required|numeric|min:10|max:15")}
          </div>
        </div>
       
        <div className="col-12">
          <div className="form-group">
            <label className="input_title" htmlFor="input_textarea">
              Message
            </label>
            <textarea
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              value={forms.message}
              name="message"
              className="form-control"
              placeholder="How can we help you?"
              required
            ></textarea>
            {validator.message("message", forms.message, "required")}
          </div>
          
          <div className="col-12 mb-2">
          <div className="form-group">
            <label className="input_title" htmlFor="input_subject">
              Category
            </label>
            <select
              value={forms.category}
              name="category"
              className="form-control form-select"
              onChange={changeHandler}
            >
              <option value="">Select Category</option>
              <option value="HR-Consultancy">HR-Consultancy</option>
              <option value="Software-AI-Automation">Software AI Automation
              </option>
              <option value="IT-Consulting-Services">IT Consulting Services</option>
              <option value="Product">Product Demo</option>
            </select>
            {validator.message('category', forms.category, 'required')}
          </div>
        </div>
                     
          <button
            type="submit"
            className="btn btn-primary"
            disabled={submissionState.submitting}
          >
            <span className="btn_label" data-text="Send Message">
              {submissionState.submitting ? "Sending..." : "Send Message"}
            </span>
            <span className="btn_icon">
              <i className="fa-solid fa-arrow-up-right"></i>
            </span>
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
