import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "../Sidebar/Sidebar";
import * as XLSX from "xlsx";

const NewsLetterEmail = () => {
  const [subscribers, setSubscribers] = useState([]);

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASEURL}/api/subscribers`
        );
        setSubscribers(response.data);
      } catch (error) {
        console.error("Error fetching subscribers:", error);
      }
    };
    fetchSubscribers();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASEURL}/api/subscribers/${id}`
      );
      setSubscribers(subscribers.filter((subscriber) => subscriber._id !== id));
    } catch (error) {
      console.log("Error deleting subscriber:", error);
    }
  };

  const handleExportToExcel = () => {
    // Prepare data for export
    const dataToExport = subscribers.map((subscriber, index) => ({
      "No.": index + 1,
      Email: subscriber.email,
      "Subscribed At": new Date(subscriber.createdAt).toLocaleString(),
    }));

    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Create workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subscribers");

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "newsletter_subscribers.xlsx");
  };

  const layoutStyle = {
    display: "flex",
  };

  const contentStyle = {
    marginLeft: "250px",
    width: "100%",
    padding: "20px",
    overflowX: "auto",
  };

  return (
    <div style={layoutStyle}>
      <Sidebar />
      <div style={contentStyle}>
        <div className="container-fluid">
          <h2 className="mb-4">Newsletter Subscribers</h2>
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Subscriber List</h5>
              <button
                className="btn btn-success"
                onClick={handleExportToExcel}
                disabled={subscribers.length === 0}
              >
                <i className="fa fa-download mr-1"></i> Download as Excel
              </button>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead className="table-dark">
                    <tr>
                      <th>#</th>
                      <th>Email</th>
                      <th>Subscribed At</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscribers.length > 0 ? (
                      subscribers.map((subscriber, index) => (
                        <tr key={subscriber._id}>
                          <td>{index + 1}</td>
                          <td>{subscriber.email}</td>
                          <td>
                            {new Date(subscriber.createdAt).toLocaleString()}
                          </td>
                          <td>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => handleDelete(subscriber._id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No subscribers found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetterEmail;
