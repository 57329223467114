// src/components/JobDetails.js
import React from "react";
import { Card, CardContent, Typography, Grid, IconButton ,Chip ,Box ,Stack} from "@mui/material";
import { Facebook, Twitter, LinkedIn, ContentCopy } from "@mui/icons-material";
import { HelmetProvider } from "react-helmet-async";
import SEO from "../SEO";

const JobDetails = ({ job, onCopyLink }) => {
  const jobUrl = window.location.href;
  const encodedJobUrl = encodeURIComponent(jobUrl);

  return (
    <Card elevation={3} sx={{ marginY: 3, padding: 2 }}>
      <SEO
        title={`Explore Career Opportunities with UFS`}
        description="Join UFS Networks and grow your career in software development, AI, DevOps, and cybersecurity. Explore exciting job opportunities and work with innovative technology."
        url={`${encodedJobUrl}`}
        image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
      />
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {job.title}
        </Typography>


<Box sx={{ display: "flex", marginBottom: 2, flexWrap: "wrap" }}>
  <Stack
    direction={{ xs: "column", sm: "row" }} 
    spacing={1}
    flexWrap="wrap"
    sx={{
      gap: 1, // Add space between chips
      width: "100%", // Ensure Stack takes full width
    }}
  >
    <Chip label={`Location: ${job.location}${job.state ? `, ${job.state}` : ''}`} color="primary" variant="outlined" sx={{ marginBottom: 1 }} />
    <Chip label={`Experience: ${job.experience}`} color="secondary" variant="outlined" sx={{ marginBottom: 1 }} />
    <Chip label={`Skillset: ${job.skillset}`} color="success" variant="outlined" sx={{ marginBottom: 1 }} />
    <Chip label={`Job Type: ${job.jobType}`} color="warning" variant="outlined" sx={{ marginBottom: 1 }} />
    <Chip label={`Department: ${job.department}`} color="info" variant="outlined" sx={{ marginBottom: 1 }} />
    {job.jobMode && (<Chip label={`Job Mode: ${job.jobMode}`} color="success" variant="outlined" sx={{ marginBottom: 1 }} />)}
  </Stack>
</Box>




        
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: job.description }}
          gutterBottom
        />

{job.tags && job.tags.length > 0 && (
  <div>
    <Typography variant="h6" gutterBottom>
      Tags:
    </Typography>
    <div>
      {job.tags.map((tag, index) => (
        <Chip key={index} label={tag} color="primary" variant="outlined" sx={{ marginRight: 1, marginBottom: 1 }} />
      ))}
    </div>
  </div>
)}


        {/* Social Sharing Buttons */}
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item>
            <IconButton
              color="primary"
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodedJobUrl}`}
              target="_blank"
            >
              <Facebook />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              href={`https://twitter.com/intent/tweet?url=${encodedJobUrl}`}
              target="_blank"
            >
              <Twitter />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedJobUrl}`}
              target="_blank"
            >
              <LinkedIn />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton color="primary" onClick={onCopyLink}>
              <ContentCopy />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JobDetails;
