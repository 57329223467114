import React, { useState } from "react";

const PortfolioDownload = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone.replace(/\D/g, ""))) {
      newErrors.phone = "Please enter a valid phone number";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDownload = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);

      setTimeout(() => {
        console.log("Form submitted:", formData);

        const pdfUrl =
          "https://texora.s3.ap-south-1.amazonaws.com/UFS+_PORTFOLIO.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.setAttribute("download", "UFS_PORTFOLIO.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setFormData({
          name: "",
          email: "",
          // phone: "",
          company: "",
        });
        setShowModal(false);
        setIsSubmitting(false);
      }, 1000);
    }
  };

  return (
    <>
      <li>
        <a
          href="#"
          className="btn btn-outline-primary"
          onClick={handleDownload}
        >
          <span className="btn_label" data-text="Download Portfolio">
            Download Portfolio
          </span>
          <span className="btn_icon">
            <i className="fa-solid fa-download"></i>
          </span>
        </a>
      </li>

      {/* Modal with Blur Background */}
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(10px)", // Proper Blur Fix
            WebkitBackdropFilter: "blur(10px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              width: "40vw",
              maxWidth: "400px",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
              position: "relative",
              maxHeight: "80vh", // Ye add kiya scroll enable karne ke liye
              overflowY: "auto", // Ye fix karega agar height jyada ho
            }}
          >
            <button
              onClick={() => setShowModal(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "15px",
                fontSize: "24px",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              &times;
            </button>

            <div style={{ textAlign: "center", marginBottom: "30px" }}>
              <h3 style={{ fontSize: "24px", fontWeight: "bold" }}>
                Download Our Portfolio
              </h3>
              <p style={{ color: "gray" }}>Fill in your details to proceed</p>
            </div>

            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: "10px" }}>
                <label>Full Name *</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "5px",
                    border: "1px solid gray",
                  }}
                  placeholder="John Doe"
                />
                {errors.name && (
                  <p style={{ color: "red", fontSize: "12px" }}>{errors.name}</p>
                )}
              </div>

              <div style={{ marginBottom: "10px" }}>
                <label>Email Address *</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "5px",
                    border: "1px solid gray",
                  }}
                  placeholder="john@example.com"
                />
                {errors.email && (
                  <p style={{ color: "red", fontSize: "12px" }}>{errors.email}</p>
                )}
              </div>

              <div style={{ marginBottom: "10px" }}>
                <label>Phone Number *</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "5px",
                    border: "1px solid gray",
                  }}
                  placeholder="1234567890"
                />
                {errors.phone && (
                  <p style={{ color: "red", fontSize: "12px" }}>{errors.phone}</p>
                )}
              </div>

              {/* Company Name (Optional) */}
              {/* <div style={{ marginBottom: "10px" }}>
                <label>Company Name (Optional)</label>
                <input
                  type="text"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "5px",
                    border: "1px solid gray",
                  }}
                  placeholder="Company Name (Optional)"
                />
              </div> */}

              <button
                type="submit"
                disabled={isSubmitting}
                style={{
                  width: "100%",
                  padding: "12px",
                  backgroundColor: "blue",
                  color: "white",
                  fontSize: "16px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  border: "none",
                  marginTop: "5px", // Isse button aur upar aayega
                }}
              >
                {isSubmitting ? (
                  <span>
                    <i className="fa-solid fa-spinner fa-spin"></i> Processing...
                  </span>
                ) : (
                  "Download Portfolio"
                )}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default PortfolioDownload;

