import { Helmet } from "react-helmet-async";

const SEO = ({
  title,
  description,
  url,
  image,
  canonicalUrl, // Optional: for more flexible canonical handling
}) => {
  // Use canonicalUrl if provided, otherwise fall back to url
  const canonicalLink = canonicalUrl || url;

  return (
    <Helmet>
      {/* Standard Meta SEO */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="Consulting, Recruitment, AI, Software Development, Human Resources (HR)"
      />
      <meta name="author" content="M Shibli" />

      {/* Canonical URL - Ensure it's a full URL */}
      <link rel="canonical" href={canonicalLink} />

      {/* Open Graph (Facebook, LinkedIn) */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={title} />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:site" content="@ufsnetworks" />
    </Helmet>
  );
};

export default SEO;
