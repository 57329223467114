import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import Sidebar from "../Sidebar/Sidebar";

const ContactDF = () => {
    const [contacts, setContacts] = useState([]);
    const [contactUsData, setContactUsData] = useState([]);

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASEURL}/api/contact`);
                setContacts(response.data);
            } catch (error) {
                console.error("Error fetching contacts:", error);
            }
        };
        const fetchContactUsData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASEURL}/api/contact-us`)
                setContactUsData(response.data)
            } catch (error) {
                console.error("Error fetching contact us data: ", error)
            }
        }

        fetchContacts();
        fetchContactUsData();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_BASEURL}/api/contact/${id}`);
            setContacts(contacts.filter(contact => contact._id !== id));
        } catch (error) {
            console.error("Error deleting contact:", error);
        }
    };

    const handleDeleteContactUs = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_BASEURL}/api/contact-us/${id}`);
            setContactUsData(contactUsData.filter(contact => contact._id !== id));
        } catch (error) {
            console.error("Error deleting contact:", error);
        }
    };

    const handleDownload = (data, fileName) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };

    const layoutStyle = {
        display: "flex",

    };

    const contentStyle = {
        marginLeft: "250px",
        width: "100%",
        padding: "20px",
        overflowX: "auto",
    }

    return (
        <div style={layoutStyle}>
            <Sidebar />
            <div className="container mt-4" style={contentStyle}>
                <h3 className="mb-4">Admin Dashboard</h3>

                <div className="card shadow-lg ">
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <h4 className="mb-3 ">All Contact Submissions</h4>
                            <button className="btn btn-dark mb-3 p-3" onClick={() => handleDownload(contacts, "contacts")}>Download Excel</button>
                        </div>
                        <div style={{overflowX: "auto", maxHeight: "600px", overflowY: "scroll"}}>
                        <table className="table table-bordered table-striped">
                            <thead className="table-dark">
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Company</th>
                                    <th>Category</th>
                                    <th>Message</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contacts.length > 0 ? (
                                    contacts.map((contact, index) => (
                                        <tr key={contact._id}>
                                            <td>{index + 1}</td>
                                            <td>{contact.name}</td>
                                            <td>{contact.email}</td>
                                            <td>{contact.phone}</td>
                                            <td>{contact.company}</td>
                                            <td>{contact.category}</td>
                                            <td>{contact.message}</td>
                                            <td>
                                                <button className="btn btn-dark mb-3 p-3" onClick={() => handleDelete(contact._id)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>

                <div className="card shadow-lg mt-5">
                     <div className="card-body">
                        <div className="d-flex justify-content-between">
                        <h4>Contact Us Submissions</h4>
                        
                        <button className="btn btn-dark mb-3 p-3" onClick={() => handleDownload(contactUsData, "contact-us")}>Download Excel</button>
                        </div>
                        <table className="table table-boardered table-striped">
                            <thead className="table-dark">
                                <tr>
                                    <th>#</th>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Category</th>
                                    <th>Message</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    contactUsData.length > 0 ? (contactUsData.map((contact, index) => (
                                        <tr key={contact._id}>
                                            <td>{index + 1}</td>
                                            <td>{contact.FullName}</td>
                                            <td>{contact.email}</td>
                                            <td>{contact.phone}</td>
                                            <td>{contact.category}</td>
                                            <td>{contact.message}</td>
                                            <td>
                                                <button className="btn btn-dark mb-3 p-3" onClick={() => handleDeleteContactUs(contact._id)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))):(
                                        <tr>
                                            <td colSpan="6" className="text-center">No data available</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                     </div>
                </div>



            </div>
        </div>
    );
};

export default ContactDF;
