import axios from 'axios';
import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
    const initialState = {
        name: '',
        email: '',
        company: '',
        phone: '',
        message: '',
        category: ''
    };

    const [forms, setForms] = useState(initialState);
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        if (validator.allValid()) {
            validator.hideMessages();

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_BASEURL}/api/contact`,
                    forms
                );

                console.log("📩 API Response:", response);

                if (response.status === 200) {
                    toast.success("✅ Form submitted successfully!", { position: "top-right" });

                    // Reset the form
                    setForms({ ...initialState });
                }
            } catch (error) {
                toast.error("❌ Failed to submit. Please try again.", { position: "top-right" });
            }
        } else {
            validator.showMessages();
            toast.warn("⚠️ Please fill in all required fields correctly.", { position: "top-right" });
        }
    };

    return (
        <>
            <ToastContainer />
            <form className="xb-item--form contact-form" onSubmit={submitHandler}>
                <div className="row">
                    {/* Name Input */}
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="input_title">
                                <i className="fa-regular fa-user"></i>
                            </label>
                            <input
                                value={forms.name}
                                type="text"
                                name="name"
                                className="form-control"
                                onChange={changeHandler}
                                placeholder="First & Last Name"
                            />
                            {validator.message('name', forms.name, 'required|alpha_space')}
                        </div>
                    </div>

                    {/* Email Input */}
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="input_title">
                                <i className="fa-regular fa-envelope"></i>
                            </label>
                            <input
                                value={forms.email}
                                type="email"
                                name="email"
                                className="form-control"
                                onChange={changeHandler}
                                placeholder="Business Email"
                            />
                            {validator.message('email', forms.email, 'required|email')}
                        </div>
                    </div>

                    {/* Phone Input */}
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="input_title">
                                <i className="fa-regular fa-phone-volume"></i>
                            </label>
                            <input
                                value={forms.phone}
                                type="phone"
                                name="phone"
                                className="form-control"
                                onChange={changeHandler}
                                placeholder="Contact Number"
                            />
                            {validator.message('phone', forms.phone, 'required|phone')}
                        </div>
                    </div>

                    {/* Company Input */}
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="input_title">
                                <i className="fa-regular fa-globe"></i>
                            </label>
                            <input
                                value={forms.company}
                                type="text"
                                name="company"
                                className="form-control"
                                onChange={changeHandler}
                                placeholder="Your Company Name"
                            />
                            {validator.message('company', forms.company, 'required')}
                        </div>
                    </div>

                    {/* Category Select */}
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="input_title">
                                <i className="fa-regular fa-list"></i>
                            </label>
                            <select
                                value={forms.category}
                                name="category"
                                className="form-control form-select"
                                onChange={changeHandler}
                            >
                                <option value="">Select Category</option>
                                <option value="HR">HR-Consultancy</option>
                                <option value="Developer">Software AI Automation</option>
                                <option value="IT-Consulting-Services">IT Consulting Services</option>
                                <option value="ProductDemo">Product Demo</option>
                            </select>
                            {validator.message('category', forms.category, 'required')}
                        </div>
                    </div>

                    {/* Message Input */}
                    <div className="col-12">
                        <div className="form-group">
                            <label className="input_title">
                                <i className="fa-regular fa-comments"></i>
                            </label>
                            <textarea
                                value={forms.message}
                                name="message"
                                className="form-control"
                                onChange={changeHandler}
                                placeholder="How can we help you?"
                            ></textarea>
                            {validator.message('message', forms.message, 'required')}
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">
                            <span className="btn_label">Send Request</span>
                            <span className="btn_icon">
                                <i className="fa-solid fa-arrow-up-right"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default ContactForm;
