import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Chip,
  Box,
  IconButton,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
  Divider,
  InputAdornment,
  LinearProgress,
  Alert,
  AlertTitle,
} from "@mui/material";

import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { HelmetProvider } from "react-helmet-async";
import SEO from "../SEO";

const ApplyForm = ({ jobId, setSnackbar }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "+91", // Default country code
    phone: "",
    resume: null,
    coverLetter: null, // Optional
    linkedinUrl: "",
    experienceRange: "",
    noticePeriod: "",
    currentCTC: "",
    expectedCTC: "",
    permanentAddress: "",
    city: "",
    state: "",
    country: "",
    workspace: "",
    referralSource: "",
    referralEmail: "", // Field for reference email
    openToRelocation: "Maybe", // Field for relocation preference
    previouslyEmployed: "No", // Field for previous employment
    qualifications: [
      {
        institution: "",
        degree: "",
        specialization: "",
        startYear: "",
        endYear: "",
      },
    ],
    skills: [], // Field for skills
    languages: [
      {
        language: "",
        proficiency: "",
      },
    ],
    workExperiences: [], // New field for work experiences
  });

  const [countryCodes, setCountryCodes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [skillInput, setSkillInput] = useState(""); // For managing skill input
  const requiredFields = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone Number",
    resume: "Resume",
    permanentAddress: "Current Address",
    city: "City",
    state: "State/Province",
    country: "Country",
  };
  useEffect(() => {
    // Fetch country codes with flags
    fetch("https://restcountries.com/v3.1/all?fields=name,flags,idd")
      .then((response) => response.json())
      .then((data) => {
        const codes = data
          .filter((country) => country.idd && country.idd.root)
          .map((country) => ({
            code: `${country.idd.root}${
              country.idd.suffixes ? country.idd.suffixes[0] : ""
            }`,
            name: country.name.common,
            flag: country.flags.svg,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        setCountryCodes(codes);

        // Also prepare countries list for the country dropdown
        const countryList = data
          .map((country) => ({
            name: country.name.common,
            code: country.cca2,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        setCountries(countryList);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching country data:", error);
        setLoading(false);
        // Fallback to basic country code format if API fails
        setCountryCodes([
          { code: "+1", name: "United States", flag: "" },
          { code: "+44", name: "United Kingdom", flag: "" },
          { code: "+91", name: "India", flag: "" },
        ]);
      });
  }, []);
  const calculateProgress = () => {
    const requiredFields = Object.keys(requiredFields);
    const filledFields = requiredFields.filter((field) => !!formData[field]);
    return (filledFields.length / requiredFields.length) * 100;
  };
  // Update the handleChange function
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // Clear error for this field
    if (fieldErrors[name]) {
      setFieldErrors((prev) => ({ ...prev, [name]: "" }));
    }

    if (name === "resume" || name === "coverLetter") {
      setFormData({ ...formData, [name]: files ? files[0] : null });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handler for qualification fields
  const handleQualificationChange = (index, field, value) => {
    const updatedQualifications = [...formData.qualifications];
    updatedQualifications[index] = {
      ...updatedQualifications[index],
      [field]: value,
    };
    setFormData({ ...formData, qualifications: updatedQualifications });
  };

  // Add new qualification
  const addQualification = () => {
    setFormData({
      ...formData,
      qualifications: [
        ...formData.qualifications,
        {
          institution: "",
          degree: "",
          specialization: "",
          startYear: "",
          endYear: "",
        },
      ],
    });
  };

  // Remove qualification
  const removeQualification = (index) => {
    const updatedQualifications = [...formData.qualifications];
    updatedQualifications.splice(index, 1);
    setFormData({ ...formData, qualifications: updatedQualifications });
  };

  // Handler for language fields
  const handleLanguageChange = (index, field, value) => {
    const updatedLanguages = [...formData.languages];
    updatedLanguages[index] = {
      ...updatedLanguages[index],
      [field]: value,
    };
    setFormData({ ...formData, languages: updatedLanguages });
  };

  // Add new language
  const addLanguage = () => {
    setFormData({
      ...formData,
      languages: [
        ...formData.languages,
        {
          language: "",
          proficiency: "",
        },
      ],
    });
  };

  // Remove language
  const removeLanguage = (index) => {
    const updatedLanguages = [...formData.languages];
    updatedLanguages.splice(index, 1);
    setFormData({ ...formData, languages: updatedLanguages });
  };

  // Work Experience Handlers
  const addWorkExperience = () => {
    setFormData({
      ...formData,
      workExperiences: [
        ...formData.workExperiences,
        {
          jobTitle: "",
          organization: "",
          startDate: "",
          endDate: "",
          currentlyWorking: false,
          jobDescription: "",
          location: "",
        },
      ],
    });
  };

  const removeWorkExperience = (index) => {
    const updatedWorkExperiences = [...formData.workExperiences];
    updatedWorkExperiences.splice(index, 1);
    setFormData({ ...formData, workExperiences: updatedWorkExperiences });
  };

  const handleWorkExperienceChange = (index, field, value) => {
    const updatedWorkExperiences = [...formData.workExperiences];

    // Special handling for the checkbox
    if (field === "currentlyWorking") {
      updatedWorkExperiences[index] = {
        ...updatedWorkExperiences[index],
        [field]: value,
        // Clear end date if currently working
        ...(value && { endDate: "" }),
      };
    } else {
      updatedWorkExperiences[index] = {
        ...updatedWorkExperiences[index],
        [field]: value,
      };
    }

    setFormData({ ...formData, workExperiences: updatedWorkExperiences });
  };

  // Handle skills input
  const handleSkillInputChange = (e) => {
    setSkillInput(e.target.value);
  };

  // Add a skill when Enter is pressed
  const handleSkillKeyDown = (e) => {
    if (e.key === "Enter" && skillInput.trim() !== "") {
      e.preventDefault();
      addSkill();
    }
  };

  // Add a skill
  const addSkill = () => {
    if (skillInput.trim() !== "" && formData.skills.length < 50) {
      setFormData({
        ...formData,
        skills: [...formData.skills, skillInput.trim()],
      });
      setSkillInput("");
    }
  };

  // Remove a skill
  const removeSkill = (skillToRemove) => {
    setFormData({
      ...formData,
      skills: formData.skills.filter((skill) => skill !== skillToRemove),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      firstName,
      lastName,
      email,
      countryCode,
      phone,
      resume,
      qualifications,
      skills,
      languages,
      openToRelocation,
      previouslyEmployed,
    } = formData;

    // Validate required fields
    if (!firstName || !lastName || !email || !phone || !resume) {
      setSnackbar({
        open: true,
        severity: "error",
        message: "Please fill in all required fields.",
      });
      return;
    }

    // Validate at least one qualification is entered
    if (
      formData.qualifications.length === 0 ||
      !formData.qualifications[0].institution
    ) {
      setSnackbar({
        open: true,
        severity: "error",
        message: "Please add at least one qualification.",
      });
      return;
    }

    // Check for validation errors
    let errors = {};
    let hasErrors = false;

    // Check each required field
    Object.keys(requiredFields).forEach((field) => {
      if (!formData[field]) {
        errors[field] = `${requiredFields[field]} is required`;
        hasErrors = true;
      }
    });

    // Additional validation for qualifications
    if (
      formData.qualifications.length === 0 ||
      !formData.qualifications[0].institution
    ) {
      errors.qualifications = "At least one qualification is required";
      hasErrors = true;
    }

    if (hasErrors) {
      setFieldErrors(errors);
      // Scroll to the first error
      const firstErrorField = document.querySelector(".Mui-error");
      if (firstErrorField)
        firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" });

      setSnackbar({
        open: true,
        severity: "error",
        message: "Please fill in all required fields.",
      });
      return;
    }

    setSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append("jobId", jobId);
    formDataToSend.append("firstName", firstName);
    formDataToSend.append("lastName", lastName);
    formDataToSend.append("email", email);
    formDataToSend.append("countryCode", countryCode);
    formDataToSend.append("phone", phone);
    formDataToSend.append("resume", resume);

    // Append optional fields if they exist
    if (formData.coverLetter) {
      formDataToSend.append("coverLetter", formData.coverLetter);
    }

    formDataToSend.append("linkedinUrl", formData.linkedinUrl || "");
    formDataToSend.append("experienceRange", formData.experienceRange || "");
    formDataToSend.append("noticePeriod", formData.noticePeriod || "");
    formDataToSend.append("currentCTC", formData.currentCTC || "");
    formDataToSend.append("expectedCTC", formData.expectedCTC || "");
    formDataToSend.append("permanentAddress", formData.permanentAddress || "");
    formDataToSend.append("city", formData.city || "");
    formDataToSend.append("state", formData.state || "");
    formDataToSend.append("country", formData.country || "");
    formDataToSend.append("workspace", formData.workspace || "");
    formDataToSend.append("referralSource", formData.referralSource || "");
    formDataToSend.append("referralEmail", formData.referralEmail || "");
    formDataToSend.append("openToRelocation", openToRelocation || "");
    formDataToSend.append("previouslyEmployed", previouslyEmployed || "");

    // Append qualifications as JSON
    formDataToSend.append(
      "qualifications",
      JSON.stringify(formData.qualifications)
    );

    // Append skills as JSON
    formDataToSend.append("skills", JSON.stringify(formData.skills));

    // Append languages as JSON
    formDataToSend.append("languages", JSON.stringify(formData.languages));

    // Append work experiences as JSON
    formDataToSend.append(
      "workExperiences",
      JSON.stringify(formData.workExperiences)
    );

    setSubmitting(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASEURL}/api/applications/submit`,
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      const result = await response.json();

      setSnackbar({
        open: true,
        severity: response.ok ? "success" : "error",
        message: response.ok
          ? "Application submitted successfully!"
          : result.message || "Failed to submit application.",
      });

      if (response.ok) {
        // Reset form on successful submission
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          countryCode: "+91",
          phone: "",
          resume: null,
          coverLetter: null,
          linkedinUrl: "",
          experienceRange: "",
          noticePeriod: "",
          currentCTC: "",
          expectedCTC: "",
          permanentAddress: "",
          city: "",
          state: "",
          country: "",
          workspace: "",
          referralSource: "",
          referralEmail: "",
          openToRelocation: "Maybe",
          previouslyEmployed: "No",
          qualifications: [
            {
              institution: "",
              degree: "",
              specialization: "",
              startYear: "",
              endYear: "",
            },
          ],
          skills: [],
          languages: [
            {
              language: "",
              proficiency: "",
            },
          ],
          workExperiences: [],
        });
        setSkillInput("");
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: "error",
        message: "An error occurred while submitting your application.",
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {Object.keys(fieldErrors).length > 0 && (
          <Grid item xs={12}>
            <Alert severity="error" sx={{ mb: 2 }}>
              <AlertTitle>Please correct the following errors:</AlertTitle>
              <ul>
                {Object.values(fieldErrors).map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </Alert>
          </Grid>
        )}
        <LinearProgress
          variant="determinate"
          value={
            (Object.keys(requiredFields).filter((field) => !!formData[field])
              .length /
              Object.keys(requiredFields).length) *
            100
          }
          sx={{ mb: 3 }}
          aria-label="Form completion progress"
        />
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            error={!!fieldErrors.firstName}
            helperText={fieldErrors.firstName || ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon color="action" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
            error={!!fieldErrors.lastName}
            helperText={fieldErrors.lastName || ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon color="action" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Contact information */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Country Code</InputLabel>
            <Select
              value={formData.countryCode}
              name="countryCode"
              onChange={handleChange}
              label="Country Code"
              required
            >
              {countryCodes.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.flag && (
                    <img
                      src={country.flag}
                      alt={country.name}
                      style={{ width: "20px", marginRight: "8px" }}
                    />
                  )}
                  {country.name} ({country.code})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            label="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </Grid>

        {/* Resume Upload */}
        {/* Resume Upload */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!fieldErrors.resume}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              color={formData.resume ? "success" : "primary"}
              startIcon={<UploadFileIcon />}
            >
              {formData.resume ? "Resume Uploaded" : "Upload Resume *"}
              <input
                type="file"
                hidden
                accept=".pdf,.doc,.docx"
                name="resume"
                onChange={handleChange}
                required
              />
            </Button>
            {formData.resume && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                {formData.resume.name}
              </Typography>
            )}
            {fieldErrors.resume && (
              <FormHelperText error>{fieldErrors.resume}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Cover Letter Upload */}
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            component="label"
            fullWidth
            color={formData.coverLetter ? "success" : "primary"}
          >
            {formData.coverLetter
              ? "Cover Letter Uploaded"
              : "Upload Cover Letter"}
            <input
              type="file"
              hidden
              accept=".pdf,.doc,.docx"
              name="coverLetter"
              onChange={handleChange}
            />
          </Button>
          {formData.coverLetter && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              {formData.coverLetter.name}
            </Typography>
          )}
        </Grid>

        {/* Qualifications Section */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Qualifications
          </Typography>
          {formData.qualifications.map((qualification, index) => (
            <Paper
              key={index}
              elevation={1}
              sx={{ p: 2, mb: 2, position: "relative" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="School/University Name"
                    value={qualification.institution}
                    onChange={(e) =>
                      handleQualificationChange(
                        index,
                        "institution",
                        e.target.value
                      )
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Degree"
                    value={qualification.degree}
                    onChange={(e) =>
                      handleQualificationChange(index, "degree", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Specialization"
                    value={qualification.specialization}
                    onChange={(e) =>
                      handleQualificationChange(
                        index,
                        "specialization",
                        e.target.value
                      )
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Start Year"
                    type="number"
                    value={qualification.startYear}
                    onChange={(e) =>
                      handleQualificationChange(
                        index,
                        "startYear",
                        e.target.value
                      )
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="End Year"
                    type="number"
                    value={qualification.endYear}
                    onChange={(e) =>
                      handleQualificationChange(
                        index,
                        "endYear",
                        e.target.value
                      )
                    }
                    required
                  />
                </Grid>
                {index > 0 && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeQualification(index)}
                    sx={{ position: "absolute", top: 10, right: 10 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </Paper>
          ))}

          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={addQualification}
            sx={{ mt: 1 }}
          >
            Add Another Qualification
          </Button>
        </Grid>

        {/* Work Experience Section */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Work Experience
          </Typography>

          {formData.workExperiences.length > 0 ? (
            formData.workExperiences.map((experience, index) => (
              <Paper
                key={index}
                elevation={1}
                sx={{ p: 2, mb: 2, position: "relative" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Job Title"
                      value={experience.jobTitle}
                      onChange={(e) =>
                        handleWorkExperienceChange(
                          index,
                          "jobTitle",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Organization"
                      value={experience.organization}
                      onChange={(e) =>
                        handleWorkExperienceChange(
                          index,
                          "organization",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Start Date (MM/YY)"
                      placeholder="MM/YY"
                      value={experience.startDate}
                      onChange={(e) =>
                        handleWorkExperienceChange(
                          index,
                          "startDate",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          label="End Date (MM/YY)"
                          placeholder="MM/YY"
                          value={experience.endDate}
                          onChange={(e) =>
                            handleWorkExperienceChange(
                              index,
                              "endDate",
                              e.target.value
                            )
                          }
                          disabled={experience.currentlyWorking}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={experience.currentlyWorking}
                              onChange={(e) =>
                                handleWorkExperienceChange(
                                  index,
                                  "currentlyWorking",
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Current"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Job Description"
                      multiline
                      rows={3}
                      value={experience.jobDescription}
                      onChange={(e) =>
                        handleWorkExperienceChange(
                          index,
                          "jobDescription",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Location"
                      value={experience.location}
                      onChange={(e) =>
                        handleWorkExperienceChange(
                          index,
                          "location",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeWorkExperience(index)}
                    sx={{ position: "absolute", top: 10, right: 10 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Paper>
            ))
          ) : (
            <Typography color="textSecondary" sx={{ mb: 2 }}>
              No work experience added. (Optional)
            </Typography>
          )}

          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={addWorkExperience}
            sx={{ mt: 1 }}
          >
            Add Work Experience
          </Button>
        </Grid>

        {/* Skills Section */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Skills
          </Typography>
          <Box sx={{ display: "flex", mb: 2 }}>
            <TextField
              fullWidth
              label="Add your skills"
              value={skillInput}
              onChange={handleSkillInputChange}
              onKeyDown={handleSkillKeyDown}
              sx={{ mr: 1 }}
            />
            <Button variant="contained" onClick={addSkill}>
              Add
            </Button>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {formData.skills.map((skill, index) => (
              <Chip
                key={index}
                label={skill}
                onDelete={() => removeSkill(skill)}
                color="primary"
                variant="outlined"
              />
            ))}
          </Box>
        </Grid>

        {/* Languages Section */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Languages
          </Typography>
          {formData.languages.map((language, index) => (
            <Paper
              key={index}
              elevation={1}
              sx={{ p: 2, mb: 2, position: "relative" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Language"
                    value={language.language}
                    onChange={(e) =>
                      handleLanguageChange(index, "language", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Proficiency</InputLabel>
                    <Select
                      value={language.proficiency}
                      onChange={(e) =>
                        handleLanguageChange(
                          index,
                          "proficiency",
                          e.target.value
                        )
                      }
                      label="Proficiency"
                    >
                      <MenuItem value="Beginner">Beginner</MenuItem>
                      <MenuItem value="Classroom Study">
                        Classroom Study
                      </MenuItem>
                      <MenuItem value="Fluent">Fluent</MenuItem>
                      <MenuItem value="Advanced">Advanced</MenuItem>
                      <MenuItem value="Native">Native</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {index > 0 && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeLanguage(index)}
                    sx={{ position: "absolute", top: 10, right: 10 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </Paper>
          ))}
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={addLanguage}
            sx={{ mt: 1 }}
          >
            Add Another Language
          </Button>
        </Grid>

        {/* Additional Personal Information */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Additional Information
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Experience Range</InputLabel>
            <Select
              name="experienceRange"
              value={formData.experienceRange}
              onChange={handleChange}
              label="Experience Range"
            >
              <MenuItem value="0-1 years">0-1 years</MenuItem>
              <MenuItem value="1-3 years">1-3 years</MenuItem>
              <MenuItem value="3-5 years">3-5 years</MenuItem>
              <MenuItem value="5-7 years">5-7 years</MenuItem>
              <MenuItem value="7-10 years">7-10 years</MenuItem>
              <MenuItem value="10+ years">10+ years</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Notice Period</InputLabel>
            <Select
              name="noticePeriod"
              value={formData.noticePeriod}
              required
              onChange={handleChange}
              label="Notice Period"
            >
              <MenuItem value="Immediate">Immediate</MenuItem>
              <MenuItem value="15 days">15 days</MenuItem>
              <MenuItem value="30 days">30 days</MenuItem>
              <MenuItem value="60 days">60 days</MenuItem>
              <MenuItem value="90 days">90 days</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Current CTC"
            name="currentCTC"
            value={formData.currentCTC}
            onChange={handleChange}
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Expected CTC"
            name="expectedCTC"
            value={formData.expectedCTC}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Workspace Preference</InputLabel>
            <Select
              name="workspace"
              value={formData.workspace}
              onChange={handleChange}
              label="Workspace Preference"
            >
              <MenuItem value="Remote">Remote</MenuItem>
              <MenuItem value="Hybrid">Hybrid</MenuItem>
              <MenuItem value="On-site">On-site</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Open to Relocation</InputLabel>
            <Select
              name="openToRelocation"
              value={formData.openToRelocation}
              onChange={handleChange}
              label="Open to Relocation"
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Maybe">Maybe</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Previously Employed</InputLabel>
            <Select
              name="previouslyEmployed"
              value={formData.previouslyEmployed}
              onChange={handleChange}
              label="Previously Employed"
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Referral Source</InputLabel>
            <Select
              name="referralSource"
              value={formData.referralSource}
              onChange={handleChange}
              label="Referral Source"
            >
              <MenuItem value="LinkedIn">LinkedIn</MenuItem>
              <MenuItem value="Job Portal">Job Portal</MenuItem>
              <MenuItem value="Company Website">Company Website</MenuItem>
              <MenuItem value="Employee Referral">Employee Referral</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {formData.referralSource === "Employee Referral" && (
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Referral Email"
              name="referralEmail"
              value={formData.referralEmail}
              onChange={handleChange}
            />
          </Grid>
        )}

        {/* Address Information */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Address Information
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Current Address"
            name="permanentAddress"
            value={formData.permanentAddress}
            onChange={handleChange}
            required
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="City"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="State/Province"
            name="state"
            value={formData.state}
            onChange={handleChange}
            required
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth required>
            <InputLabel>Country</InputLabel>
            <Select
              name="country"
              value={formData.country}
              onChange={handleChange}
              label="Country"
            >
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* LinkedIn URL */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="LinkedIn Profile URL"
            name="linkedinUrl"
            value={formData.linkedinUrl}
            onChange={handleChange}
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitting}
            startIcon={submitting && <CircularProgress size={20} />}
          >
            {submitting ? "Submitting..." : "Submit Application"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ApplyForm;
