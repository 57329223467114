import React, { useState } from "react";
import DashboardLayout from "./DashboardLayout";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import axios from "axios";

const PostBlog = () => {
    const [formData, setFormData] = useState({
        authorName: "",
        title: "",
        category: "",
        content: "",
        image: null,
        slug: "",
        keyword: "",
    });

    const [imagePreview, setImagePreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [wordCount, setWordCount] = useState(0);

    const generateSlug = (title) => {
        return title.toLowerCase().replace(/ /g, "-").replace(/[^a-z0-9-]/g, "");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "title") {
            setFormData({ ...formData, title: value, slug: generateSlug(value) });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleContentChange = (value) => {
        setFormData({ ...formData, content: value });
        setWordCount(value.replace(/<[^>]*>/g, "").trim().split(/\s+/).length);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({ ...formData, image: file });
            const reader = new FileReader();
            reader.onloadend = () => setImagePreview(reader.result);
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.authorName || !formData.title || !formData.category || !formData.content) {
            alert("Please fill in all required fields!");
            return;
        }

        setLoading(true);

        const blogData = new FormData();
        blogData.append("authorName", formData.authorName);
        blogData.append("title", formData.title);
        blogData.append("category", formData.category);
        blogData.append("slug", formData.slug);
        blogData.append("content", formData.content);
        blogData.append("keyword", JSON.stringify(formData.keyword.split(",").map((kw) => kw.trim())));

        if (formData.image) blogData.append("image", formData.image);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASEURL}/api/postblog/create`,
                blogData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );

            if (response.status === 201) {
                alert("Blog post created successfully!");
                setFormData({ authorName: "", title: "", category: "", content: "", image: null, slug: "", keyword: "" });
                setImagePreview(null);
            }
        } catch (error) {
            console.error("Error submitting blog: ", error.response?.data || error);
            alert("Something went wrong! Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "100vh", backgroundColor: "#f3f4f6", padding: "1.5rem" }}>
                <div style={{ backgroundColor: "white", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "1.5rem", width: "100%", maxWidth: "700px" }}>
                    <h1 style={{ fontSize: "1.875rem", fontWeight: "600", textAlign: "center", color: "#1f2937", marginBottom: "1.5rem" }}>
                        Create a Blog Post
                    </h1>
                    
                    <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", gap: "1rem" }}>
                            <div>
                                <label style={{ fontSize: "0.875rem", fontWeight: "500", color: "#374151" }}>Author Name</label>
                                <input
                                    type="text"
                                    name="authorName"
                                    value={formData.authorName}
                                    onChange={handleChange}
                                    style={{ width: "100%", padding: "0.5rem", border: "1px solid #d1d5db", borderRadius: "6px", marginTop: "0.25rem" }}
                                    placeholder="Enter author name"
                                    required
                                />
                            </div>

                            <div>
                                <label style={{ fontSize: "0.875rem", fontWeight: "500", color: "#374151" }}>Title</label>
                                <input
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    style={{ width: "100%", padding: "0.5rem", border: "1px solid #d1d5db", borderRadius: "6px", marginTop: "0.25rem" }}
                                    placeholder="Enter post title"
                                    required
                                />
                            </div>
                        </div>

                        <div>
                            <label style={{ fontSize: "0.875rem", fontWeight: "500", color: "#374151" }}>Category</label>
                            <select
                                name="category"
                                value={formData.category}
                                onChange={handleChange}
                                style={{ width: "100%", padding: "0.5rem", border: "1px solid #d1d5db", borderRadius: "6px", marginTop: "0.25rem" }}
                                required
                            >
                                <option value="">Select a Category</option>
                                <option value="javascript">JavaScript</option>
                                <option value="reactjs">React.js</option>
                                <option value="nextjs">Next.js</option>
                            </select>
                        </div>

                        <div>
                            <label style={{ fontSize: "0.875rem", fontWeight: "500", color: "#374151" }}>Image Upload</label>
                            <input type="file" accept="image/*" style={{ width: "100%", marginTop: "0.25rem" }} onChange={handleImageChange} />
                            {imagePreview && <img src={imagePreview} alt="Preview" style={{ marginTop: "0.5rem", width: "100%", height: "160px", objectFit: "cover", borderRadius: "6px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }} />}
                        </div>

                        <div>
                            <label style={{ fontSize: "0.875rem", fontWeight: "500", color: "#374151" }}>Content</label>
                            <ReactQuill theme="snow" value={formData.content} onChange={handleContentChange} style={{ backgroundColor: "white", border: "1px solid #d1d5db", borderRadius: "6px", height: "240px", marginTop: "0.25rem" }} />
                            <p style={{ fontSize: "0.875rem", color: "#6b7280", marginTop: "0.5rem" }}>Word Count: {wordCount}</p>
                        </div>

                        <button type="submit" style={{ width: "100%", backgroundColor: "#2563eb", color: "white", padding: "0.75rem", borderRadius: "6px", fontWeight: "600", cursor: "pointer" }}>
                            {loading ? "Submitting..." : "Submit Post"}
                        </button>
                    </form>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default PostBlog;



