import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Box,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import CodeIcon from "@mui/icons-material/Code";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SortIcon from "@mui/icons-material/Sort";
import TuneIcon from "@mui/icons-material/Tune"; // Filter Toggle Icon

const SearchFilterSection = ({
  experience,
  setExperience,
  skillset,
  setSkillset,
  location,
  setLocation,
  jobType,
  setJobType,
  department,
  setDepartment,
  sortOrder,
  setSortOrder,
  applyFilters,
  clearFilters,
  experiences,
  skillsets,
  countries,
  jobTypeOptions,
  departmentOptions,
}) => {
  const [showFilters, setShowFilters] = useState(window.innerWidth >= 1024);

  

  useEffect(() => {
    const handleResize = () => {
      setShowFilters(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* Always Show Title */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Typography variant="h6" fontWeight={600}>
          Filter Jobs
        </Typography>

        {/* Toggle Button Only for Mobile */}
        {window.innerWidth < 1024 && (
          <IconButton onClick={() => setShowFilters(!showFilters)}>
            <TuneIcon color="primary" />
          </IconButton>
        )}
      </Box>

      {/* Filters Section */}
      {showFilters && (
        <Paper elevation={3} sx={{ p: 3, borderRadius: "12px" }}>
          {/* Experience Filter */}
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>Experience Level</InputLabel>
            <Select
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <WorkIcon color="primary" />
                </InputAdornment>
              }
              label="Experience Level"
            >
              <MenuItem value="">All Experience</MenuItem>
              {experiences.map((exp) => (
                <MenuItem key={exp} value={exp}>
                  {exp}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Skillset Filter */}
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>Skillset</InputLabel>
            <Select
              value={skillset}
              onChange={(e) => setSkillset(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <CodeIcon color="primary" />
                </InputAdornment>
              }
              label="Skillset"
            >
              <MenuItem value="">All Skills</MenuItem>
              {skillsets.map((skill) => (
                <MenuItem key={skill} value={skill}>
                  {skill}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Location Filter */}
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>Location</InputLabel>
            <Select
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <LocationOnIcon color="primary" />
                </InputAdornment>
              }
              label="Location"
            >
              <MenuItem value="">All Locations</MenuItem>
              {countries.map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Job Type Filter */}
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>Job Type</InputLabel>
            <Select
              value={jobType}
              onChange={(e) => setJobType(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <BusinessCenterIcon color="primary" />
                </InputAdornment>
              }
              label="Job Type"
            >
              <MenuItem value="">All Job Types</MenuItem>
              {jobTypeOptions.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Department Filter */}
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>Department</InputLabel>
            <Select
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <AccountBalanceIcon color="primary" />
                </InputAdornment>
              }
              label="Department"
            >
              <MenuItem value="">All Departments</MenuItem>
              {departmentOptions.map((dept) => (
                <MenuItem key={dept} value={dept}>
                  {dept}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Sort By */}
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SortIcon color="primary" />
                </InputAdornment>
              }
              label="Sort By"
            >
              <MenuItem value="">Default</MenuItem>
              <MenuItem value="newest">Newest to Oldest</MenuItem>
              <MenuItem value="oldest">Oldest to Newest</MenuItem>
            </Select>
          </FormControl>

          {/* Apply Filters */}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={applyFilters}
            size="large"
            sx={{ py: 1.5, boxShadow: 2, borderRadius: "8px", mb: 2 }}
          >
            Apply Filters
          </Button>

          {/* Clear Filters */}
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={clearFilters}
            size="large"
            sx={{ py: 1.5, borderRadius: "8px" }}
          >
            Clear All
          </Button>
        </Paper>
      )}
    </>
  );
};

export default SearchFilterSection;
