import React, { useState } from "react";
import { Link } from "react-router-dom";
import Bg from "../../../images/shapes/bg_pattern_3.svg";
import Bg2 from "../../../images/shapes/shape_space_6.svg";
import icon1 from "../../../images/shapes/shape_space_2.svg";
import icon2 from "../../../images/icons/icon_mail_2.svg";
import Slider from "react-slick";
import axios from "axios";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

var settings = {
  dots: false,
  infinite: true,
  speed: 3000,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: "linear",
  arrows: false,

  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Footer = (props) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const SubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASEURL}/api/subscribe`,
        { email }
      );
      setMessage(response.data.message);
      setEmail("");
    } catch (error) {
      setMessage("Email already exists or an error occurred!");
    }
  };
  return (
    <footer
      className="site_footer footer_layout_2 section_decoration"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="decoration_item shape_image_1">
        <img src={icon1} alt="Techco Shape" />
      </div>
      <div className="container">
        <div className="service_pill_carousel swiper">
          <Slider {...settings}>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Technical HR</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Software Development</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>DevOps</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>CRM & ERP </span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Enterprise Apps</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Cloud Technologies</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Custom Web Apps</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Infrastructure Modernization</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Web Development</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Professional Services</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>Enterprise Apps</span>
              </a>
            </div>
            <div className="swiper-slide">
              <a className="service_pill_block" href="service_details.html">
                <i className="fa-solid fa-check"></i>
                <span>CyberSecurity</span>
              </a>
            </div>
          </Slider>
        </div>
        <div className="footer_main_content ">
          <div className="row justify-content-lg-between">
            <div className="col-lg-2 col-md-6 d-none d-sm-block">
              <div className="footer_widget">
                <h3 className="footer_info_title">Company</h3>
                <ul className="icon_list unordered_list_block">
                  <li>
                    <Link onClick={ClickHandler} to="/about">
                      <span className="icon_list_text">About</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/portfolio">
                      <span className="icon_list_text">Our Achievements</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link onClick={ClickHandler} to="/about">
                      <span className="icon_list_text">Our Partners</span>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link onClick={ClickHandler} to="/contact">
                      <span className="icon_list_text">Our Locations</span>
                    </Link>
                  </li> */}
                  <li>
                    <Link onClick={ClickHandler} to="/portfolio">
                      <span className="icon_list_text">Careers</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      <span className="icon_list_text">Contacts</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 d-none d-sm-block">
              <div className="footer_widget">
                <h3 className="footer_info_title">Expertise</h3>
                <ul className="icon_list unordered_list_block">
                  <li>
                    <Link
                      onClick={ClickHandler}
                      to="/service-single/SoftwareDevelopment"
                    >
                      <span className="icon_list_text">
                        Software Development
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={ClickHandler}
                      to="/service-single/Consultancy"
                    >
                      <span className="icon_list_text">Consultancy </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={ClickHandler}
                      to="/service-single/ArtificialIntelligence"
                    >
                      <span className="icon_list_text">
                        Artificial Intelligence
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={ClickHandler}
                      to="/service-single/CloudTechnologies"
                    >
                      <span className="icon_list_text">Cloud Technologies</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/service-single/DevOps">
                      <span className="icon_list_text">DevOps</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={ClickHandler}
                      to="/service-single/Cybersecurity"
                    >
                      <span className="icon_list_text">Cyber Security</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 d-none d-sm-block">
              <div className="footer_widget">
                <h3 className="footer_info_title">Industries</h3>
                <ul className="icon_list unordered_list_block">
                  <li>
                    <Link onClick={ClickHandler} to="/portfolio">
                      <span className="icon_list_text">Insurance</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/portfolio">
                      <span className="icon_list_text">Banking</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/portfolio">
                      <span className="icon_list_text">Fintech</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/portfolio">
                      <span className="icon_list_text">Logistics</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/portfolio">
                      <span className="icon_list_text">Retail</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/portfolio">
                      <span className="icon_list_text">Healthcare</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer_widget pe-lg-3">
                <h2 className="footer_info_title">Newsletter</h2>
                <p>
                  Sign up to UFS weekly newsletter to get the latest updates.
                </p>
                <form className="footer_newslatter_2" onSubmit={SubmitHandler}>
                  <label htmlFor="footer_mail_input">
                    <img src={icon2} alt="Mail SVG Icon" />
                  </label>
                  <input
                    id="footer_mail_input"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <button type="submit">Send</button>
                </form>
                {message && <p>{message}</p>}

                <ul className="social_icons_block unordered_list">
                  <li>
                    <a
                      href="https://www.facebook.com/ufsnetworks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://x.com/ufsnetworks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://linkedin.com/company/ufsnetworks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/unified_consultancy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@UFSNETWORKS"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom" style={{ backgroundImage: `url(${Bg2})` }}>
        <div className="container d-md-flex align-items-md-center justify-content-md-between">
          <p className="copyright_text m-0">
            Copyright © 2025 UFS, All rights reserved.
          </p>
          <ul className="icon_list unordered_list">
            <li>
              <Link onClick={ClickHandler} to="/contact">
                <span className="icon_list_icon">
                  <i className="fa-solid fa-circle"></i>
                </span>
                <span className="icon_list_text">Terms of Use</span>
              </Link>
            </li>
            <li>
              <Link onClick={ClickHandler} to="/contact">
                <span className="icon_list_icon">
                  <i className="fa-solid fa-circle"></i>
                </span>
                <span className="icon_list_text">Privacy Policy</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
