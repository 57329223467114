import React, { Fragment } from "react";
// import Header from '../../components/header/Header';
import Header from "../../components/Header2/Header2";
import Project from "../../api/project";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import psImg from "../../images/portfolio/portfolio_details_image_1.webp";
import icon from "../../images/icons/icon_check_3.svg";
import SEO from "../../components/SEO";
const PortfolioSinglePage = (props) => {
  const { slug } = useParams();
  const PortfolioDetails = Project.find((item) => item.slug === slug);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>
      <SEO
        title="UFS Networks Portfolio | Showcasing Our IT Solutions & Success"
        description="Explore UFS Networks' portfolio of innovative IT solutions, showcasing successful projects in networking, cybersecurity, cloud services, and digital transformation."
        url="https://www.ufsnetworks.com/portfolio"
        image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
        canonicalUrl="https://www.ufsnetworks.com/portfolio"
      />
      <Header />
      <main className="page_content about-page">
        <PageTitle
          pageTitle={PortfolioDetails.title}
          pagesub={"Details 😃"}
          pageTop={"Portfolio"}
        />
        <section className="portfolio_details_section section_space bg-light">
          <div className="container">
            <div className="details_item_image">
              <img src={psImg} alt="Portfolio Item" />
            </div>
            <h2 className="details_item_title">
              Empowering Innovation and Opportunities Across Industries
            </h2>
            <p style={{ textAlign: "justify" }}>
              Our projects focus on delivering cutting-edge solutions tailored
              to drive meaningful impact in diverse domains. By integrating
              advanced technologies and innovative strategies, we empower
              businesses, individuals, and communities to achieve their goals
              and thrive in an ever-evolving landscape.
            </p>
            <p className="mb-0">
              <h3>Key Features and Offerings</h3>
              <ul>
                <li>
                  <strong>
                    Global Connectivity and Economic Opportunities:
                  </strong>{" "}
                  Platforms like Bidzbay connect people and communities,
                  fostering real connections and supporting global economic
                  growth.
                </li>
                <li>
                  <strong>Career Advancement and Skill Development:</strong>{" "}
                  Rigzone AI provides flexible online training and job
                  opportunities, equipping individuals with the tools to excel
                  in dynamic career paths.
                </li>
                <li>
                  <strong>Streamlined Business Operations:</strong> CRM and ERP
                  solutions optimize core business processes, enhance customer
                  engagement, and improve operational efficiency.
                </li>
              </ul>
            </p>
            <hr />
            <ul className="portfolio_details_info_list icon_list unordered_list justify-content-lg-between mb-5">
              <li>
                <span className="icon_list_text">
                  <strong className="text-dark text-uppercase">
                    Services:
                  </strong>
                  E-commerce Solutions, Online Learning Platforms, CRM and ERP
                  Integration
                </span>
              </li>
              {/* <li>
                <span className="icon_list_text">
                  <strong className="text-dark text-uppercase">Client:</strong>
                  UFS
                </span>
              </li> */}
              <li>
                <span className="icon_list_text">
                  <strong className="text-dark text-uppercase">
                    Client Locations:
                  </strong>
                  New Delhi, India (and beyond)
                </span>
              </li>
              {/* <li>
                <span className="icon_list_text">
                  <strong className="text-dark text-uppercase">
                    Completed Date:
                  </strong>
                  20-12-2024
                </span>
              </li> */}
            </ul>

            <h3 className="details_item_info_title pt-4">
              Our Comprehensive Approach Includes
            </h3>
            <p style={{ textAlign: "justify" }}>
              Our approach is designed to deliver tailored, cutting-edge
              solutions that drive success at every stage of a project. By
              thoroughly understanding client needs and integrating advanced
              technologies such as AI, cloud computing, and automation, we
              ensure scalable, efficient outcomes. With end-to-end support—from
              initial strategy development to seamless post-implementation
              assistance—we prioritize smooth transitions and long-term success
              for every client.
            </p>
            <div className="row mb-4">
              {/* <div className="col-lg-5"> */}
              <ul className="icon_list unordered_list_block">
                <li>
                  <span className="icon_list_icon">
                    <img src={icon} alt="Check SVG Icon" />
                  </span>
                  <span className="icon_list_text">
                    <strong>Needs Assessment and Strategy Development:</strong>{" "}
                    Understanding client objectives and crafting tailored
                    solutions to meet specific requirements.
                  </span>
                </li>
                <li>
                  <span className="icon_list_icon">
                    <img src={icon} alt="Check SVG Icon" />
                  </span>
                  <span className="icon_list_text">
                    <strong>Advanced Technology Integration:</strong> Leveraging
                    AI, cloud computing, and automation to deliver scalable and
                    efficient solutions.
                  </span>
                </li>
                <li>
                  <span className="icon_list_icon">
                    <img src={icon} alt="Check SVG Icon" />
                  </span>
                  <span className="icon_list_text">
                    <strong>End-to-End Support:</strong> From planning and
                    development to training and post-implementation support,
                    ensuring smooth transitions and sustained success.
                  </span>
                </li>
                {/* <li>
                    <span className="icon_list_icon">
                      <img src={icon} alt="Check SVG Icon" />
                    </span>
                    <span className="icon_list_text">
                      Infrastructure Preparation
                    </span>
                  </li> */}
              </ul>
              {/* </div> */}
              {/* <div className="col-lg-5">
                <ul className="icon_list unordered_list_block">
                  <li>
                    <span className="icon_list_icon">
                      <img src={icon} alt="Check SVG Icon" />
                    </span>
                    <span className="icon_list_text">
                      Application Migration
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_icon">
                      <img src={icon} alt="Check SVG Icon" />
                    </span>
                    <span className="icon_list_text">
                      Training and Documentation
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_icon">
                      <img src={icon} alt="Check SVG Icon" />
                    </span>
                    <span className="icon_list_text">
                      Infrastructure Preparation
                    </span>
                  </li>
                  <li>
                    <span className="icon_list_icon">
                      <img src={icon} alt="Check SVG Icon" />
                    </span>
                    <span className="icon_list_text">
                      Post-migration Support
                    </span>
                  </li>
                </ul>
              </div> */}
            </div>

            <h3 className="details_item_info_title pt-4">Project Highlights</h3>
            <p style={{ textAlign: "justify" }}>
              The project highlights encompass the seamless integration of
              advanced tools and features that enhance functionality and
              security. Key elements such as role-based access control,
              AI-powered chatbots, and robust cloud migration strategies have
              been implemented to streamline operations and improve user
              experience. These tools not only ensure secure and efficient
              workflows but also contribute to real-time responsiveness and
              automation, driving operational excellence. By leveraging these
              technologies, the solution adapts to the specific needs of each
              client, ensuring a personalized and efficient approach to
              achieving business objectives.
            </p>
            <p style={{ textAlign: "justify" }}>
              Additionally, rigorous testing and validation were carried out at
              every stage of the project to ensure the highest standards of data
              integrity and compliance with industry regulations. This
              meticulous process guarantees that the system is both secure and
              reliable, minimizing risks related to data loss or non-compliance.
              As businesses evolve, we continue to offer ongoing support,
              adapting our solutions to meet the changing demands of the market
              and the client’s business. Our aim is to transform industries,
              empower communities, and create lasting value for our clients and
              stakeholders, ultimately delivering a solution that not only meets
              current needs but is also scalable for future growth.
            </p>

            <h3 className="details_item_info_title pt-5 mb-4">
              Our More Projects
            </h3>
            <div className="row">
              {Project.slice(9, 18).map((project, prj) => (
                <div className="col-lg-6" key={prj}>
                  <div className="portfolio_block portfolio_layout_2">
                    <div className="portfolio_image">
                      <Link
                        onClick={ClickHandler}
                        className="portfolio_image_wrap bg-light"
                        to={`/portfolio_details/${project.slug}`}
                      >
                        <img src={project.pImg} alt="Mobile App Design" />
                      </Link>
                    </div>
                    <div className="portfolio_content">
                      <h3 className="portfolio_title">
                        <Link
                          onClick={ClickHandler}
                          to={`/portfolio_details/${project.slug}`}
                        >
                          {project.title}
                        </Link>
                      </h3>
                      <ul className="category_list unordered_list">
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to={`/portfolio_details/${project.slug}`}
                          >
                            <i className="fa-solid fa-tags"></i>{" "}
                            {project.thumb1}
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to={`/portfolio_details/${project.slug}`}
                          >
                            <i className="fa-solid fa-building"></i>{" "}
                            {project.thumb2}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <CtaSection />
      </main>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default PortfolioSinglePage;
