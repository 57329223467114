import React from "react";
import ContactForm from "../ContactFrom/ContactForm";
import icon1 from "../../images/icons/icon_map_mark_2.svg";
import icon2 from "../../images/icons/icon_calling_2.svg";
import icon3 from "../../images/icons/icon_mail_3.svg";
import icon4 from "../../images/icons/icon_calendar_2.svg";

const ContactSection = (props) => {
  return (
    <section className="contact_section section_space bg-light">
      <div className="container">
        <div className="contact_info_box row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="iconbox_block text-center">
              <div className="iconbox_icon">
                <img src={icon1} alt="Map Mark SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">Location</h3>
                <p className="mb-0">K-15, Malviya Nagar, New Delhi 110017</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="iconbox_block text-center">
              <div className="iconbox_icon">
                <img src={icon2} alt="Calling SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">Contact</h3>
                <p className="mb-0">+91 9210970334</p>
                {/* <div className="mb-0">+91 9211269650</div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="iconbox_block text-center">
              <div className="iconbox_icon">
                <img src={icon3} alt="User Check SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">Email</h3>
                <p className="mb-0">info@ufsnetworks.com</p>
                {/* <p className="mb-0">hr@ufsnetworks.com</p> */}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="iconbox_block text-center">
              <div className="iconbox_icon">
                <img src={icon4} alt="Calendar SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">Visit Between</h3>
                <p className="mb-0">Mon - Fri: 10.00-7.00</p>
                <p className="mb-0">Sat - Sun: Closed</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section_space pb-0">
          <div className="row justify-content-lg-between">
            <div className="col-lg-7">
              <div className="contact_form mb-0">
                <h3 className="details_item_info_title mb-1">
                  Send Us A Message
                </h3>
                <p className="mb-5">
                  Give us chance to serve and bring magic to your brand.
                </p>
                <ContactForm />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="gmap_canvas ps-lg-5">
                <iframe
                  title="Google Maps - Unified Consultancy Services"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.276083878646!2d77.20785277925587!3d28.531420226082094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce21e1c682413%3A0x1eda673a5cea69ba!2sUnified%20Consultancy%20Services!5e0!3m2!1sen!2sin!4v1734184751000!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
