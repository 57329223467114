import React, { Fragment, useState } from "react";
import Header from "../../components/Header2/Header2";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import aImg from "../../images/about/about_image_3.webp";
import aImg2 from "../../images/about/about_image_4.webp";
import ModalVideo from "react-modal-video";
import PolicySection from "./Policy";
import WhyUs from "./WhyUs";
import FeaturesSection from "../../components/FeaturesSection/FeaturesSection";

import CtaSection from "../../components/CtaSection/CtaSection";
import { HelmetProvider } from "react-helmet-async";
import SEO from "../../components/SEO";
const AboutUsPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <SEO
        title="UFS Networks: Expert IT Consulting & Professional Services"
        description="UFS Networks delivers expert IT consulting, managed services, and cutting-edge solutions to drive business success with reliable, scalable, and secure technology."
        url="https://www.ufsnetworks.com/about"
        image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
        canonicalUrl="https://www.ufsnetworks.com/about"
      />
      <Header />
      <main className="page_content about-page">
        <PageTitle
          pageTitle="About Us"
          pagesub="UFS Networks 😃"
          pageTop="About"
        />

        <section className="intro_about_section section_space bg-light">
          <div className="intro_about_content">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="image_wrap">
                    <img src={aImg} alt="UFS - About " />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="image_wrap position-relative">
                    <img src={aImg2} alt="UFS - About" />
                    <button
                      className="video_btn ripple_effect"
                      onClick={() => setIsOpen(true)}
                    >
                      <span className="btn_icon">
                        <i className="fa-solid fa-play"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="heading_block mb-0">
              <div className="row justify-content-lg-between">
                <div className="col-lg-4">
                  <div className="heading_focus_text">
                    About
                    <span className="badge bg-secondary text-white">
                      Unified Consultancy Services
                    </span>
                  </div>
                  <h2 className="heading_text mb-0">
                    We Provide You Flexible Services
                  </h2>
                </div>
                <div className="col-lg-6">
                  <p className="heading_description mb-0">
                    At UFS Networks, we are the trusted partner for global
                    enterprises, SMEs, and tech innovators, delivering
                    exceptional value through custom software development,
                    professional IT services, and strategic consultancy. Our
                    expertise spans modernizing IT infrastructures, optimizing
                    cloud technologies, and resolving complex infrastructure
                    challenges to drive business growth and innovation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <PolicySection />
        <FeaturesSection />

        <WhyUs />
        <CtaSection />
      </main>
      <Footer />
      <Scrollbar />
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="qsvo_-rSNB4"
        onClose={() => setIsOpen(false)}
      />
    </Fragment>
  );
};

export default AboutUsPage;
