import React, { Fragment } from "react";

import Header2 from "../../components/Header2/Header2";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import { HelmetProvider } from "react-helmet-async";
import SEO from "../../components/SEO";
import ContactSection from "../../components/ContactSection";

const ContactPage = (props) => {
  return (
    <Fragment>
      <SEO
        title="Contact UFS Networks – Get in Touch for Software & IT Solutions"
        description="Contact UFS Networks for expert IT solutions, including software development, AI, DevOps, and cybersecurity services. Reach out today to discuss your project needs"
        url="https://www.ufsnetworks.com/contact"
        image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
        canonicalUrl="https://www.ufsnetworks.com/contact"
      />
      <Header2 />
      <main className="page_content about-page">
        <PageTitle
          pageTitle={"Contact Us"}
          pagesub={"Us 😍"}
          pageTop={"Contact"}
        />
        <ContactSection />
        <CtaSection />
      </main>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ContactPage;
