import sImg1 from "../images/services/service_image_1.webp";
import sImg2 from "../images/services/service_image_2.webp";
import sImg3 from "../images/services/service_image_3.webp";
import sImg4 from "../images/services/service_image_4.webp";
import sImg5 from "../images/services/service_image_5.webp";

import icon1 from "../images/icons/icon_code.svg";
import icon2 from "../images/icons/icon_programming_tree.svg";
import icon3 from "../images/icons/icon_monitor_2.svg";
import icon4 from "../images/icons/icon_phone.svg";
import icon5 from "../images/icons/icon_bug.svg";
import icon6 from "../images/icons/icon_programming.svg";

import icon7 from "../images/icons/icon_analisis_2.svg";
import icon8 from "../images/icons/icon_process.svg";
import icon9 from "../images/icons/icon_chart_2.svg";
import icon10 from "../images/icons/icon_meeting.svg";
import icon11 from "../images/icons/icon_bulb_2.svg";
import icon12 from "../images/icons/icon_speed_miter.svg";

const Services = [
  {
    Id: "1",
    sImg: sImg1,
    title: "Custom Software Development",
    slug: "SoftwareDevelopement",
    thumb1: "Software",
    thumb2: "FullStack",
    col: "col-lg-6",
    description:
      "Visit new places to discover with a Tourist Visa. We deliver your documents ...",
  },
  {
    Id: "2",
    sImg: sImg2,
    title: "Artificial Intelligence",
    slug: "Artificial Intelligence",
    thumb1: "AI ML",
    thumb2: "GenAI",
    col: "col-lg-6",
    description:
      "Developing your trade, setting up new sales channels Your visa is ready...",
  },
  {
    Id: "3",
    sImg: sImg3,
    title: "Consultancy Services",
    slug: "Consultancy",
    thumb1: "HR Consultancy",
    thumb2: "Tech Consultancy",
    col: "col-lg-4",
    description:
      "Developing your trade, setting up new sales channels Your visa is ready...",
  },
  {
    Id: "4",
    sImg: sImg4,
    title: "Cloud Technologies",
    slug: "CloudTechnologies",
    thumb1: "AWS",
    thumb2: "Azure",
    col: "col-lg-4",
    description:
      "Embarking on a journey of higher education in a foreign country opens doors to...",
  },
  {
    Id: "5",
    sImg: sImg5,
    title: "Cyber Security",
    slug: "CyberSecurity",
    thumb1: "SIEM",
    thumb2: "Pen Testing",
    col: "col-lg-4",
    description:
      "Expert Guidance for a Seamless Immigration Journey Expert Guidance...",
  },
  {
    Id: "6",
    sImg: icon1,
    title: "Software Development",
    slug: "SoftwareDevelopment",
    features: [
      "Software architecture design",
      "System integration services",
      "Data migration services",
      "Legacy app modernization",
    ],
  },
  {
    Id: "7",
    sImg: icon2,
    title: "Artificial Intelligence",
    slug: "ArtificialIntelligence",
    features: [
      "Automation Optimization",
      "Data Analysis",
      "Natural Language Processing",
      "Computer Vision",
    ],
  },
  {
    Id: "8",
    sImg: icon3,
    title: "HR Recruitment",
    slug: "Recruitment",
    features: [
      "Talent Acquisition and Screening",
      "Diversity and Inclusion Initiatives",
      "Data-Driven Hiring Decisions",
      "Streamlined Interview Management",
    ],
  },
  {
    Id: "9",
    sImg: icon4,
    title: "Cloud & DevOps",
    slug: "CloudDevOps",
    features: [
      "Scalable and Secure Cloud Migration",
      "Continuous Integration and Deployment (CI/CD)",
      "Infrastructure as Code (IaC)",
      "Monitoring and Collaboration",
    ],
  },
  {
    Id: "10",
    sImg: icon5,
    title: "Consultancy Services",
    slug: "Consultancy",
    features: [
      "Strategic Planning and Advisory",
      "Enhancing Operational Efficiency",
      "Technology Deployement and Support",
      "Risk Management",
    ],
  },
  {
    Id: "11",
    sImg: icon6,
    title: "Cybersecurity",
    slug: "Cybersecurity",
    features: [
      "Threat Detection and Response Action",
      "Security Assessments and Audits",
      "Data Protection",
      "Employee Training and Awareness",
    ],
  },
  {
    Id: "12",
    sImg: icon7,
    title: "Strategic Planning and Execution",
    slug: "Strategic-Planning-and-Execution",
    description:
      "Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.",
  },
  {
    Id: "13",
    sImg: icon8,
    title: "Business Process Optimization",
    slug: "Business-Process-Optimization",
    description:
      "Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.",
  },
  {
    Id: "14",
    sImg: icon9,
    title: "Digital Transformation Consulting",
    slug: "Digital-Transformation-Consulting",
    description:
      "Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.",
  },
  {
    Id: "15",
    sImg: icon10,
    title: "Strategic Planning and Execution",
    slug: "Strategic-Planning-and-Executions",
    description:
      "Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.",
  },
  {
    Id: "16",
    sImg: icon11,
    title: "Change Management Solutions",
    slug: "Change-Management-Solutions",
    description:
      "Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.",
  },
  {
    Id: "17",
    sImg: icon12,
    title: "Performance Metrics and KPI Development",
    slug: "Performance-Metrics-and-KPI-Development",
    description:
      "Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.",
  },

  {
    Id: "18",
    sImg: icon1,
    title: "Software Development",
    slug: "SoftwareDevelopment",
    description: `
      Software Development is at the core of technological evolution, enabling businesses to create solutions tailored to their needs. 
      At our agency, we specialize in building custom software with a focus on functionality, scalability, and user experience.
      Our team excels in a wide range of technologies including Python, Java, .NET, and modern frameworks like React and Angular. 
      We deliver services such as enterprise software, SaaS platforms, and mobile applications that solve real-world problems.
    `,
    technologies: ["React.js", "Angular", "Python", "Node.js", ".NET", "Java"],
    scope: `
      From conceptualization to deployment, our software development services are designed to adapt to diverse industries 
      including healthcare, finance, retail, and logistics. The scope extends to system upgrades, API integrations, and cloud-based application deployment.
    `,
  },
  {
    Id: "19",
    sImg: icon2,
    title: "Artificial Intelligence",
    slug: "ArtificialIntelligence",
    description: `
      Artificial Intelligence transforms data into actionable insights and automates complex processes. 
      We deliver AI solutions that include machine learning models, predictive analytics, and AI-powered chatbots, 
      ensuring businesses stay ahead in a competitive market. Our expertise lies in deep learning frameworks like TensorFlow and PyTorch, 
      as well as advanced natural language processing.
    `,
    technologies: ["TensorFlow", "PyTorch", "OpenAI", "Scikit-learn", "Keras"],
    scope: `
      Our AI services span industries like e-commerce, healthcare, and logistics, addressing needs such as personalization, fraud detection, 
      and predictive maintenance. Whether it’s creating an intelligent chatbot or designing computer vision systems, our solutions are scalable and secure.
    `,
  },
  {
    Id: "20",
    sImg: icon3,
    title: "Consultancy Services",
    slug: "Consultancy",
    description: `
      Our consultancy services are designed to bridge the gap between technology and business strategy. 
      We provide expert advisory on operational efficiency, technological implementation, and risk management.
      From startups to multinational corporations, our services include in-depth market analysis and strategic planning to help businesses achieve measurable growth.
    `,
    technologies: ["ERP Systems", "CRM Tools", "BI Platforms"],
    scope: `
      The scope of our consultancy services includes digital transformation, business process reengineering, and resource optimization. 
      We cater to businesses looking to leverage modern technologies to streamline their operations and enhance productivity.
    `,
  },
  {
    Id: "21",
    sImg: icon4,
    title: "Cloud Technologies",
    slug: "CloudTechnologies",
    description: `
      Cloud Technologies empower businesses to operate with flexibility, scalability, and resilience. 
      Our services include cloud migration, infrastructure setup, and managed cloud solutions tailored to client needs. 
      We specialize in platforms such as AWS, Azure, and Google Cloud, ensuring seamless transitions and high uptime.
    `,
    technologies: ["AWS", "Azure", "Google Cloud", "Kubernetes", "Docker"],
    scope: `
      Our cloud services cover application hosting, serverless computing, and disaster recovery planning. 
      Industries such as fintech, gaming, and education benefit from our customized cloud solutions for secure and efficient operations.
    `,
  },
  {
    Id: "22",
    sImg: icon5,
    title: "DevOps Control",
    slug: "DevOps",
    description: `
      DevOps accelerates software delivery cycles while enhancing reliability and scalability. 
      Our DevOps services include CI/CD pipelines, automated testing, and infrastructure management using tools like Jenkins and Ansible.
      We streamline workflows to foster collaboration between development and operations teams.
    `,
    technologies: ["Jenkins", "Ansible", "Terraform", "Kubernetes", "Docker"],
    scope: `
      The scope of DevOps services includes automation, monitoring, and performance optimization for cloud and on-premise systems. 
      Businesses in tech, finance, and entertainment rely on our expertise to improve their software delivery processes.
    `,
  },
  {
    Id: "23",
    sImg: icon6,
    title: "Cybersecurity",
    slug: "Cybersecurity",
    description: `
      Cybersecurity is a critical component of modern business operations, protecting sensitive data from ever-evolving threats. 
      Our services include vulnerability assessments, threat detection, and compliance audits to ensure robust defense mechanisms.
      We utilize advanced tools and frameworks to provide end-to-end security solutions.
    `,
    technologies: [
      "Firewalls",
      "SIEM",
      "Endpoint Security Tools",
      "Penetration Testing",
    ],
    scope: `
      From securing network infrastructure to employee training, our cybersecurity solutions serve industries like banking, healthcare, and retail. 
      Our services are tailored to protect against data breaches, ransomware, and insider threats, ensuring business continuity.
    `,
  },
];

export default Services;
