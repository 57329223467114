import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage2 from "../HomePage2/HomePage2";
import AboutUsPage from "../AboutUsPage/AboutUsPage";
import PricingPage from "../PricingPage/PricingPage";
import PortfolioPage from "../PortfolioPage/PortfolioPage";
import PortfolioSinglePage from "../PortfolioSinglePage/PortfolioSinglePage";

import TeamSinglePage from "../TeamSinglePage/TeamSinglePage";
import ServicePage from "../ServicePage/ServicePage";
import ServiceSinglePage from "../ServiceSinglePage/ServiceSinglePage";
import BlogPage from "../BlogPage/BlogPage";
import BlogDetails from "../BlogDetails/BlogDetails";
import ContactPage from "../ContactPage/ContactPage";
import CareerPage from "../Careers/CareerPage";
import Login from "../Login/Login";
import Dashboard from "../Dashboard/Dashboard";
import PostJob from "../Dashboard/Postjob";
import ContactDF from "../ContactFormData/ContactDF";
import NewsLetterEmail from "../newsLetterEmail/NewsLetterEmail";
import ApplyJob from "../Dashboard/ApplyJob";
import { AuthProvider, ProtectedRoute } from "../Login/AuthContext";
import ManageJob from "../Dashboard/ManageJob";
import ManageApplications from "../Dashboard/ManageApplications";
import PostBlog from "../Dashboard/postBlog";
import { HelmetProvider } from "react-helmet-async";
import SEO from "../../components/SEO";

const AllRoute = () => {
  return (
    <HelmetProvider>
      {" "}
      {/* Wrap everything inside HelmetProvider */}
      <AuthProvider>
        <div className="App">
          <BrowserRouter>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<HomePage2 />} />
              <Route path="home" element={<HomePage2 />} />
              <Route path="about" element={<AboutUsPage />} />
              <Route path="pricing" element={<PricingPage />} />
              <Route path="portfolio" element={<PortfolioPage />} />
              <Route
                path="portfolio_details/:slug"
                element={<PortfolioSinglePage />}
              />

              <Route path="team-single/:slug" element={<TeamSinglePage />} />
              <Route path="service" element={<ServicePage />} />
              <Route
                path="service-single/:slug"
                element={<ServiceSinglePage />}
              />
              <Route path="blog" element={<BlogPage />} />
              <Route path="blog-single/:slug" element={<BlogDetails />} />
              <Route path="contact" element={<ContactPage />} />
              <Route path="career" element={<CareerPage />} />
              <Route path="login" element={<Login />} />
              <Route path="apply/:jobId" element={<ApplyJob />} />

              {/* Protected Routes */}
              <Route
                path="dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="dashboard/contact-data"
                element={
                  <ProtectedRoute>
                    <ContactDF />
                  </ProtectedRoute>
                }
              />
              <Route
                path="dashboard/newsletter-data"
                element={
                  <ProtectedRoute>
                    <NewsLetterEmail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="dashboard/post-job"
                element={
                  <ProtectedRoute>
                    <PostJob />
                  </ProtectedRoute>
                }
              />
              <Route
                path="dashboard/manage-jobs"
                element={
                  <ProtectedRoute>
                    <ManageJob />
                  </ProtectedRoute>
                }
              />
              <Route
                path="dashboard/manage-applications"
                element={
                  <ProtectedRoute>
                    <ManageApplications />
                  </ProtectedRoute>
                }
              />
              <Route
                path="dashboard/post-blog"
                element={
                  <ProtectedRoute>
                    <PostBlog />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </div>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default AllRoute;
