import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";

const Search = ({ onSearchResults }) => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [search, setSearch] = useState("");
  const [experience, setExperience] = useState("");
  const [skillset, setSkillset] = useState("");
  const [location, setLocation] = useState("");
  const [jobType, setJobType] = useState("");
  const [department, setDepartment] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const fetchJobs = () => {
    fetch(`${process.env.REACT_APP_BACKEND_BASEURL}/api/jobs`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setJobs(data.data);
          setFilteredJobs(data.data);
          onSearchResults(data.data);
        } else {
          console.error("Failed to fetch jobs:", data.message);
        }
      })
      .catch((err) => console.error("Error fetching jobs:", err));
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    let filtered = jobs.filter((job) => {
      const searchTerms = search.toLowerCase().trim();
      const searchMatch = !searchTerms
        ? true
        : [job.title, job.experience, job.skillset, job.location, job.jobType, job.department].some(
            (field) => field && field.toString().toLowerCase().includes(searchTerms)
          );

      const experienceMatch = !experience || job.experience === experience;
      const skillsetMatch = !skillset || (job.skillset && job.skillset.includes(skillset));
      const locationMatch = !location || job.location === location;
      const jobTypeMatch = !jobType || job.jobType === jobType;
      const departmentMatch = !department || job.department === department;

      return searchMatch && experienceMatch && skillsetMatch && locationMatch && jobTypeMatch && departmentMatch;
    });

    if (sortOrder === "newest") {
      filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (sortOrder === "oldest") {
      filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    }

    setFilteredJobs(filtered);
    onSearchResults(filtered);
  }, [search, experience, skillset, location, jobType, department, sortOrder, jobs]);

  return (
    <div style={{ padding: "20px", maxWidth: "1100px", margin: "auto", textAlign: "center" }}>
      <div style={{ 
        display: "flex", 
        alignItems: "center", 
        border: "2px solid #ccc", 
        borderRadius: "10px", 
        padding: "10px", 
        backgroundColor: "#fff", 
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        transition: "box-shadow 0.3s ease-in-out"
      }}
        onMouseEnter={(e) => e.currentTarget.style.boxShadow = "0px 6px 10px rgba(0, 0, 0, 0.15)"}
        onMouseLeave={(e) => e.currentTarget.style.boxShadow = "0px 4px 6px rgba(0, 0, 0, 0.1)"}
      >
        <FiSearch style={{ marginRight: "10px", color: "#888" }} />
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search jobs..."
          style={{
            width: "100%",
            padding: "8px",
            border: "none",
            outline: "none",
          }}
        />
      </div>
    </div>
  );
};

export default Search;

