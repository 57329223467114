import React, { useState, useEffect } from "react";
import DashboardLayout from "./DashboardLayout";
import { X, Download } from "lucide-react"; // Importing X and Download icons
import Quill from "react-quill";
import "react-quill/dist/quill.snow.css";
import JobSearchFilter from "./JobSearchFilter";

const ManageJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 10;

  // State for Edit Modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editJob, setEditJob] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  // Add state for job details view
  const [viewingJob, setViewingJob] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  // State for confirmation dialog
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  const jobModeOptions = ["Remote", "Onsite", "Hybrid"];

  const stateOptions = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    // When jobs are fetched or changed, update filtered jobs
    setFilteredJobs(jobs);
  }, [jobs]);

  const fetchJobs = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASEURL}/api/jobs`
      );
      const data = await response.json();
      if (data.success) {
        setJobs(data.data);
      } else {
        console.error("Failed to fetch jobs:", data.message);
        showNotification(
          "Failed to load jobs. Please try again later.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
      showNotification(
        "Error connecting to server. Please check your connection.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  // Handle Delete Function
  const handleDelete = async (jobId) => {
    setIsUpdating(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASEURL}/api/jobs/${jobId}`,
        { method: "DELETE" }
      );
      const data = await response.json();
      if (data.success) {
        setJobs(jobs.filter((job) => job.jobId !== jobId));
        showNotification("Job deleted successfully", "success");
      } else {
        showNotification("Failed to delete job: " + data.message, "error");
      }
    } catch (error) {
      console.error("Error deleting job:", error);
      showNotification("Error deleting job. Please try again.", "error");
    } finally {
      setIsUpdating(false);
      setShowConfirmDialog(false);
      setJobToDelete(null);
    }
  };

  const confirmDelete = (jobId) => {
    setJobToDelete(jobId);
    setShowConfirmDialog(true);
  };

  // Handle View Job Details
  const handleViewDetails = (job) => {
    setViewingJob(job);
    setIsViewModalOpen(true);
  };

  // Handle Edit (Open Modal)
  const handleEdit = (job) => {
    setEditJob({ ...job });
    setFormErrors({});
    setIsEditModalOpen(true);
  };

  // Validate form data
  const validateForm = () => {
    const errors = {};

    if (!editJob.title?.trim()) errors.title = "Job title is required";
    if (!editJob.location?.trim()) errors.location = "Location is required";
    if (!editJob.experience?.trim())
      errors.experience = "Experience level is required";
    if (!editJob.skillset?.trim()) errors.skillset = "Skillset is required";
    if (!editJob.jobType?.trim()) errors.jobType = "Job type is required";
    if (!editJob.department?.trim())
      errors.department = "Department is required";
    if (!editJob.description?.trim())
      errors.description = "Job description is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle Edit Form Submit
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsUpdating(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASEURL}/api/jobs/${editJob.jobId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(editJob),
        }
      );
      const data = await response.json();
      if (data.success) {
        setJobs(
          jobs.map((job) => (job.jobId === editJob.jobId ? editJob : job))
        );
        setIsEditModalOpen(false);
        showNotification("Job updated successfully", "success");
      } else {
        console.error("Failed to update job:", data.message);
        showNotification("Failed to update job: " + data.message, "error");
      }
    } catch (error) {
      console.error("Error updating job:", error);
      showNotification("Error updating job. Please try again.", "error");
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle Input Change in Edit Form
  const handleInputChange = (e) => {
    setEditJob({ ...editJob, [e.target.name]: e.target.value });
  };

  // Handle Description Change (for Quill editor)
  const handleDescriptionChange = (content) => {
    setEditJob({ ...editJob, description: content });
  };

  // Notification helper
  const showNotification = (message, type) => {
    const notification = document.createElement("div");
    notification.className = `notification ${type}`;
    notification.textContent = message;
    document.body.appendChild(notification);

    setTimeout(() => {
      notification.classList.add("visible");
    }, 10);

    setTimeout(() => {
      notification.classList.remove("visible");
      setTimeout(() => {
        document.body.removeChild(notification);
      }, 300);
    }, 3000);
  };

  // Export to Excel function
  const exportToExcel = () => {
    // Create a CSV string
    let csvContent = "data:text/csv;charset=utf-8,";

    // Add headers
    csvContent +=
      "Job ID,Title,Location,Experience,Job Type,Department,Skillset\n";

    // Add job data
    jobs.forEach((job) => {
      // Exclude description field
      const row = [
        job.jobId,
        `"${job.title?.replace(/"/g, '""') || ""}"`,
        `"${job.location?.replace(/"/g, '""') || ""}"`,
        `"${job.experience?.replace(/"/g, '""') || ""}"`,
        `"${job.jobType?.replace(/"/g, '""') || ""}"`,
        `"${job.department?.replace(/"/g, '""') || ""}"`,
        `"${job.skillset?.replace(/"/g, '""') || ""}"`,
      ].join(",");
      csvContent += row + "\n";
    });

    // Create a download link
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `jobs_export_${new Date().toISOString().split("T")[0]}.csv`
    );
    document.body.appendChild(link);

    // Trigger download
    link.click();
    document.body.removeChild(link);
  };

  // Pagination Logic
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
  const totalPages = Math.max(1, Math.ceil(filteredJobs.length / jobsPerPage));

  const handleFilteredJobsChange = (newFilteredJobs) => {
    setFilteredJobs(newFilteredJobs);
    setCurrentPage(1); // Reset to first page when filters change
  };

  // Option lists - from PostJob.js
  const skillOptions = [
    "Human Resources",
    "Recruitment",
    "Employee Relations",
    "Payroll",
    "Training and Development",
    "Compliance",
    "Finance",
    "Financial Analysis",
    "Budgeting",
    "Accounting",
    "Auditing",
    "Financial Reporting",
    "Marketing",
    "SEO",
    "Content Marketing",
    "Social Media Marketing",
    "Email Marketing",
    "PPC",
    "Branding",
    "Sales",
    "Lead Generation",
    "CRM",
    "Negotiation",
    "Sales Strategy",
    "Customer Relationship Management",
    "Information Technology (IT)",
    "Network Administration",
    "Cybersecurity",
    "Software Development",
    "Database Management",
    "Technical Support",
    "Customer Service",
    "Communication",
    "Problem-Solving",
    "CRM",
    "Conflict Resolution",
    "Customer Support",
    "Operations",
    "Process Improvement",
    "Supply Chain Management",
    "Inventory Management",
    "Operations Strategy",
    "Logistics",
    "Research and Development (R&D)",
    "Product Development",
    "Market Research",
    "Innovation",
    "Data Analysis",
    "Project Management",
    "Product Management",
    "Product Strategy",
    "Market Analysis",
    "Agile Methodology",
    "Product Lifecycle Management",
    "Roadmapping",
    "Engineering",
    "Mechanical Engineering",
    "Electrical Engineering",
    "Civil Engineering",
    "Quality Control",
    "Project Management",
    "Supply Chain Management",
    "Procurement",
    "Logistics",
    "Supplier Management",
    "Demand Planning",
    "Inventory Control",
    "Logistics",
    "Telecommunication",
    "Transportation Management",
    "Warehouse Management",
    "Distribution Planning",
    "Supply Chain Coordination",
    "Legal",
    "Contract Law",
    "Compliance",
    "Intellectual Property",
    "Litigation",
    "Corporate Law",
    "Public Relations (PR)",
    "Media Relations",
    "Press Release Writing",
    "Crisis Management",
    "Event Planning",
    "Communications",
    "Quality Assurance (QA)",
    "Quality Control",
    "Testing",
    "Compliance",
    "Auditing",
    "Process Improvement",
    "Administration",
    "Office Management",
    "Scheduling",
    "Administrative Support",
    "Document Management",
    "Communication",
    "Design",
    "Graphic Design",
    "UX/UI Design",
    "Visual Communication",
    "Adobe Creative Suite",
    "Prototyping",
    "Project Management",
    "Project Planning",
    "Risk Management",
    "Stakeholder Communication",
    "Budgeting",
    "Agile Methodology",
    "Compliance",
    "Regulatory Knowledge",
    "Risk Assessment",
    "Auditing",
    "Policy Development",
    "Reporting",
    "Training and Development",
    "Training Program Design",
    "Instructional Design",
    "E-learning",
    "Coaching",
  ].sort();

  const experienceOptions = [
    "Fresher",
    "Early Professional",
    "Mid Level",
    "Senior",
    "Super Senior",
  ];

  const jobTypeOptions = [
    "Full Time",
    "Part Time",
    "Contract",
    "Consultant",
    "Freelancer",
    "Internship",
  ];

  const departmentOptions = [
    "Human Resources",
    "Finance",
    "Marketing",
    "Sales",
    "Information Technology (IT)",
    "Customer Service",
    "Operations",
    "Research and Development (R&D)",
    "Product Management",
    "Engineering",
    "Supply Chain Management",
    "Logistics",
    "Legal",
    "Public Relations (PR)",
    "Quality Assurance (QA)",
    "Administration",
    "Design",
    "Project Management",
    "Compliance",
    "Training and Development",
  ];

  return (
    <DashboardLayout>
      <div className="p-6">
        <div className="bg-white rounded-lg shadow-md">
          <div className="p-5 border-b border-gray-200">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-2xl font-bold text-gray-800">
                  Manage Jobs
                </h2>
                <p className="text-gray-600 mt-1">
                  View, edit, and manage your job listings
                </p>
                <button
                  onClick={exportToExcel}
                  className="flex items-center px-4 py-2 border border-gray-300 rounded text-gray-700 bg-white hover:bg-gray-50 transition-colors"
                  disabled={jobs.length === 0}
                >
                  <Download size={16} className="mr-2" />
                  Download as Excel
                </button>
              </div>
            </div>
          </div>
          <div className="p-4">
            <JobSearchFilter
              jobs={jobs}
              onFilteredJobsChange={handleFilteredJobsChange}
              jobModeOptions={jobModeOptions}
              jobTypeOptions={jobTypeOptions}
              departmentOptions={departmentOptions}
              experienceOptions={experienceOptions}
            />
          </div>
          {loading ? (
            <div className="flex justify-center items-center p-12">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              <span className="ml-3 text-gray-600">Loading jobs...</span>
            </div>
          ) : filteredJobs.length === 0 ? (
            <div className="text-center p-8">
              <p className="text-gray-600 mb-4">No jobs found</p>
              <a
                href="/post-job"
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
              >
                Post Your First Job
              </a>
            </div>
          ) : (
            <div className="p-4">
              <div className="overflow-x-auto bg-gray-50 rounded-lg">
                <table className="w-full border-collapse">
                  <thead>
                    <tr>
                      <th className="p-3 bg-gray-800 text-black">S.No</th>
                      <th className="p-3 bg-gray-800 text-black">Title</th>
                      <th className="p-3 bg-gray-800 text-black">Location</th>
                      <th className="p-3 bg-gray-800 text-black">Experience</th>
                      <th className="p-3 bg-gray-800 text-black">Job Type</th>
                      <th className="p-3 bg-gray-800 text-black">Department</th>
                      {/* <th className="p-3 bg-gray-800 text-black">Job Mode</th> */}
                      <th className="p-3 bg-gray-800 text-black">Priority</th>
                      <th className="p-3 bg-gray-800 text-black">
                        Date Posted
                      </th>
                      <th className="p-3 bg-gray-800 text-white">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentJobs.map((job, index) => (
                      <tr
                        key={job.jobId}
                        className={`hover:bg-gray-100 transition-colors ${
                          index % 2 === 0 ? "bg-white" : "bg-gray-50"
                        }`}
                      >
                        <td className="p-3 border-b">
                          {index + 1 + (currentPage - 1) * jobsPerPage}
                        </td>
                        <td className="p-3 border-b">{job.title}</td>
                        <td className="p-3 border-b">{job.location}</td>
                        <td className="p-3 border-b">{job.experience}</td>
                        <td className="p-3 border-b">
                          {job.jobType || "Full Time"}
                        </td>
                        <td className="p-3 border-b">
                          {job.department || "N/A"}
                        </td>
                        {/* <td className="p-3 border-b">{job.jobMode || "N/A"}</td> */}
                        <td className="p-3 border-b">
                          <span
                            className={`px-2 py-1 rounded-full text-xs ${
                              job.priority
                                ? "bg-red-200 text-red-800"
                                : "bg-green-200 text-green-800"
                            }`}
                          >
                            {job.priority ? "High" : "Normal"}
                          </span>
                        </td>
                        <td className="p-3 border-b">
                          {new Date(job.createdAt).toLocaleDateString()}
                        </td>
                        <td className="p-3 border-b">
                          <div className="flex space-x-2 justify-center">
                            <button
                              onClick={() => handleViewDetails(job)}
                              className="p-1 text-gray-600 hover:text-gray-800 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors"
                              title="View Details"
                            >
                              👁️
                            </button>
                            <button
                              onClick={() => handleEdit(job)}
                              className="p-1 text-blue-600 hover:text-blue-800 bg-blue-100 rounded-full hover:bg-blue-200 transition-colors"
                              title="Edit Job"
                            >
                              ✏️
                            </button>
                            <button
                              onClick={() => confirmDelete(job.jobId)}
                              className="p-1 text-red-600 hover:text-red-800 bg-red-100 rounded-full hover:bg-red-200 transition-colors"
                              title="Delete Job"
                            >
                              🗑️
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Pagination */}
              {totalPages > 1 && (
                <div className="flex justify-center mt-6">
                  <div className="flex items-center bg-white rounded-lg shadow-sm">
                    <button
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                      disabled={currentPage === 1}
                      className={`px-4 py-2 rounded-l-lg transition-colors ${
                        currentPage === 1
                          ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                          : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                      }`}
                    >
                      ← Previous
                    </button>
                    <div className="px-4 py-2 bg-white text-gray-700 font-medium">
                      Page {currentPage} of {totalPages}
                    </div>
                    <button
                      onClick={() =>
                        setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                      }
                      disabled={currentPage === totalPages}
                      className={`px-4 py-2 rounded-r-lg transition-colors ${
                        currentPage === totalPages
                          ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                          : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                      }`}
                    >
                      Next →
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* View Job Details Modal */}
      {isViewModalOpen && viewingJob && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-2xl max-h-full overflow-hidden">
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-xl font-semibold text-gray-800">
                Job Details
              </h3>
              <button
                onClick={() => setIsViewModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>
            <div className="p-6 overflow-y-auto max-h-96">
              <h2 className="text-2xl font-bold mb-2">{viewingJob.title}</h2>

              <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="bg-gray-50 p-3 rounded">
                  <p className="text-sm text-gray-500">Location</p>
                  <p className="font-medium">{viewingJob.location}</p>
                </div>
                <div className="bg-gray-50 p-3 rounded">
                  <p className="text-sm text-gray-500">Experience</p>
                  <p className="font-medium">{viewingJob.experience}</p>
                </div>
                <div className="bg-gray-50 p-3 rounded">
                  <p className="text-sm text-gray-500">Job Type</p>
                  <p className="font-medium">
                    {viewingJob.jobType || "Full Time"}
                  </p>
                </div>
                <div className="bg-gray-50 p-3 rounded">
                  <p className="text-sm text-gray-500">Department</p>
                  <p className="font-medium">
                    {viewingJob.department || "N/A"}
                  </p>
                </div>
                <div className="bg-gray-50 p-3 rounded col-span-2">
                  <p className="text-sm text-gray-500">Skills Required</p>
                  <p className="font-medium">{viewingJob.skillset}</p>
                </div>
              </div>

              <div className="mt-4">
                <h3 className="font-semibold text-lg mb-2">Description</h3>
                <div
                  className="prose max-w-none bg-gray-50 p-4 rounded"
                  dangerouslySetInnerHTML={{ __html: viewingJob.description }}
                ></div>
              </div>
            </div>
            <div className="p-4 border-t bg-gray-50 flex justify-end">
              <button
                onClick={() => {
                  setIsViewModalOpen(false);
                  handleEdit(viewingJob);
                }}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
              >
                Edit This Job
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Job Modal */}
      {isEditModalOpen && editJob && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-2xl max-h-full overflow-hidden">
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-xl font-semibold text-gray-800">Edit Job</h3>
              <button
                onClick={() => setIsEditModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>

            <form onSubmit={handleEditSubmit}>
              <div className="p-6 overflow-y-auto max-h-96">
                <div className="space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="jobMode"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Job Mode <span className="text-red-500">*</span>
                      </label>
                      <select
                        id="jobMode"
                        name="jobMode"
                        value={editJob.jobMode || ""}
                        onChange={handleInputChange}
                        className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                          formErrors.jobMode
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                      >
                        <option value="">Select job mode</option>
                        {jobModeOptions.map((mode, index) => (
                          <option key={index} value={mode}>
                            {mode}
                          </option>
                        ))}
                      </select>
                      {formErrors.jobMode && (
                        <p className="mt-1 text-sm text-red-500">
                          {formErrors.jobMode}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="state"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        State
                      </label>
                      <select
                        id="state"
                        name="state"
                        value={editJob.state || ""}
                        onChange={handleInputChange}
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="">Select state</option>
                        {stateOptions.map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="priority"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Job Priority
                      </label>
                      <select
                        id="priority"
                        name="priority"
                        value={editJob.priority === true ? "high" : "normal"}
                        onChange={(e) => {
                          setEditJob({
                            ...editJob,
                            priority: e.target.value === "high",
                          });
                        }}
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="normal">Normal</option>
                        <option value="high">High</option>
                      </select>
                    </div>

                    <div>
                      <label
                        htmlFor="numberOfOpenings"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Number of Openings{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        id="numberOfOpenings"
                        name="numberOfOpenings"
                        type="number"
                        min="1"
                        value={editJob.numberOfOpenings || 1}
                        onChange={handleInputChange}
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>

                  {/* Tags Input */}
                  <div>
                    <label
                      htmlFor="tags"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Tags (Max 5)
                    </label>
                    <input
                      id="tags"
                      name="tags"
                      value={editJob.tags ? editJob.tags.join(", ") : ""}
                      onChange={(e) => {
                        const tagsArray = e.target.value
                          .split(",")
                          .map((tag) => tag.trim())
                          .filter((tag) => tag !== "")
                          .slice(0, 5);
                        setEditJob({ ...editJob, tags: tagsArray });
                      }}
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter tags separated by comma (max 5)"
                    />
                    <p className="text-xs text-gray-500 mt-1">
                      Separate tags with commas. Maximum 5 tags allowed.
                    </p>
                  </div>

                  <div>
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Job Title <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="title"
                      name="title"
                      value={editJob.title || ""}
                      onChange={handleInputChange}
                      className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                        formErrors.title ? "border-red-500" : "border-gray-300"
                      }`}
                      placeholder="e.g. Senior Frontend Developer"
                    />
                    {formErrors.title && (
                      <p className="mt-1 text-sm text-red-500">
                        {formErrors.title}
                      </p>
                    )}
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="location"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Location <span className="text-red-500">*</span>
                      </label>
                      <input
                        id="location"
                        name="location"
                        value={editJob.location || ""}
                        onChange={handleInputChange}
                        className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                          formErrors.location
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                        placeholder="e.g. New York, USA"
                      />
                      {formErrors.location && (
                        <p className="mt-1 text-sm text-red-500">
                          {formErrors.location}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="experience"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Experience Level <span className="text-red-500">*</span>
                      </label>
                      <select
                        id="experience"
                        name="experience"
                        value={editJob.experience || ""}
                        onChange={handleInputChange}
                        className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                          formErrors.experience
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                      >
                        <option value="">Select experience level</option>
                        {experienceOptions.map((exp, index) => (
                          <option key={index} value={exp}>
                            {exp}
                          </option>
                        ))}
                      </select>
                      {formErrors.experience && (
                        <p className="mt-1 text-sm text-red-500">
                          {formErrors.experience}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="jobType"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Job Type <span className="text-red-500">*</span>
                      </label>
                      <select
                        id="jobType"
                        name="jobType"
                        value={editJob.jobType || ""}
                        onChange={handleInputChange}
                        className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                          formErrors.jobType
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                      >
                        <option value="">Select job type</option>
                        {jobTypeOptions.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                      {formErrors.jobType && (
                        <p className="mt-1 text-sm text-red-500">
                          {formErrors.jobType}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="department"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Department <span className="text-red-500">*</span>
                      </label>
                      <select
                        id="department"
                        name="department"
                        value={editJob.department || ""}
                        onChange={handleInputChange}
                        className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                          formErrors.department
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                      >
                        <option value="">Select department</option>
                        {departmentOptions.map((dept, index) => (
                          <option key={index} value={dept}>
                            {dept}
                          </option>
                        ))}
                      </select>
                      {formErrors.department && (
                        <p className="mt-1 text-sm text-red-500">
                          {formErrors.department}
                        </p>
                      )}
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="skillset"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Required Skills <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="skillset"
                      name="skillset"
                      value={editJob.skillset || ""}
                      onChange={handleInputChange}
                      className={`w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                        formErrors.skillset
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                    >
                      <option value="">Select primary skillset</option>
                      {skillOptions.map((skill, index) => (
                        <option key={index} value={skill}>
                          {skill}
                        </option>
                      ))}
                    </select>
                    {formErrors.skillset && (
                      <p className="mt-1 text-sm text-red-500">
                        {formErrors.skillset}
                      </p>
                    )}
                  </div>

                  {/* Add Description field with Quill Editor */}
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Job Description <span className="text-red-500">*</span>
                    </label>
                    <div
                      className={`border rounded ${
                        formErrors.description
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                    >
                      <Quill
                        theme="snow"
                        value={editJob.description || ""}
                        onChange={handleDescriptionChange}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, false] }],
                            ["bold", "italic", "underline", "strike"],
                            [{ list: "ordered" }, { list: "bullet" }],
                            ["blockquote", "code-block"],
                            [{ align: [] }],
                            ["link"],
                            ["clean"],
                          ],
                        }}
                      />
                    </div>
                    {formErrors.description && (
                      <p className="mt-1 text-sm text-red-500">
                        {formErrors.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="p-4 border-t bg-gray-50 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setIsEditModalOpen(false)}
                  className="px-4 py-2 border border-gray-300 rounded text-gray-700 bg-white hover:bg-gray-50 transition-colors"
                  disabled={isUpdating}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 border border-gray-300 rounded text-gray-700 bg-white hover:bg-gray-50 transition-colors"
                  disabled={isUpdating}
                >
                  {isUpdating ? (
                    <>
                      <span className="animate-spin h-4 w-4 mr-2 border-2 border-white border-t-transparent rounded-full"></span>
                      Updating...
                    </>
                  ) : (
                    "Save Changes"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Confirmation Dialog */}
      {showConfirmDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto">
            <h3 className="text-lg font-semibold text-gray-900 mb-3">
              Confirm Deletion
            </h3>
            <p className="text-gray-600 mb-4">
              Are you sure you want to delete this job? This action cannot be
              undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowConfirmDialog(false)}
                className="px-4 py-2 border border-gray-300 rounded text-gray-700 bg-white hover:bg-gray-50 transition-colors"
                disabled={isUpdating}
              >
                Cancel
              </button>
              <button
                onClick={() => handleDelete(jobToDelete)}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors flex items-center"
                disabled={isUpdating}
              >
                {isUpdating ? (
                  <>
                    <span className="animate-spin h-4 w-4 mr-2 border-2 border-white border-t-transparent rounded-full"></span>
                    Deleting...
                  </>
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* CSS for notifications */}
      <style jsx>{`
        .notification {
          position: fixed;
          top: 1rem;
          right: 1rem;
          padding: 1rem;
          border-radius: 0.5rem;
          color: white;
          max-width: 24rem;
          transform: translateX(120%);
          transition: transform 0.3s ease-out;
          z-index: 1000;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .notification.visible {
          transform: translateX(0);
        }

        .notification.success {
          background-color: #10b981;
        }

        .notification.error {
          background-color: #ef4444;
        }
      `}</style>
    </DashboardLayout>
  );
};

export default ManageJobs;
