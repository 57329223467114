import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container,  Snackbar,  Alert,  CircularProgress,  Grid,  Box,  Paper,  Typography,  Divider,  useMediaQuery,  useTheme,} from "@mui/material";
import Header2 from "../../components/Header2/Header2";
import PageTitle from "../../components/pagetitle/PageTitle";
import JobDetails from "../../components/jobpages/JobDetails";
import ApplyForm from "../../components/jobpages/ApplyForm";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";

const ApplyJob = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "success",
    message: "",
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASEURL}/api/jobs/${jobId}`)
      .then((res) => res.json())
      .then((data) => setJob(data))
      .catch(() =>
        setSnackbar({
          open: true,
          severity: "error",
          message: "Failed to load job details.",
        })
      )
      .finally(() => setLoading(false));
  }, [jobId]);

  return (
    <Fragment>
      <Header2 />
      <PageTitle
        pageTitle="Explore Career Opportunities"
        pagesub={"Careers"}
        pageTop="Join Our"
      />
      
      <Container maxWidth="xl" sx={{ mt: 4, mb: 8 }}>
        {loading ? (
          <Box display="flex" justifyContent="center" my={8}>
            <CircularProgress />
          </Box>
        ) : (
          job && (
            <Paper 
              elevation={3} 
              sx={{ 
                overflow: "hidden",
                borderRadius: 2,
                mb: 4
              }}
            >
              <Grid container>
                {/* Job Details Section - 75% width */}
                <Grid 
                  item 
                  xs={12} 
                  md={7} 
                  sx={{ 
                    backgroundColor: theme.palette.background.default,
                    borderRight: isMobile ? 'none' : `1px solid ${theme.palette.divider}`,
                    borderBottom: isMobile ? `1px solid ${theme.palette.divider}` : 'none',
                  }}
                >
                  <Box 
                    sx={{ 
                      p: 4,
                      height: '100%',
                    }}
                  >
                    <Typography variant="h5" component="h2" gutterBottom sx={{ mb: 3, fontWeight: 600 }}>
                      Job Details 
                    </Typography>
                    <JobDetails
                      job={job}
                      onCopyLink={() =>
                        navigator.clipboard.writeText(window.location.href)
                      }
                    />
                  </Box>
                </Grid>
                
                {/* Application Form Section - 25% width */}
                <Grid item xs={12} md={5}>
                <Box 
                      sx={{ 
                        p: { xs: 3, md: 4 },
                        backgroundColor: theme.palette.background.paper,
                      }}
                    >
                      <Typography 
                        variant="h5" 
                        component="h2" 
                        gutterBottom 
                        sx={{ 
                          pb: 2, 
                          borderBottom: `1px solid ${theme.palette.divider}`,
                          fontWeight: 600 
                        }}
                      >
                        Apply for this Position
                      </Typography>
                      <ApplyForm jobId={jobId} jobTitle={job.title} setSnackbar={setSnackbar} />
                    </Box>
                </Grid>
              </Grid>
            </Paper>
          )
        )}
        
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
      
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ApplyJob;




