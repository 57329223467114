import React from 'react';
import sIcon1 from '../../images/icons/icon_clock.svg'
import sIcon2 from '../../images/icons/icon_dart_board_2.svg'
import sIcon3 from '../../images/icons/icon_target.svg'

const Policy = [
    {
        title: 'Our History',
        subTitle: 'Since 2012, UFS Networks has been a global leader in technical consulting and software solutions, committed to delivering exceptional service excellence and building lasting customer relationships.',
        icon: sIcon1,
    },
    {
        title: 'Our Mission',
        subTitle: 'UFS Networks is a trusted leader in customer experience management, offering innovative solutions and cutting-edge strategies to optimize business performance and maximize client satisfaction.',
        icon: sIcon2,
    },
    {
        title: 'Our Vision',
        subTitle: 'To provide seamless end-to-end services, from unified consulting and strategic implementation to comprehensive managed solutions, driving success for businesses worldwide.',
        icon: sIcon3,
    },


]


const PolicySection = (props) => {

    return (
        <section className="policy_section bg-light">
            <div className="container">
                <div className="row">
                    {Policy.map((policy, pitem) => (
                        <div className="col-lg-4" key={pitem}>
                            <div className="iconbox_block">
                                <div className="iconbox_icon">
                                    <img src={policy.icon} alt="Dollar SVG Icon" />
                                </div>
                                <div className="iconbox_content">
                                    <h3 className="iconbox_title">{policy.title}</h3>
                                    <p className="mb-0">
                                        {policy.subTitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PolicySection;