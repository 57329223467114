// import React, { Fragment, useState, useEffect } from "react";
// import Header2 from "../../components/Header2/Header2";
// import { useNavigate } from "react-router-dom";
// import { Container, Grid, Paper, Typography, Box } from "@mui/material";
// import PageTitle from "../../components/pagetitle/PageTitle";
// import Scrollbar from "../../components/scrollbar/scrollbar";
// import Footer from "../../components/footer/Footer";
// import SearchFilterSection from "./SearchFilterSection";
// import { HelmetProvider } from "react-helmet-async";
// import SEO from "../../components/SEO";
// import JobListingSection from "./JobListingSection";
// import Search from "./Search";

// const CareerPage = () =>

//   {
//   const navigate = useNavigate();
//   const [jobs, setJobs] = useState([]);
//   const [filteredJobs, setFilteredJobs] = useState([]);
//   const [countries, setCountries] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [refreshTrigger, setRefreshTrigger] = useState(0);
//   const jobsPerPage = 6;

//   // Filter states
//   const [search, setSearch] = useState("");
//   const [experience, setExperience] = useState("");
//   const [skillset, setSkillset] = useState("");
//   const [location, setLocation] = useState("");
//   const [jobType, setJobType] = useState("");
//   const [department, setDepartment] = useState("");
//   const [sortOrder, setSortOrder] = useState("");

//   const handleApply = (job) => {
//     navigate(`/apply/${job._id}`, { state: { job } });
//   };

//   // Fetch jobs function
//   const fetchJobs = () => {
//     fetch(`${process.env.REACT_APP_BACKEND_BASEURL}/api/jobs`)
//       .then((res) => res.json())
//       .then((data) => {
//         if (data.success) {
//           setJobs(data.data);
//           applyFilters(data.data);
//         } else {
//           console.error("Failed to fetch jobs:", data.message);
//         }
//       })
//       .catch((err) => {
//         console.error("Error fetching jobs:", err);
//         setJobs([]);
//         setFilteredJobs([]);
//       });
//   };

//   useEffect(() => {
//     fetchJobs();
//     const refreshInterval = setInterval(() => {
//       fetchJobs();
//     }, 60000);
//     return () => clearInterval(refreshInterval);
//   }, [refreshTrigger]);

//   useEffect(() => {
//     fetch("https://restcountries.com/v3.1/all")
//       .then((res) => res.json())
//       .then((data) => {
//         const countryNames = data.map((country) => country.name.common).sort();
//         setCountries(countryNames);
//       })
//       .catch((err) => {
//         console.error("Error fetching countries:", err);
//         setCountries([]);
//       });
//   }, []);

//   const experiences = ["Fresher", "Early Professional", "Mid Level", "Senior", "Super Senior"];
//   const skillsets = ["Java Dev", "Python Dev", "Full Stack Dev", "Frontend Dev", "Backend Dev", "ML Eng", "DevOps", "Cyber Sec", "Customer Service", "Operations", "Marketing", "Sales"];
//   const jobTypeOptions = ["Full Time", "Part Time", "Contract", "Consultant", "Freelancer", "Internship"];
//   const departmentOptions = ["HR", "Accounting Finance", "IT Tech Services", "Software Development", "AI & Automation", "Marketing", "Sales", "Education", "Telecom"];

//   const applyFilters = (jobsToFilter = jobs) => {
//     let filtered = jobsToFilter.filter((job) => {
//       const searchTerms = search.toLowerCase().trim();
//       const searchMatch = !searchTerms
//         ? true
//         : [job.title, job.experience, job.skillset, job.location, job.jobType, job.department].some(
//             (field) => field && field.toString().toLowerCase().includes(searchTerms)
//           );

//       const experienceMatch = !experience || job.experience === experience;
//       const skillsetMatch = !skillset || (job.skillset && job.skillset.includes(skillset));
//       const locationMatch = !location || job.location === location;
//       const jobTypeMatch = !jobType || job.jobType === jobType;
//       const departmentMatch = !department || job.department === department;

//       return searchMatch && experienceMatch && skillsetMatch && locationMatch && jobTypeMatch && departmentMatch;
//     });

//     if (sortOrder === "newest") {
//       filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
//     } else if (sortOrder === "oldest") {
//       filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
//     }

//     setFilteredJobs(filtered);
//   };

//   const clearFilters = () => {
//     setSearch("");
//     setExperience("");
//     setSkillset("");
//     setLocation("");
//     setJobType("");
//     setDepartment("");
//     setSortOrder("");
//     setFilteredJobs(jobs);
//     setCurrentPage(1);
//   };

//   useEffect(() => {
//     applyFilters();
//   }, [search, experience, skillset, location, jobType, department, sortOrder]);

//   const triggerRefresh = () => {
//     setRefreshTrigger((prev) => prev + 1);
//   };

//   const indexOfLastJob = currentPage * jobsPerPage;
//   const indexOfFirstJob = indexOfLastJob - jobsPerPage;
//   const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
//   const totalPages = Math.ceil(filteredJobs.length / jobsPerPage);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   return (
//     <Fragment>
//       <SEO
//         title="Explore Career Opportunities in Software Development & Cybersecurity"
//         description="Join UFS Networks and grow your career in software development, AI, DevOps, and cybersecurity."
//         url="https://www.ufsnetworks.com/career"
//         image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
//       />
//       <Header2 />
//       <PageTitle pageTitle={"Explore Career Opportunities"} pagesub={"Careers"} pageTop={"Join Our"} />

//       <Container maxWidth="xl" sx={{ mt: 4, mb: 8 }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={2} lg={2}>
//             <SearchFilterSection
//               search={search}
//               setSearch={setSearch}
//               experience={experience}
//               setExperience={setExperience}
//               skillset={skillset}
//               setSkillset={setSkillset}
//               location={location}
//               setLocation={setLocation}
//               jobType={jobType}
//               setJobType={setJobType}
//               department={department}
//               setDepartment={setDepartment}
//               sortOrder={sortOrder}
//               setSortOrder={setSortOrder}
//               applyFilters={() => applyFilters()}
//               clearFilters={clearFilters}
//               experiences={experiences}
//               skillsets={skillsets}
//               countries={countries}
//               jobTypeOptions={jobTypeOptions}
//               departmentOptions={departmentOptions}
//             />
//           </Grid>

//           <Grid item xs={12} md={8} lg={8}>
//          <Search onSearchResults={setFilteredJobs}/>

//             <JobListingSection

//               jobs={currentJobs}
//               handleApply={handleApply}
//               currentPage={currentPage}
//               totalPages={totalPages}
//               handlePageChange={handlePageChange}
//               onRefreshNeeded={triggerRefresh}
//             />
//           </Grid>

//           {/* ✅ New Image Section - Mobile me hide, Laptop pe show */}
//           <Grid item xs={12} md={2} lg={2} sx={{ display: { xs: "none", md: "block" } }}>
//             <Box component="img" src={require("../../images/ad1.png")} alt="Advertisement" sx={{ width: "100%", height: "auto", objectFit: "contain" }} />
//           </Grid>
//         </Grid>
//       </Container>

//       <Footer />
//       <Scrollbar />
//     </Fragment>
//   );
// };

// export default CareerPage;

/////////////////////total jobs////////////////////////////

// import React, { Fragment, useState, useEffect } from "react";
// import Header2 from "../../components/Header2/Header2";
// import { useNavigate } from "react-router-dom";
// import { Container, Grid, Paper, Typography, Box } from "@mui/material";
// import PageTitle from "../../components/pagetitle/PageTitle";
// import Scrollbar from "../../components/scrollbar/scrollbar";
// import Footer from "../../components/footer/Footer";
// import SearchFilterSection from "./SearchFilterSection";
// import { HelmetProvider } from "react-helmet-async";
// import SEO from "../../components/SEO";
// import JobListingSection from "./JobListingSection";
// import Search from "./Search";

// const CareerPage = () => {
//   const navigate = useNavigate();
//   const [jobs, setJobs] = useState([]);
//   const [filteredJobs, setFilteredJobs] = useState([]);
//   const [countries, setCountries] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [refreshTrigger, setRefreshTrigger] = useState(0);
//   const jobsPerPage = 25; // Updated to 25 jobs per page

//   // Filter states
//   const [search, setSearch] = useState("");
//   const [experience, setExperience] = useState("");
//   const [skillset, setSkillset] = useState("");
//   const [location, setLocation] = useState("");
//   const [jobType, setJobType] = useState("");
//   const [department, setDepartment] = useState("");
//   const [sortOrder, setSortOrder] = useState("");

//   const handleApply = (job) => {
//     navigate(`/apply/${job._id}`, { state: { job } });
//   };

//   // Fetch jobs function
//   const fetchJobs = () => {
//     fetch(`${process.env.REACT_APP_BACKEND_BASEURL}/api/jobs`)
//       .then((res) => res.json())
//       .then((data) => {
//         if (data.success) {
//           setJobs(data.data);
//           applyFilters(data.data);
//         } else {
//           console.error("Failed to fetch jobs:", data.message);
//         }
//       })
//       .catch((err) => {
//         console.error("Error fetching jobs:", err);
//         setJobs([]);
//         setFilteredJobs([]);
//       });
//   };

//   useEffect(() => {
//     fetchJobs();
//     const refreshInterval = setInterval(() => {
//       fetchJobs();
//     }, 60000);
//     return () => clearInterval(refreshInterval);
//   }, [refreshTrigger]);

//   useEffect(() => {
//     fetch("https://restcountries.com/v3.1/all")
//       .then((res) => res.json())
//       .then((data) => {
//         const countryNames = data.map((country) => country.name.common).sort();
//         setCountries(countryNames);
//       })
//       .catch((err) => {
//         console.error("Error fetching countries:", err);
//         setCountries([]);
//       });
//   }, []);

//   const experiences = ["Fresher", "Early Professional", "Mid Level", "Senior", "Super Senior"];
//   const skillsets = ["Java Dev", "Python Dev", "Full Stack Dev", "Frontend Dev", "Backend Dev", "ML Eng", "DevOps", "Cyber Sec", "Customer Service", "Operations", "Marketing", "Sales"];
//   const jobTypeOptions = ["Full Time", "Part Time", "Contract", "Consultant", "Freelancer", "Internship"];
//   const departmentOptions = ["HR", "Accounting Finance", "IT Tech Services", "Software Development", "AI & Automation", "Marketing", "Sales", "Education", "Telecom"];

//   const applyFilters = (jobsToFilter = jobs) => {
//     let filtered = jobsToFilter.filter((job) => {
//       const searchTerms = search.toLowerCase().trim();
//       const searchMatch = !searchTerms
//         ? true
//         : [job.title, job.experience, job.skillset, job.location, job.jobType, job.department].some(
//             (field) => field && field.toString().toLowerCase().includes(searchTerms)
//           );

//       const experienceMatch = !experience || job.experience === experience;
//       const skillsetMatch = !skillset || (job.skillset && job.skillset.includes(skillset));
//       const locationMatch = !location || job.location === location;
//       const jobTypeMatch = !jobType || job.jobType === jobType;
//       const departmentMatch = !department || job.department === department;

//       return searchMatch && experienceMatch && skillsetMatch && locationMatch && jobTypeMatch && departmentMatch;
//     });

//     if (sortOrder === "newest") {
//       filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
//     } else if (sortOrder === "oldest") {
//       filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
//     }

//     setFilteredJobs(filtered);
//   };

//   const clearFilters = () => {
//     setSearch("");
//     setExperience("");
//     setSkillset("");
//     setLocation("");
//     setJobType("");
//     setDepartment("");
//     setSortOrder("");
//     setFilteredJobs(jobs);
//     setCurrentPage(1);
//   };

//   useEffect(() => {
//     applyFilters();
//   }, [search, experience, skillset, location, jobType, department, sortOrder]);

//   const triggerRefresh = () => {
//     setRefreshTrigger((prev) => prev + 1);
//   };

//   // Pagination logic
//   const indexOfLastJob = currentPage * jobsPerPage;
//   const indexOfFirstJob = indexOfLastJob - jobsPerPage;
//   const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
//   const totalJobs = filteredJobs.length; // Get the total number of jobs
//   const totalPages = Math.ceil(totalJobs / jobsPerPage);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   return (
//     <Fragment>
//       <SEO
//         title="Explore Career Opportunities in Software Development & Cybersecurity"
//         description="Join UFS Networks and grow your career in software development, AI, DevOps, and cybersecurity."
//         url="https://www.ufsnetworks.com/career"
//         image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
//       />
//       <Header2 />
//       <PageTitle pageTitle={"Explore Career Opportunities"} pagesub={"Careers"} pageTop={"Join Our"} />

//       <Container maxWidth="xl" sx={{ mt: 4, mb: 8 }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={2} lg={2}>
//             <SearchFilterSection
//               search={search}
//               setSearch={setSearch}
//               experience={experience}
//               setExperience={setExperience}
//               skillset={skillset}
//               setSkillset={setSkillset}
//               location={location}
//               setLocation={setLocation}
//               jobType={jobType}
//               setJobType={setJobType}
//               department={department}
//               setDepartment={setDepartment}
//               sortOrder={sortOrder}
//               setSortOrder={setSortOrder}
//               applyFilters={() => applyFilters()}
//               clearFilters={clearFilters}
//               experiences={experiences}
//               skillsets={skillsets}
//               countries={countries}
//               jobTypeOptions={jobTypeOptions}
//               departmentOptions={departmentOptions}
//             />
//           </Grid>

//           <Grid item xs={12} md={8} lg={8}>
//             <Search onSearchResults={setFilteredJobs} />

//             {/* Display Total Jobs Available */}
//             <Typography variant="h6" sx={{ mb: 2 }}>
//               Total Job Openings: {totalJobs}
//             </Typography>

//             <JobListingSection
//               jobs={currentJobs}
//               handleApply={handleApply}
//               currentPage={currentPage}
//               totalPages={totalPages}
//               handlePageChange={handlePageChange}
//               onRefreshNeeded={triggerRefresh}
//             />
//           </Grid>

//           {/* ✅ New Image Section - Mobile me hide, Laptop pe show */}
//           {/* <Grid item xs={12} md={2} lg={2} sx={{ display: { xs: "none", md: "block" } }}>
//             <Box component="img" src={require("../../images/ad1.png")} alt="Advertisement" sx={{ width: "100%", height: "auto", objectFit: "contain" }} />
//           </Grid> */}
//         </Grid>
//       </Container>

//       <Footer />
//       <Scrollbar />
//     </Fragment>
//   );
// };

// export default CareerPage;

///////////////////////////////////////////////////////////////////////////

// import React, { Fragment, useState, useEffect } from "react";
// import Header2 from "../../components/Header2/Header2";
// import { useNavigate } from "react-router-dom";
// import { Container, Grid, Typography, Box } from "@mui/material";
// import PageTitle from "../../components/pagetitle/PageTitle";
// import Scrollbar from "../../components/scrollbar/scrollbar";
// import Footer from "../../components/footer/Footer";
// import SearchFilterSection from "./SearchFilterSection";
// import { HelmetProvider } from "react-helmet-async";
// import SEO from "../../components/SEO";
// import JobListingSection from "./JobListingSection";
// import Search from "./Search";
// import { Link } from "react-router-dom"; // Import Link for navigation

// const CareerPage = () => {
//   const navigate = useNavigate();
//   const [jobs, setJobs] = useState([]);
//   const [filteredJobs, setFilteredJobs] = useState([]);
//   const [countries, setCountries] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [refreshTrigger, setRefreshTrigger] = useState(0);
//   const jobsPerPage = 25; // Updated to 25 jobs per page

//   // Filter states
//   const [search, setSearch] = useState("");
//   const [experience, setExperience] = useState("");
//   const [skillset, setSkillset] = useState("");
//   const [location, setLocation] = useState("");
//   const [jobType, setJobType] = useState("");
//   const [department, setDepartment] = useState("");
//   const [sortOrder, setSortOrder] = useState("");

//   const handleApply = (job) => {
//     navigate(`/apply/${job._id}`, { state: { job } });
//   };

//   // Fetch jobs function
//   const fetchJobs = () => {
//     fetch(`${process.env.REACT_APP_BACKEND_BASEURL}/api/jobs`)
//       .then((res) => res.json())
//       .then((data) => {
//         if (data.success) {
//           setJobs(data.data);
//           applyFilters(data.data);
//         } else {
//           console.error("Failed to fetch jobs:", data.message);
//         }
//       })
//       .catch((err) => {
//         console.error("Error fetching jobs:", err);
//         setJobs([]);
//         setFilteredJobs([]);
//       });
//   };

//   useEffect(() => {
//     fetchJobs();
//     const refreshInterval = setInterval(() => {
//       fetchJobs();
//     }, 60000);
//     return () => clearInterval(refreshInterval);
//   }, [refreshTrigger]);

//   useEffect(() => {
//     fetch("https://restcountries.com/v3.1/all")
//       .then((res) => res.json())
//       .then((data) => {
//         const countryNames = data.map((country) => country.name.common).sort();
//         setCountries(countryNames);
//       })
//       .catch((err) => {
//         console.error("Error fetching countries:", err);
//         setCountries([]);
//       });
//   }, []);

//   const experiences = ["Fresher", "Early Professional", "Mid Level", "Senior", "Super Senior"];
//   const skillsets = ["Java Dev", "Python Dev", "Full Stack Dev", "Frontend Dev", "Backend Dev", "ML Eng", "DevOps", "Cyber Sec", "Customer Service", "Operations", "Marketing", "Sales"];
//   const jobTypeOptions = ["Full Time", "Part Time", "Contract", "Consultant", "Freelancer", "Internship"];
//   const departmentOptions = ["HR", "Accounting Finance", "IT Tech Services", "Software Development", "AI & Automation", "Marketing", "Sales", "Education", "Telecom"];

//   const applyFilters = (jobsToFilter = jobs) => {
//     let filtered = jobsToFilter.filter((job) => {
//       const searchTerms = search.toLowerCase().trim();
//       const searchMatch = !searchTerms
//         ? true
//         : [job.title, job.experience, job.skillset, job.location, job.jobType, job.department].some(
//             (field) => field && field.toString().toLowerCase().includes(searchTerms)
//           );

//       const experienceMatch = !experience || job.experience === experience;
//       const skillsetMatch = !skillset || (job.skillset && job.skillset.includes(skillset));
//       const locationMatch = !location || job.location === location;
//       const jobTypeMatch = !jobType || job.jobType === jobType;
//       const departmentMatch = !department || job.department === department;

//       return searchMatch && experienceMatch && skillsetMatch && locationMatch && jobTypeMatch && departmentMatch;
//     });

//     if (sortOrder === "newest") {
//       filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
//     } else if (sortOrder === "oldest") {
//       filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
//     }

//     setFilteredJobs(filtered);
//   };

//   const clearFilters = () => {
//     setSearch("");
//     setExperience("");
//     setSkillset("");
//     setLocation("");
//     setJobType("");
//     setDepartment("");
//     setSortOrder("");
//     setFilteredJobs(jobs);
//     setCurrentPage(1);
//   };

//   useEffect(() => {
//     applyFilters();
//   }, [search, experience, skillset, location, jobType, department, sortOrder]);

//   const triggerRefresh = () => {
//     setRefreshTrigger((prev) => prev + 1);
//   };

//   // Pagination logic
//   const indexOfLastJob = currentPage * jobsPerPage;
//   const indexOfFirstJob = indexOfLastJob - jobsPerPage;
//   const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
//   const totalJobs = filteredJobs.length; // Get the total number of jobs
//   const totalPages = Math.ceil(totalJobs / jobsPerPage);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   return (
//     <Fragment>
//       <SEO
//         title="Explore Career Opportunities in Software Development & Cybersecurity"
//         description="Join UFS Networks and grow your career in software development, AI, DevOps, and cybersecurity."
//         url="https://www.ufsnetworks.com/career"
//         image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
//       />
//       <Header2 />
//       <PageTitle pageTitle={"Explore Career Opportunities"} pagesub={"Careers"} pageTop={"Join Our"} />

//       <Container maxWidth="xl" sx={{ mt: 4, mb: 8 }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={2} lg={2}>
//             <SearchFilterSection
//               search={search}
//               setSearch={setSearch}
//               experience={experience}
//               setExperience={setExperience}
//               skillset={skillset}
//               setSkillset={setSkillset}
//               location={location}
//               setLocation={setLocation}
//               jobType={jobType}
//               setJobType={setJobType}
//               department={department}
//               setDepartment={setDepartment}
//               sortOrder={sortOrder}
//               setSortOrder={setSortOrder}
//               applyFilters={() => applyFilters()}
//               clearFilters={clearFilters}
//               experiences={experiences}
//               skillsets={skillsets}
//               countries={countries}
//               jobTypeOptions={jobTypeOptions}
//               departmentOptions={departmentOptions}
//             />
//           </Grid>

//           <Grid item xs={12} md={8} lg={8}>
//             <Search onSearchResults={setFilteredJobs} />

//             {/* Display Total Jobs Available */}
//             <Typography variant="h6" sx={{ mb: 2 }}>
//               Total Job Openings: {totalJobs}
//             </Typography>

//             <JobListingSection
//               jobs={currentJobs}
//               handleApply={handleApply}
//               currentPage={currentPage}
//               totalPages={totalPages}
//               handlePageChange={handlePageChange}
//               onRefreshNeeded={triggerRefresh}
//             />
//           </Grid>

//           {/* Latest Jobs Section - Mobile me hide, Laptop pe show */}
//           <Grid item xs={12} md={2} lg={2} sx={{ display: { xs: "none", md: "block" } }}>
//             <Typography variant="h6" sx={{ mb: 2 }}>
//               Latest Jobs
//             </Typography>
//             <Box lg={{ overflowY: "auto", maxHeight: "300px" }}>
//               {filteredJobs.map((job) => (
//                 <Box key={job._id}>
//                   <Link
//                     to={`/apply/${job._id}`}
//                     style={{
//                       display: "block",
//                       margin: "5px 0",
//                       textDecoration: "none",
//                       color: "blue",
//                     }}
//                   >
//                     {job.title}
//                   </Link>
//                 </Box>
//               ))}
//             </Box>
//           </Grid>

//         </Grid>
//       </Container>

//       <Footer />
//       <Scrollbar />
//     </Fragment>
//   );
// };

// export default CareerPage;

//////////////////////////////// letest job cloud but less//////////////////////////////////////////

// import React, { Fragment, useState, useEffect } from "react";
// import Header2 from "../../components/Header2/Header2";
// import { useNavigate } from "react-router-dom";
// import {
//   Container,
//   Grid,
//   Typography,
//   Box,
//   Paper,
//   Link as MUILink,
//   Divider,
//   useMediaQuery,
//   useTheme
// } from "@mui/material";
// import PageTitle from "../../components/pagetitle/PageTitle";
// import Scrollbar from "../../components/scrollbar/scrollbar";
// import Footer from "../../components/footer/Footer";
// import SearchFilterSection from "./SearchFilterSection";
// import { HelmetProvider } from "react-helmet-async";
// import SEO from "../../components/SEO";
// import JobListingSection from "./JobListingSection";
// import Search from "./Search";
// import { Link } from "react-router-dom";
// import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

// const LatestJobsSidebar = ({ filteredJobs }) => {
//   const theme = useTheme();
//   const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

//   // If no desktop view or no jobs, return null
//   if (!isDesktop || filteredJobs.length === 0) {
//     return null;
//   }

//   return (
//     <Grid
//       item
//       xs={12}
//       md={2}
//       lg={2}
//       sx={{
//         display: { xs: 'none', md: 'block' },
//         position: 'sticky',
//         top: '16px',
//       }}
//     >
//       <Paper
//         elevation={3}
//         sx={{
//           p: 2,
//           borderRadius: 2,
//           backgroundColor: 'background.paper',
//         }}
//       >
//         <Typography
//           variant="h6"
//           color="primary"
//           sx={{
//             mb: 2,
//             display: 'flex',
//             alignItems: 'center',
//             fontWeight: 600,
//             borderBottom: '2px solid',
//             borderColor: 'primary.main',
//             pb: 1,
//           }}
//         >
//           <WorkOutlineIcon sx={{ mr: 1 }} />
//           Latest Jobs
//         </Typography>

//         <Box
//           sx={{
//             overflowY: 'auto',
//             maxHeight: '400px',
//             pr: 1,
//             '&::-webkit-scrollbar': {
//               width: '6px',
//             },
//             '&::-webkit-scrollbar-thumb': {
//               backgroundColor: 'primary.light',
//               borderRadius: '3px',
//             },
//           }}
//         >
//           {filteredJobs.slice(0, 10).map((job) => (
//             <React.Fragment key={job._id}>
//               <MUILink
//                 component={Link}
//                 to={`/apply/${job._id}`}
//                 sx={{
//                   display: 'block',
//                   p: 1,
//                   borderRadius: 1,
//                   transition: 'all 0.3s ease',
//                   '&:hover': {
//                     backgroundColor: 'action.hover',
//                     transform: 'translateX(5px)',
//                     textDecoration: 'none',
//                   },
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.primary"
//                   sx={{
//                     fontWeight: 500,
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                     whiteSpace: 'nowrap',
//                   }}
//                 >
//                   {job.title}
//                 </Typography>
//                 <Typography
//                   variant="caption"
//                   color="text.secondary"
//                   sx={{
//                     display: 'block',
//                     fontSize: '0.7rem',
//                   }}
//                 >
//                   {job.department}
//                 </Typography>
//               </MUILink>
//               <Divider light />
//             </React.Fragment>
//           ))}
//         </Box>

//         {filteredJobs.length > 10 && (
//           <Typography
//             variant="caption"
//             color="text.secondary"
//             sx={{
//               display: 'block',
//               textAlign: 'center',
//               mt: 1,
//               fontStyle: 'italic'
//             }}
//           >
//             {filteredJobs.length - 10} more jobs available
//           </Typography>
//         )}
//       </Paper>
//     </Grid>
//   );
// };

// const CareerPage = () => {
//   const navigate = useNavigate();
//   const [jobs, setJobs] = useState([]);
//   const [filteredJobs, setFilteredJobs] = useState([]);
//   const [countries, setCountries] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [refreshTrigger, setRefreshTrigger] = useState(0);
//   const jobsPerPage = 25; // Updated to 25 jobs per page

//   // Filter states
//   const [search, setSearch] = useState("");
//   const [experience, setExperience] = useState("");
//   const [skillset, setSkillset] = useState("");
//   const [location, setLocation] = useState("");
//   const [jobType, setJobType] = useState("");
//   const [department, setDepartment] = useState("");
//   const [sortOrder, setSortOrder] = useState("");

//   const handleApply = (job) => {
//     navigate(`/apply/${job._id}`, { state: { job } });
//   };

//   // Fetch jobs function
//   const fetchJobs = () => {
//     fetch(`${process.env.REACT_APP_BACKEND_BASEURL}/api/jobs`)
//       .then((res) => res.json())
//       .then((data) => {
//         if (data.success) {
//           setJobs(data.data);
//           applyFilters(data.data);
//         } else {
//           console.error("Failed to fetch jobs:", data.message);
//         }
//       })
//       .catch((err) => {
//         console.error("Error fetching jobs:", err);
//         setJobs([]);
//         setFilteredJobs([]);
//       });
//   };

//   useEffect(() => {
//     fetchJobs();
//     const refreshInterval = setInterval(() => {
//       fetchJobs();
//     }, 60000);
//     return () => clearInterval(refreshInterval);
//   }, [refreshTrigger]);

//   useEffect(() => {
//     fetch("https://restcountries.com/v3.1/all")
//       .then((res) => res.json())
//       .then((data) => {
//         const countryNames = data.map((country) => country.name.common).sort();
//         setCountries(countryNames);
//       })
//       .catch((err) => {
//         console.error("Error fetching countries:", err);
//         setCountries([]);
//       });
//   }, []);

//   const experiences = ["Fresher", "Early Professional", "Mid Level", "Senior", "Super Senior"];
//   const skillsets = ["Java Dev", "Python Dev", "Full Stack Dev", "Frontend Dev", "Backend Dev", "ML Eng", "DevOps", "Cyber Sec", "Customer Service", "Operations", "Marketing", "Sales"];
//   const jobTypeOptions = ["Full Time", "Part Time", "Contract", "Consultant", "Freelancer", "Internship"];
//   const departmentOptions = ["HR", "Accounting Finance", "IT Tech Services", "Software Development", "AI & Automation", "Marketing", "Sales", "Education", "Telecom"];

//   const applyFilters = (jobsToFilter = jobs) => {
//     let filtered = jobsToFilter.filter((job) => {
//       const searchTerms = search.toLowerCase().trim();
//       const searchMatch = !searchTerms
//         ? true
//         : [job.title, job.experience, job.skillset, job.location, job.jobType, job.department].some(
//             (field) => field && field.toString().toLowerCase().includes(searchTerms)
//           );

//       const experienceMatch = !experience || job.experience === experience;
//       const skillsetMatch = !skillset || (job.skillset && job.skillset.includes(skillset));
//       const locationMatch = !location || job.location === location;
//       const jobTypeMatch = !jobType || job.jobType === jobType;
//       const departmentMatch = !department || job.department === department;

//       return searchMatch && experienceMatch && skillsetMatch && locationMatch && jobTypeMatch && departmentMatch;
//     });

//     if (sortOrder === "newest") {
//       filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
//     } else if (sortOrder === "oldest") {
//       filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
//     }

//     setFilteredJobs(filtered);
//   };

//   const clearFilters = () => {
//     setSearch("");
//     setExperience("");
//     setSkillset("");
//     setLocation("");
//     setJobType("");
//     setDepartment("");
//     setSortOrder("");
//     setFilteredJobs(jobs);
//     setCurrentPage(1);
//   };

//   useEffect(() => {
//     applyFilters();
//   }, [search, experience, skillset, location, jobType, department, sortOrder]);

//   const triggerRefresh = () => {
//     setRefreshTrigger((prev) => prev + 1);
//   };

//   // Pagination logic
//   const indexOfLastJob = currentPage * jobsPerPage;
//   const indexOfFirstJob = indexOfLastJob - jobsPerPage;
//   const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
//   const totalJobs = filteredJobs.length; // Get the total number of jobs
//   const totalPages = Math.ceil(totalJobs / jobsPerPage);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   return (
//     <Fragment>
//       <SEO
//         title="Explore Career Opportunities in Software Development & Cybersecurity"
//         description="Join UFS Networks and grow your career in software development, AI, DevOps, and cybersecurity."
//         url="https://www.ufsnetworks.com/career"
//         image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
//       />
//       <Header2 />
//       <PageTitle pageTitle={"Explore Career Opportunities"} pagesub={"Careers"} pageTop={"Join Our"} />

//       <Container maxWidth="xl" sx={{ mt: 4, mb: 8 }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={2} lg={2}>
//             <SearchFilterSection
//               search={search}
//               setSearch={setSearch}
//               experience={experience}
//               setExperience={setExperience}
//               skillset={skillset}
//               setSkillset={setSkillset}
//               location={location}
//               setLocation={setLocation}
//               jobType={jobType}
//               setJobType={setJobType}
//               department={department}
//               setDepartment={setDepartment}
//               sortOrder={sortOrder}
//               setSortOrder={setSortOrder}
//               applyFilters={() => applyFilters()}
//               clearFilters={clearFilters}
//               experiences={experiences}
//               skillsets={skillsets}
//               countries={countries}
//               jobTypeOptions={jobTypeOptions}
//               departmentOptions={departmentOptions}
//             />
//           </Grid>

//           <Grid item xs={12} md={8} lg={8}>
//             <Search onSearchResults={setFilteredJobs} />

//             {/* Display Total Jobs Available */}
//             <Typography variant="h6" sx={{ mb: 2 }}>
//               Total Job Openings: {totalJobs}
//             </Typography>

//             <JobListingSection
//               jobs={currentJobs}
//               handleApply={handleApply}
//               currentPage={currentPage}
//               totalPages={totalPages}
//               handlePageChange={handlePageChange}
//               onRefreshNeeded={triggerRefresh}
//             />
//           </Grid>

//           {/* Latest Jobs Sidebar */}
//           <LatestJobsSidebar filteredJobs={filteredJobs} />
//         </Grid>
//       </Container>

//       <Footer />
//       <Scrollbar />
//     </Fragment>
//   );
// };

// export default CareerPage;

//////////////////////////latest job cloud all////////////////////

// import React, { Fragment, useState, useEffect } from "react";
// import Header2 from "../../components/Header2/Header2";
// import { useNavigate } from "react-router-dom";
// import {
//   Container,
//   Grid,
//   Typography,
//   Box,
//   Paper,
//   Link as MUILink,
//   Divider,
//   useMediaQuery,
//   useTheme
// } from "@mui/material";
// import PageTitle from "../../components/pagetitle/PageTitle";
// import Scrollbar from "../../components/scrollbar/scrollbar";
// import Footer from "../../components/footer/Footer";
// import SearchFilterSection from "./SearchFilterSection";
// import SEO from "../../components/SEO";
// import JobListingSection from "./JobListingSection";
// import Search from "./Search";
// import { Link } from "react-router-dom";
// import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

// const LatestJobsSidebar = ({ filteredJobs }) => {
//   const theme = useTheme();
//   const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

//   // If no desktop view or no jobs, return null
//   if (!isDesktop || filteredJobs.length === 0) {
//     return null;
//   }

//   return (
//     <Grid
//       item
//       xs={12}
//       md={2}
//       lg={2}
//       sx={{
//         display: { xs: 'none', md: 'block' },
//         position: 'sticky',
//         top: '16px',
//       }}
//     >
//       <Paper
//         elevation={3}
//         sx={{
//           p: 2,
//           borderRadius: 2,
//           backgroundColor: 'background.paper',
//         }}
//       >
//         <Typography
//           variant="h6"
//           color="primary"
//           sx={{
//             mb: 2,
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'space-between',
//             fontWeight: 600,
//             borderBottom: '2px solid',
//             borderColor: 'primary.main',
//             pb: 1,
//           }}
//         >
//           <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <WorkOutlineIcon sx={{ mr: 1 }} />
//             Latest Jobs
//           </Box>
//           <Typography
//             variant="caption"
//             color="text.secondary"
//             sx={{
//               ml: 2,
//               fontSize: '0.7rem',
//               fontWeight: 400,
//             }}
//           >
//             ({filteredJobs.length} Total)
//           </Typography>
//         </Typography>

//         <Box
//           sx={{
//             overflowY: 'auto',
//             maxHeight: '400px',
//             pr: 1,
//             '&::-webkit-scrollbar': {
//               width: '6px',
//             },
//             '&::-webkit-scrollbar-thumb': {
//               backgroundColor: 'primary.light',
//               borderRadius: '3px',
//             },
//           }}
//         >
//           {filteredJobs.slice(0, 20).map((job) => (
//             <React.Fragment key={job._id}>
//               <MUILink
//                 component={Link}
//                 to={`/apply/${job._id}`}
//                 sx={{
//                   display: 'block',
//                   p: 1,
//                   borderRadius: 1,
//                   transition: 'all 0.3s ease',
//                   '&:hover': {
//                     backgroundColor: 'action.hover',
//                     transform: 'translateX(5px)',
//                     textDecoration: 'none',
//                   },
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.primary"
//                   sx={{
//                     fontWeight: 500,
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                     whiteSpace: 'nowrap',
//                   }}
//                 >
//                   {job.title}
//                 </Typography>
//                 <Typography
//                   variant="caption"
//                   color="text.secondary"
//                   sx={{
//                     display: 'block',
//                     fontSize: '0.7rem',
//                   }}
//                 >
//                   {job.department}
//                 </Typography>
//               </MUILink>
//               <Divider light />
//             </React.Fragment>
//           ))}
//         </Box>

//         {filteredJobs.length > 10 && (
//           <Typography
//             variant="caption"
//             color="text.secondary"
//             sx={{
//               display: 'block',
//               textAlign: 'center',
//               mt: 1,
//               fontStyle: 'italic'
//             }}
//           >
//             {filteredJobs.length - 20} more jobs available
//           </Typography>
//         )}
//       </Paper>
//     </Grid>
//   );
// };

// const CareerPage = () => {
//   const navigate = useNavigate();
//   const [jobs, setJobs] = useState([]);
//   const [filteredJobs, setFilteredJobs] = useState([]);
//   const [countries, setCountries] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [refreshTrigger, setRefreshTrigger] = useState(0);
//   const jobsPerPage = 25; // Updated to 25 jobs per page

//   // Filter states
//   const [search, setSearch] = useState("");
//   const [experience, setExperience] = useState("");
//   const [skillset, setSkillset] = useState("");
//   const [location, setLocation] = useState("");
//   const [jobType, setJobType] = useState("");
//   const [department, setDepartment] = useState("");
//   const [sortOrder, setSortOrder] = useState("");

//   const handleApply = (job) => {
//     navigate(`/apply/${job._id}`, { state: { job } });
//   };

//   // Fetch jobs function
//   const fetchJobs = () => {
//     fetch(`${process.env.REACT_APP_BACKEND_BASEURL}/api/jobs`)
//       .then((res) => res.json())
//       .then((data) => {
//         if (data.success) {
//           setJobs(data.data);
//           applyFilters(data.data);
//         } else {
//           console.error("Failed to fetch jobs:", data.message);
//         }
//       })
//       .catch((err) => {
//         console.error("Error fetching jobs:", err);
//         setJobs([]);
//         setFilteredJobs([]);
//       });
//   };

//   useEffect(() => {
//     fetchJobs();
//     const refreshInterval = setInterval(() => {
//       fetchJobs();
//     }, 60000);
//     return () => clearInterval(refreshInterval);
//   }, [refreshTrigger]);

//   useEffect(() => {
//     fetch("https://restcountries.com/v3.1/all")
//       .then((res) => res.json())
//       .then((data) => {
//         const countryNames = data.map((country) => country.name.common).sort();
//         setCountries(countryNames);
//       })
//       .catch((err) => {
//         console.error("Error fetching countries:", err);
//         setCountries([]);
//       });
//   }, []);

//   const experiences = ["Fresher", "Early Professional", "Mid Level", "Senior", "Super Senior"];
//   const skillsets = ["Java Dev", "Python Dev", "Full Stack Dev", "Frontend Dev", "Backend Dev", "ML Eng", "DevOps", "Cyber Sec", "Customer Service", "Operations", "Marketing", "Sales"];
//   const jobTypeOptions = ["Full Time", "Part Time", "Contract", "Consultant", "Freelancer", "Internship"];
//   const departmentOptions = ["HR", "Accounting Finance", "IT Tech Services", "Software Development", "AI & Automation", "Marketing", "Sales", "Education", "Telecom"];

//   const applyFilters = (jobsToFilter = jobs) => {
//     let filtered = jobsToFilter.filter((job) => {
//       const searchTerms = search.toLowerCase().trim();
//       const searchMatch = !searchTerms
//         ? true
//         : [job.title, job.experience, job.skillset, job.location, job.jobType, job.department].some(
//             (field) => field && field.toString().toLowerCase().includes(searchTerms)
//           );

//       const experienceMatch = !experience || job.experience === experience;
//       const skillsetMatch = !skillset || (job.skillset && job.skillset.includes(skillset));
//       const locationMatch = !location || job.location === location;
//       const jobTypeMatch = !jobType || job.jobType === jobType;
//       const departmentMatch = !department || job.department === department;

//       return searchMatch && experienceMatch && skillsetMatch && locationMatch && jobTypeMatch && departmentMatch;
//     });

//     if (sortOrder === "newest") {
//       filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
//     } else if (sortOrder === "oldest") {
//       filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
//     }

//     setFilteredJobs(filtered);
//   };

//   const clearFilters = () => {
//     setSearch("");
//     setExperience("");
//     setSkillset("");
//     setLocation("");
//     setJobType("");
//     setDepartment("");
//     setSortOrder("");
//     setFilteredJobs(jobs);
//     setCurrentPage(1);
//   };

//   useEffect(() => {
//     applyFilters();
//   }, [search, experience, skillset, location, jobType, department, sortOrder]);

//   const triggerRefresh = () => {
//     setRefreshTrigger((prev) => prev + 1);
//   };

//   // Pagination logic
//   const indexOfLastJob = currentPage * jobsPerPage;
//   const indexOfFirstJob = indexOfLastJob - jobsPerPage;
//   const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
//   const totalJobs = filteredJobs.length; // Get the total number of jobs
//   const totalPages = Math.ceil(totalJobs / jobsPerPage);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   return (
//     <Fragment>
//       <SEO
//         title="Explore Career Opportunities in Software Development & Cybersecurity"
//         description="Join UFS Networks and grow your career in software development, AI, DevOps, and cybersecurity."
//         url="https://www.ufsnetworks.com/career"
//         image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
//       />
//       <Header2 />
//       <PageTitle pageTitle={"Explore Career Opportunities"} pagesub={"Careers"} pageTop={"Join Our"} />

//       <Container maxWidth="xl" sx={{ mt: 4, mb: 8 }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={2} lg={2}>
//             <SearchFilterSection
//               search={search}
//               setSearch={setSearch}
//               experience={experience}
//               setExperience={setExperience}
//               skillset={skillset}
//               setSkillset={setSkillset}
//               location={location}
//               setLocation={setLocation}
//               jobType={jobType}
//               setJobType={setJobType}
//               department={department}
//               setDepartment={setDepartment}
//               sortOrder={sortOrder}
//               setSortOrder={setSortOrder}
//               applyFilters={() => applyFilters()}
//               clearFilters={clearFilters}
//               experiences={experiences}
//               skillsets={skillsets}
//               countries={countries}
//               jobTypeOptions={jobTypeOptions}
//               departmentOptions={departmentOptions}
//             />
//           </Grid>

//           <Grid item xs={12} md={8} lg={8}>
//             <Search onSearchResults={setFilteredJobs} />

//             {/* Display Total Jobs Available */}
//             <Typography variant="h6" sx={{ mb: 2 }}>
//               Total Job Openings: {totalJobs}
//             </Typography>

//             <JobListingSection
//               jobs={currentJobs}
//               handleApply={handleApply}
//               currentPage={currentPage}
//               totalPages={totalPages}
//               handlePageChange={handlePageChange}
//               onRefreshNeeded={triggerRefresh}
//             />
//           </Grid>

//           {/* Latest Jobs Sidebar */}
//           <LatestJobsSidebar filteredJobs={filteredJobs} />
//         </Grid>
//       </Container>

//       <Footer />
//       <Scrollbar />
//     </Fragment>
//   );
// };

// export default CareerPage;

////////////////////////////////////////////////recommendation////////////////////////////

// import React, { Fragment, useState, useEffect } from "react";
// import Header2 from "../../components/Header2/Header2";
// import { useNavigate } from "react-router-dom";
// import {
//   Container,
//   Grid,
//   Typography,
//   Box,
//   Paper,
//   Link as MUILink,
//   Divider,
//   useMediaQuery,
//   useTheme,
//   Card,
//   CardContent,
//   Button,
//   Chip,
//   IconButton
// } from "@mui/material";
// import PageTitle from "../../components/pagetitle/PageTitle";
// import Scrollbar from "../../components/scrollbar/scrollbar";
// import Footer from "../../components/footer/Footer";
// import SearchFilterSection from "./SearchFilterSection";
// import SEO from "../../components/SEO";
// import JobListingSection from "./JobListingSection";
// import Search from "./Search";
// import { Link } from "react-router-dom";
// import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
// import StarIcon from '@mui/icons-material/Star';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

// // New component for Priority Jobs Recommendation
// const PriorityJobsRecommendation = ({ jobs, handleApply }) => {
//   const theme = useTheme();
//   const scrollRef = React.useRef(null);

//   const priorityJobs = jobs.filter(job => job.priority === true);

//   if (priorityJobs.length === 0) {
//     return null;
//   }

//   const handleScrollLeft = () => {
//     if (scrollRef.current) {
//       scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
//     }
//   };

//   const handleScrollRight = () => {
//     if (scrollRef.current) {
//       scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
//     }
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 2,
//         mb: 3,
//         borderRadius: 2,
//         backgroundColor: 'background.paper',
//         position: 'relative',
//       }}
//     >
//       <Typography
//         variant="h6"
//         color="primary"
//         sx={{
//           mb: 2,
//           display: 'flex',
//           alignItems: 'center',
//           fontWeight: 600,
//           borderBottom: '2px solid',
//           borderColor: 'primary.main',
//           pb: 1,
//         }}
//       >
//         <StarIcon sx={{ mr: 1, color: theme.palette.warning.main }} />
//         Priority Openings
//         <Chip
//           label={`${priorityJobs.length} Jobs`}
//           size="small"
//           color="primary"
//           variant="outlined"
//           sx={{ ml: 2, height: '20px' }}
//         />
//       </Typography>

//       <Box sx={{ position: 'relative' }}>
//         <IconButton
//           onClick={handleScrollLeft}
//           sx={{
//             position: 'absolute',
//             left: -16,
//             top: '50%',
//             transform: 'translateY(-50%)',
//             zIndex: 2,
//             backgroundColor: 'background.paper',
//             boxShadow: 2,
//             '&:hover': {
//               backgroundColor: 'primary.light',
//             },
//           }}
//           size="small"
//         >
//           <NavigateBeforeIcon />
//         </IconButton>

//         <Box
//           ref={scrollRef}
//           sx={{
//             display: 'flex',
//             overflowX: 'auto',
//             scrollbarWidth: 'none', // Firefox
//             '&::-webkit-scrollbar': {
//               display: 'none', // Chrome, Safari, Edge
//             },
//             px: 1,
//             py: 1,
//             gap: 2,
//           }}
//         >
//           {priorityJobs.map((job) => (
//             <Card
//               key={job._id}
//               sx={{
//                 minWidth: 240,
//                 maxWidth: 280,
//                 borderLeft: '4px solid',
//                 borderColor: 'primary.main',
//                 transition: 'all 0.3s ease',
//                 '&:hover': {
//                   transform: 'translateY(-5px)',
//                   boxShadow: 4,
//                 },
//                 display: 'flex',
//                 flexDirection: 'column',
//               }}
//             >
//               <CardContent sx={{ flexGrow: 1 }}>
//                 <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
//                   <Typography
//                     variant="subtitle1"
//                     component="h3"
//                     color="text.primary"
//                     sx={{
//                       fontWeight: 600,
//                       overflow: 'hidden',
//                       textOverflow: 'ellipsis',
//                       display: '-webkit-box',
//                       WebkitLineClamp: 2,
//                       WebkitBoxOrient: 'vertical',
//                       height: '50px',
//                     }}
//                   >
//                     {job.title}
//                   </Typography>
//                   <StarIcon sx={{ color: theme.palette.warning.main, fontSize: '1rem' }} />
//                 </Box>

//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   sx={{ mb: 1 }}
//                 >
//                   {job.department}
//                 </Typography>

//                 <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1 }}>
//                   <Chip
//                     label={job.jobType}
//                     size="small"
//                     sx={{
//                       fontSize: '0.7rem',
//                       height: '20px',
//                       backgroundColor: theme.palette.primary.light,
//                       color: theme.palette.primary.contrastText,
//                     }}
//                   />
//                   <Chip
//                     label={job.location}
//                     size="small"
//                     sx={{
//                       fontSize: '0.7rem',
//                       height: '20px',
//                       backgroundColor: theme.palette.secondary.light,
//                       color: theme.palette.secondary.contrastText,
//                     }}
//                   />
//                 </Box>

//                 <Typography
//                   variant="caption"
//                   color="text.secondary"
//                   sx={{
//                     display: 'block',
//                     mb: 2,
//                     fontStyle: 'italic',
//                   }}
//                 >
//                   Experience: {job.experience}
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//   <Typography
//     variant="caption"
//     color="text.secondary"
//     sx={{
//       fontWeight: 600,
//     }}
//   >
//     Openings: {job.numberOfOpenings || 1}
//   </Typography>
// </Box>
//               </CardContent>

//               <Box sx={{ p: 1, pt: 0 }}>
//                 <Button
//                   variant="outlined"
//                   color="primary"
//                   fullWidth
//                   onClick={() => handleApply(job)}
//                   size="small"
//                 >
//                   Apply Now
//                 </Button>
//               </Box>
//             </Card>
//           ))}
//         </Box>

//         <IconButton
//           onClick={handleScrollRight}
//           sx={{
//             position: 'absolute',
//             right: -16,
//             top: '50%',
//             transform: 'translateY(-50%)',
//             zIndex: 2,
//             backgroundColor: 'background.paper',
//             boxShadow: 2,
//             '&:hover': {
//               backgroundColor: 'primary.light',
//             },
//           }}
//           size="small"
//         >
//           <NavigateNextIcon />
//         </IconButton>
//       </Box>
//     </Paper>
//   );
// };

// const LatestJobsSidebar = ({ filteredJobs }) => {
//   const theme = useTheme();
//   const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

//   // If no desktop view or no jobs, return null
//   if (!isDesktop || filteredJobs.length === 0) {
//     return null;
//   }

//   return (
//     <Grid
//       item
//       xs={12}
//       md={2}
//       lg={2}
//       sx={{
//         display: { xs: 'none', md: 'block' },
//         position: 'sticky',
//         top: '16px',
//       }}
//     >
//       <Paper
//         elevation={3}
//         sx={{
//           p: 2,
//           borderRadius: 2,
//           backgroundColor: 'background.paper',
//         }}
//       >
//         <Typography
//           variant="h6"
//           color="primary"
//           sx={{
//             mb: 2,
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'space-between',
//             fontWeight: 600,
//             borderBottom: '2px solid',
//             borderColor: 'primary.main',
//             pb: 1,
//           }}
//         >
//           <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <WorkOutlineIcon sx={{ mr: 1 }} />
//             Latest Jobs
//           </Box>
//           <Typography
//             variant="caption"
//             color="text.secondary"
//             sx={{
//               ml: 2,
//               fontSize: '0.7rem',
//               fontWeight: 400,
//             }}
//           >
//             ({filteredJobs.length} Total)
//           </Typography>
//         </Typography>

//         <Box
//           sx={{
//             overflowY: 'auto',
//             maxHeight: '400px',
//             pr: 1,
//             '&::-webkit-scrollbar': {
//               width: '6px',
//             },
//             '&::-webkit-scrollbar-thumb': {
//               backgroundColor: 'primary.light',
//               borderRadius: '3px',
//             },
//           }}
//         >
//           {filteredJobs.slice(0, 20).map((job) => (
//             <React.Fragment key={job._id}>
//               <MUILink
//                 component={Link}
//                 to={`/apply/${job._id}`}
//                 sx={{
//                   display: 'block',
//                   p: 1,
//                   borderRadius: 1,
//                   transition: 'all 0.3s ease',
//                   '&:hover': {
//                     backgroundColor: 'action.hover',
//                     transform: 'translateX(5px)',
//                     textDecoration: 'none',
//                   },
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.primary"
//                   sx={{
//                     fontWeight: 500,
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                     whiteSpace: 'nowrap',
//                   }}
//                 >
//                   {job.title}
//                 </Typography>
//                 <Typography
//                   variant="caption"
//                   color="text.secondary"
//                   sx={{
//                     display: 'block',
//                     fontSize: '0.7rem',
//                   }}
//                 >
//                   {job.department}
//                 </Typography>
//               </MUILink>
//               <Divider light />
//             </React.Fragment>
//           ))}
//         </Box>

//         {filteredJobs.length > 20 && (
//           <Typography
//             variant="caption"
//             color="text.secondary"
//             sx={{
//               display: 'block',
//               textAlign: 'center',
//               mt: 1,
//               fontStyle: 'italic'
//             }}
//           >
//             {filteredJobs.length - 20} more jobs available
//           </Typography>
//         )}
//       </Paper>
//     </Grid>
//   );
// };

// const CareerPage = () => {
//   const navigate = useNavigate();
//   const [jobs, setJobs] = useState([]);
//   const [filteredJobs, setFilteredJobs] = useState([]);
//   const [countries, setCountries] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [refreshTrigger, setRefreshTrigger] = useState(0);
//   const jobsPerPage = 25; // Updated to 25 jobs per page

//   // Filter states
//   const [search, setSearch] = useState("");
//   const [experience, setExperience] = useState("");
//   const [skillset, setSkillset] = useState("");
//   const [location, setLocation] = useState("");
//   const [jobType, setJobType] = useState("");
//   const [department, setDepartment] = useState("");
//   const [sortOrder, setSortOrder] = useState("");

//   const handleApply = (job) => {
//     navigate(`/apply/${job._id}`, { state: { job } });
//   };

//   // Fetch jobs function
//   const fetchJobs = () => {
//     fetch(`${process.env.REACT_APP_BACKEND_BASEURL}/api/jobs`)
//       .then((res) => res.json())
//       .then((data) => {
//         if (data.success) {
//           setJobs(data.data);
//           applyFilters(data.data);
//         } else {
//           console.error("Failed to fetch jobs:", data.message);
//         }
//       })
//       .catch((err) => {
//         console.error("Error fetching jobs:", err);
//         setJobs([]);
//         setFilteredJobs([]);
//       });
//   };

//   useEffect(() => {
//     fetchJobs();
//     const refreshInterval = setInterval(() => {
//       fetchJobs();
//     }, 60000);
//     return () => clearInterval(refreshInterval);
//   }, [refreshTrigger]);

//   useEffect(() => {
//     fetch("https://restcountries.com/v3.1/all")
//       .then((res) => res.json())
//       .then((data) => {
//         const countryNames = data.map((country) => country.name.common).sort();
//         setCountries(countryNames);
//       })
//       .catch((err) => {
//         console.error("Error fetching countries:", err);
//         setCountries([]);
//       });
//   }, []);

//   const experiences = ["Fresher", "Early Professional", "Mid Level", "Senior", "Super Senior"];
//   const skillsets = ["Java Dev", "Python Dev", "Full Stack Dev", "Frontend Dev", "Backend Dev", "ML Eng", "DevOps", "Cyber Sec", "Customer Service", "Operations", "Marketing", "Sales"];
//   const jobTypeOptions = ["Full Time", "Part Time", "Contract", "Consultant", "Freelancer", "Internship"];
//   const departmentOptions = ["HR", "Accounting Finance", "IT Tech Services", "Software Development", "AI & Automation", "Marketing", "Sales", "Education", "Telecom"];

//   const applyFilters = (jobsToFilter = jobs) => {
//     let filtered = jobsToFilter.filter((job) => {
//       const searchTerms = search.toLowerCase().trim();
//       const searchMatch = !searchTerms
//         ? true
//         : [job.title, job.experience, job.skillset, job.location, job.jobType, job.department].some(
//             (field) => field && field.toString().toLowerCase().includes(searchTerms)
//           );

//       const experienceMatch = !experience || job.experience === experience;
//       const skillsetMatch = !skillset || (job.skillset && job.skillset.includes(skillset));
//       const locationMatch = !location || job.location === location;
//       const jobTypeMatch = !jobType || job.jobType === jobType;
//       const departmentMatch = !department || job.department === department;

//       return searchMatch && experienceMatch && skillsetMatch && locationMatch && jobTypeMatch && departmentMatch;
//     });

//     if (sortOrder === "newest") {
//       filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
//     } else if (sortOrder === "oldest") {
//       filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
//     }

//     setFilteredJobs(filtered);
//   };

//   const clearFilters = () => {
//     setSearch("");
//     setExperience("");
//     setSkillset("");
//     setLocation("");
//     setJobType("");
//     setDepartment("");
//     setSortOrder("");
//     setFilteredJobs(jobs);
//     setCurrentPage(1);
//   };

//   useEffect(() => {
//     applyFilters();
//   }, [search, experience, skillset, location, jobType, department, sortOrder]);

//   const triggerRefresh = () => {
//     setRefreshTrigger((prev) => prev + 1);
//   };

//   // Pagination logic
//   const indexOfLastJob = currentPage * jobsPerPage;
//   const indexOfFirstJob = indexOfLastJob - jobsPerPage;
//   const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
//   const totalJobs = filteredJobs.length; // Get the total number of jobs
//   const totalPages = Math.ceil(totalJobs / jobsPerPage);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   return (
//     <Fragment>
//       <SEO
//         title="Explore Career Opportunities in Software Development & Cybersecurity"
//         description="Join UFS Networks and grow your career in software development, AI, DevOps, and cybersecurity."
//         url="https://www.ufsnetworks.com/career"
//         image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
//       />
//       <Header2 />
//       <PageTitle pageTitle={"Explore Career Opportunities"} pagesub={"Careers"} pageTop={"Join Our"} />

//       <Container maxWidth="xl" sx={{ mt: 4, mb: 8 }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={2} lg={2}>
//             <SearchFilterSection
//               search={search}
//               setSearch={setSearch}
//               experience={experience}
//               setExperience={setExperience}
//               skillset={skillset}
//               setSkillset={setSkillset}
//               location={location}
//               setLocation={setLocation}
//               jobType={jobType}
//               setJobType={setJobType}
//               department={department}
//               setDepartment={setDepartment}
//               sortOrder={sortOrder}
//               setSortOrder={setSortOrder}
//               applyFilters={() => applyFilters()}
//               clearFilters={clearFilters}
//               experiences={experiences}
//               skillsets={skillsets}
//               countries={countries}
//               jobTypeOptions={jobTypeOptions}
//               departmentOptions={departmentOptions}
//             />
//           </Grid>

//           <Grid item xs={12} md={8} lg={8}>
//             <Search onSearchResults={setFilteredJobs} />

//             {/* Display Total Jobs Available */}
//                      <Typography variant="h6" sx={{ mb: 2 }}>
//               Total Job Openings: {totalJobs}
//             </Typography>

//             {/* New Priority Jobs Recommendation Section */}
//             <PriorityJobsRecommendation jobs={jobs} handleApply={handleApply} />

//             <JobListingSection
//               jobs={currentJobs}
//               handleApply={handleApply}
//               currentPage={currentPage}
//               totalPages={totalPages}
//               handlePageChange={handlePageChange}
//               onRefreshNeeded={triggerRefresh}

//             />
//           </Grid>

//           {/* Latest Jobs Sidebar */}
//           <LatestJobsSidebar filteredJobs={filteredJobs} />
//         </Grid>
//       </Container>

//       <Footer />
//       <Scrollbar />
//     </Fragment>
//   );
// };

// export default CareerPage;

///////////////////////////////////Random jobs////////////////////////////////////

// import React, { Fragment, useState, useEffect } from "react";
// import Header2 from "../../components/Header2/Header2";
// import { useNavigate } from "react-router-dom";
// import {
//   Container,
//   Grid,
//   Typography,
//   Box,
//   Paper,
//   Link as MUILink,
//   Divider,
//   useMediaQuery,
//   useTheme,
//   Card,
//   CardContent,
//   Button,
//   Chip,
//   IconButton
// } from "@mui/material";
// import PageTitle from "../../components/pagetitle/PageTitle";
// import Scrollbar from "../../components/scrollbar/scrollbar";
// import Footer from "../../components/footer/Footer";
// import SearchFilterSection from "./SearchFilterSection";
// import SEO from "../../components/SEO";
// import JobListingSection from "./JobListingSection";
// import Search from "./Search";
// import { Link } from "react-router-dom";
// import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
// import StarIcon from '@mui/icons-material/Star';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
// import ShuffleIcon from '@mui/icons-material/Shuffle';

// // Priority Jobs Recommendation component remains unchanged
// const PriorityJobsRecommendation = ({ jobs, handleApply }) => {
//   const theme = useTheme();
//   const scrollRef = React.useRef(null);

//   const priorityJobs = jobs.filter(job => job.priority === true);

//   if (priorityJobs.length === 0) {
//     return null;
//   }

//   const handleScrollLeft = () => {
//     if (scrollRef.current) {
//       scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
//     }
//   };

//   const handleScrollRight = () => {
//     if (scrollRef.current) {
//       scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
//     }
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 2,
//         mb: 3,
//         borderRadius: 2,
//         backgroundColor: 'background.paper',
//         position: 'relative',
//       }}
//     >
//       <Typography
//         variant="h6"
//         color="primary"
//         sx={{
//           mb: 2,
//           display: 'flex',
//           alignItems: 'center',
//           fontWeight: 600,
//           borderBottom: '2px solid',
//           borderColor: 'primary.main',
//           pb: 1,
//         }}
//       >
//         <StarIcon sx={{ mr: 1, color: theme.palette.warning.main }} />
//         Priority Openings
//         <Chip
//           label={`${priorityJobs.length} Jobs`}
//           size="small"
//           color="primary"
//           variant="outlined"
//           sx={{ ml: 2, height: '20px' }}
//         />
//       </Typography>

//       <Box sx={{ position: 'relative' }}>
//         <IconButton
//           onClick={handleScrollLeft}
//           sx={{
//             position: 'absolute',
//             left: -16,
//             top: '50%',
//             transform: 'translateY(-50%)',
//             zIndex: 2,
//             backgroundColor: 'background.paper',
//             boxShadow: 2,
//             '&:hover': {
//               backgroundColor: 'primary.light',
//             },
//           }}
//           size="small"
//         >
//           <NavigateBeforeIcon />
//         </IconButton>

//         <Box
//           ref={scrollRef}
//           sx={{
//             display: 'flex',
//             overflowX: 'auto',
//             scrollbarWidth: 'none', // Firefox
//             '&::-webkit-scrollbar': {
//               display: 'none', // Chrome, Safari, Edge
//             },
//             px: 1,
//             py: 1,
//             gap: 2,
//           }}
//         >
//           {priorityJobs.map((job) => (
//             <Card
//               key={job._id}
//               sx={{
//                 minWidth: 240,
//                 maxWidth: 280,
//                 borderLeft: '4px solid',
//                 borderColor: 'primary.main',
//                 transition: 'all 0.3s ease',
//                 '&:hover': {
//                   transform: 'translateY(-5px)',
//                   boxShadow: 4,
//                 },
//                 display: 'flex',
//                 flexDirection: 'column',
//               }}
//             >
//               <CardContent sx={{ flexGrow: 1 }}>
//                 <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
//                   <Typography
//                     variant="subtitle1"
//                     component="h3"
//                     color="text.primary"
//                     sx={{
//                       fontWeight: 600,
//                       overflow: 'hidden',
//                       textOverflow: 'ellipsis',
//                       display: '-webkit-box',
//                       WebkitLineClamp: 2,
//                       WebkitBoxOrient: 'vertical',
//                       height: '50px',
//                     }}
//                   >
//                     {job.title}
//                   </Typography>
//                   <StarIcon sx={{ color: theme.palette.warning.main, fontSize: '1rem' }} />
//                 </Box>

//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   sx={{ mb: 1 }}
//                 >
//                   {job.department}
//                 </Typography>

//                 <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1 }}>
//                   <Chip
//                     label={job.jobType}
//                     size="small"
//                     sx={{
//                       fontSize: '0.7rem',
//                       height: '20px',
//                       backgroundColor: theme.palette.primary.light,
//                       color: theme.palette.primary.contrastText,
//                     }}
//                   />
//                   <Chip
//                     label={job.location}
//                     size="small"
//                     sx={{
//                       fontSize: '0.7rem',
//                       height: '20px',
//                       backgroundColor: theme.palette.secondary.light,
//                       color: theme.palette.secondary.contrastText,
//                     }}
//                   />
//                 </Box>

//                 <Typography
//                   variant="caption"
//                   color="text.secondary"
//                   sx={{
//                     display: 'block',
//                     mb: 2,
//                     fontStyle: 'italic',
//                   }}
//                 >
//                   Experience: {job.experience}
//                 </Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                   <Typography
//                     variant="caption"
//                     color="text.secondary"
//                     sx={{
//                       fontWeight: 600,
//                     }}
//                   >
//                     Openings: {job.numberOfOpenings || 1}
//                   </Typography>
//                 </Box>
//               </CardContent>

//               <Box sx={{ p: 1, pt: 0 }}>
//                 <Button
//                   variant="outlined"
//                   color="primary"
//                   fullWidth
//                   onClick={() => handleApply(job)}
//                   size="small"
//                 >
//                   Apply Now
//                 </Button>
//               </Box>
//             </Card>
//           ))}
//         </Box>

//         <IconButton
//           onClick={handleScrollRight}
//           sx={{
//             position: 'absolute',
//             right: -16,
//             top: '50%',
//             transform: 'translateY(-50%)',
//             zIndex: 2,
//             backgroundColor: 'background.paper',
//             boxShadow: 2,
//             '&:hover': {
//               backgroundColor: 'primary.light',
//             },
//           }}
//           size="small"
//         >
//           <NavigateNextIcon />
//         </IconButton>
//       </Box>
//     </Paper>
//   );
// };

// // Fixed More Jobs Sidebar that shows random jobs
// const MoreJobsSidebar = ({ allJobs, handleApply }) => {
//   const theme = useTheme();
//   const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
//   const [randomJobs, setRandomJobs] = useState([]);
//   const [refreshRandom, setRefreshRandom] = useState(0);

//   // Moved useEffect before early return to fix the React Hook rule violation
//   useEffect(() => {
//     // Function to get random jobs
//     const getRandomJobs = () => {
//       const shuffled = [...allJobs].sort(() => 0.5 - Math.random());
//       setRandomJobs(shuffled.slice(0, 15)); // Show 15 random jobs
//     };

//     getRandomJobs();
//   }, [allJobs, refreshRandom]);

//   // If no desktop view or no jobs, return null
//   if (!isDesktop || allJobs.length === 0) {
//     return null;
//   }

//   const handleRefreshRandomJobs = () => {
//     setRefreshRandom(prev => prev + 1);
//   };

//   return (
//     <Grid
//       item
//       xs={12}
//       md={2}
//       lg={2}
//       sx={{
//         display: { xs: 'none', md: 'block' },
//         position: 'sticky',
//         top: '16px',
//       }}
//     >
//       <Paper
//         elevation={3}
//         sx={{
//           p: 2,
//           borderRadius: 2,
//           backgroundColor: 'background.paper',
//         }}
//       >
//         <Typography
//           variant="h6"
//           color="primary"
//           sx={{
//             mb: 2,
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'space-between',
//             fontWeight: 600,
//             borderBottom: '2px solid',
//             borderColor: 'primary.main',
//             pb: 1,
//           }}
//         >
//           <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <WorkOutlineIcon sx={{ mr: 1 }} />
//             More Jobs
//           </Box>
//           <IconButton
//             size="small"
//             onClick={handleRefreshRandomJobs}
//             title="Refresh random jobs"
//             sx={{
//               p: 0.5,
//               '&:hover': {
//                 backgroundColor: 'primary.light',
//                 color: 'white',
//               },
//             }}
//           >
//             <ShuffleIcon sx={{ fontSize: '1rem' }} />
//           </IconButton>
//         </Typography>

//         <Box
//           sx={{
//             overflowY: 'auto',
//             maxHeight: '400px',
//             pr: 1,
//             '&::-webkit-scrollbar': {
//               width: '6px',
//             },
//             '&::-webkit-scrollbar-thumb': {
//               backgroundColor: 'primary.light',
//               borderRadius: '3px',
//             },
//           }}
//         >
//           {randomJobs.map((job) => (
//             <React.Fragment key={job._id}>
//               <MUILink
//                 component={Link}
//                 to={`/apply/${job._id}`}
//                 sx={{
//                   display: 'block',
//                   p: 1,
//                   borderRadius: 1,
//                   transition: 'all 0.3s ease',
//                   '&:hover': {
//                     backgroundColor: 'action.hover',
//                     transform: 'translateX(5px)',
//                     textDecoration: 'none',
//                   },
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   color="text.primary"
//                   sx={{
//                     fontWeight: 500,
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                     whiteSpace: 'nowrap',
//                   }}
//                 >
//                   {job.title}
//                 </Typography>
//                 <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                   <Typography
//                     variant="caption"
//                     color="text.secondary"
//                     sx={{
//                       display: 'block',
//                       fontSize: '0.7rem',
//                     }}
//                   >
//                     {job.department}
//                   </Typography>
//                   <Chip
//                     label={job.jobType}
//                     size="small"
//                     sx={{
//                       fontSize: '0.6rem',
//                       height: '16px',
//                       backgroundColor: theme.palette.primary.light,
//                       color: theme.palette.primary.contrastText,
//                     }}
//                   />
//                 </Box>
//               </MUILink>
//               <Divider light />
//             </React.Fragment>
//           ))}
//         </Box>

//         <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//           <Button
//             variant="outlined"
//             color="primary"
//             size="small"
//             startIcon={<ShuffleIcon />}
//             onClick={handleRefreshRandomJobs}
//           >
//             Show More
//           </Button>
//         </Box>
//       </Paper>
//     </Grid>
//   );
// };

// const CareerPage = () => {
//   const navigate = useNavigate();
//   const [jobs, setJobs] = useState([]);
//   const [filteredJobs, setFilteredJobs] = useState([]);
//   const [countries, setCountries] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [refreshTrigger, setRefreshTrigger] = useState(0);
//   const jobsPerPage = 25; // Updated to 25 jobs per page

//   // Filter states
//   const [search, setSearch] = useState("");
//   const [experience, setExperience] = useState("");
//   const [skillset, setSkillset] = useState("");
//   const [location, setLocation] = useState("");
//   const [jobType, setJobType] = useState("");
//   const [department, setDepartment] = useState("");
//   const [sortOrder, setSortOrder] = useState("");

//   const handleApply = (job) => {
//     navigate(`/apply/${job._id}`, { state: { job } });
//   };

//   // Fetch jobs function
//   const fetchJobs = () => {
//     fetch(`${process.env.REACT_APP_BACKEND_BASEURL}/api/jobs`)
//       .then((res) => res.json())
//       .then((data) => {
//         if (data.success) {
//           setJobs(data.data);
//           applyFilters(data.data);
//         } else {
//           console.error("Failed to fetch jobs:", data.message);
//         }
//       })
//       .catch((err) => {
//         console.error("Error fetching jobs:", err);
//         setJobs([]);
//         setFilteredJobs([]);
//       });
//   };

//   useEffect(() => {
//     fetchJobs();
//     const refreshInterval = setInterval(() => {
//       fetchJobs();
//     }, 60000);
//     return () => clearInterval(refreshInterval);
//   }, [refreshTrigger]);

//   useEffect(() => {
//     fetch("https://restcountries.com/v3.1/all")
//       .then((res) => res.json())
//       .then((data) => {
//         const countryNames = data.map((country) => country.name.common).sort();
//         setCountries(countryNames);
//       })
//       .catch((err) => {
//         console.error("Error fetching countries:", err);
//         setCountries([]);
//       });
//   }, []);

//   const experiences = ["Fresher", "Early Professional", "Mid Level", "Senior", "Super Senior"];
//   const skillsets = ["Java Dev", "Python Dev", "Full Stack Dev", "Frontend Dev", "Backend Dev", "ML Eng", "DevOps", "Cyber Sec", "Customer Service", "Operations", "Marketing", "Sales"];
//   const jobTypeOptions = ["Full Time", "Part Time", "Contract", "Consultant", "Freelancer", "Internship"];
//   const departmentOptions = ["HR", "Accounting Finance", "IT Tech Services", "Software Development", "AI & Automation", "Marketing", "Sales", "Education", "Telecom"];

//   const applyFilters = (jobsToFilter = jobs) => {
//     let filtered = jobsToFilter.filter((job) => {
//       const searchTerms = search.toLowerCase().trim();
//       const searchMatch = !searchTerms
//         ? true
//         : [job.title, job.experience, job.skillset, job.location, job.jobType, job.department].some(
//             (field) => field && field.toString().toLowerCase().includes(searchTerms)
//           );

//       const experienceMatch = !experience || job.experience === experience;
//       const skillsetMatch = !skillset || (job.skillset && job.skillset.includes(skillset));
//       const locationMatch = !location || job.location === location;
//       const jobTypeMatch = !jobType || job.jobType === jobType;
//       const departmentMatch = !department || job.department === department;

//       return searchMatch && experienceMatch && skillsetMatch && locationMatch && jobTypeMatch && departmentMatch;
//     });

//     if (sortOrder === "newest") {
//       filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
//     } else if (sortOrder === "oldest") {
//       filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
//     }

//     setFilteredJobs(filtered);
//   };

//   const clearFilters = () => {
//     setSearch("");
//     setExperience("");
//     setSkillset("");
//     setLocation("");
//     setJobType("");
//     setDepartment("");
//     setSortOrder("");
//     setFilteredJobs(jobs);
//     setCurrentPage(1);
//   };

//   useEffect(() => {
//     applyFilters();
//   }, [search, experience, skillset, location, jobType, department, sortOrder]);

//   const triggerRefresh = () => {
//     setRefreshTrigger((prev) => prev + 1);
//   };

//   // Pagination logic
//   const indexOfLastJob = currentPage * jobsPerPage;
//   const indexOfFirstJob = indexOfLastJob - jobsPerPage;
//   const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
//   const totalJobs = filteredJobs.length; // Get the total number of jobs
//   const totalPages = Math.ceil(totalJobs / jobsPerPage);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   return (
//     <Fragment>
//       <SEO
//         title="Explore Career Opportunities in Software Development & Cybersecurity"
//         description="Join UFS Networks and grow your career in software development, AI, DevOps, and cybersecurity."
//         url="https://www.ufsnetworks.com/career"
//         image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
//       />
//       <Header2 />
//       <PageTitle pageTitle={"Explore Career Opportunities"} pagesub={"Careers"} pageTop={"Join Our"} />

//       <Container maxWidth="xl" sx={{ mt: 4, mb: 8 }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={2} lg={2}>
//             <SearchFilterSection
//               search={search}
//               setSearch={setSearch}
//               experience={experience}
//               setExperience={setExperience}
//               skillset={skillset}
//               setSkillset={setSkillset}
//               location={location}
//               setLocation={setLocation}
//               jobType={jobType}
//               setJobType={setJobType}
//               department={department}
//               setDepartment={setDepartment}
//               sortOrder={sortOrder}
//               setSortOrder={setSortOrder}
//               applyFilters={() => applyFilters()}
//               clearFilters={clearFilters}
//               experiences={experiences}
//               skillsets={skillsets}
//               countries={countries}
//               jobTypeOptions={jobTypeOptions}
//               departmentOptions={departmentOptions}
//             />
//           </Grid>

//           <Grid item xs={12} md={8} lg={8}>
//             <Search onSearchResults={setFilteredJobs} />

//             {/* Display Total Jobs Available */}
//             <Typography variant="h6" sx={{ mb: 2 }}>
//               Total Job Openings: {totalJobs}
//             </Typography>

//             {/* Priority Jobs Recommendation Section */}
//             <PriorityJobsRecommendation jobs={jobs} handleApply={handleApply} />

//             <JobListingSection
//               jobs={currentJobs}
//               handleApply={handleApply}
//               currentPage={currentPage}
//               totalPages={totalPages}
//               handlePageChange={handlePageChange}
//               onRefreshNeeded={triggerRefresh}
//             />
//           </Grid>

//           {/* More Jobs Sidebar - replaced Latest Jobs with random jobs */}
//           <MoreJobsSidebar allJobs={jobs} handleApply={handleApply} />
//         </Grid>
//       </Container>

//       <Footer />
//       <Scrollbar />
//     </Fragment>
//   );
// };

// export default CareerPage;

//////////////////////////////////////////////position fixed of filter and random jobs /////////////////

import React, { Fragment, useState, useEffect } from "react";
import Header2 from "../../components/Header2/Header2";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
  Paper,
  Link as MUILink,
  Divider,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Button,
  Chip,
  IconButton,
} from "@mui/material";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import SearchFilterSection from "./SearchFilterSection";
import SEO from "../../components/SEO";
import JobListingSection from "./JobListingSection";
import Search from "./Search";
import { Link } from "react-router-dom";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import StarIcon from "@mui/icons-material/Star";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ShuffleIcon from "@mui/icons-material/Shuffle";

// Priority Jobs Recommendation component remains unchanged
const PriorityJobsRecommendation = ({ jobs, handleApply }) => {
  const theme = useTheme();
  const scrollRef = React.useRef(null);

  const priorityJobs = jobs.filter((job) => job.priority === true);

  if (priorityJobs.length === 0) {
    return null;
  }

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 2,
        backgroundColor: "background.paper",
        position: "relative",
      }}
    >
      <Typography
        variant="h6"
        color="primary"
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          fontWeight: 600,
          borderBottom: "2px solid",
          borderColor: "primary.main",
          pb: 1,
        }}
      >
        <StarIcon sx={{ mr: 1, color: theme.palette.warning.main }} />
        Urgent Hiring 🚨
        <Chip
          label={`${priorityJobs.length} Jobs`}
          size="small"
          color="primary"
          variant="outlined"
          sx={{ ml: 2, height: "20px" }}
        />
      </Typography>

      <Box sx={{ position: "relative" }}>
        <IconButton
          onClick={handleScrollLeft}
          sx={{
            position: "absolute",
            left: -16,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
            backgroundColor: "background.paper",
            boxShadow: 2,
            "&:hover": {
              backgroundColor: "primary.light",
            },
          }}
          size="small"
        >
          <NavigateBeforeIcon />
        </IconButton>

        <Box
          ref={scrollRef}
          sx={{
            display: "flex",
            overflowX: "auto",
            scrollbarWidth: "none", // Firefox
            "&::-webkit-scrollbar": {
              display: "none", // Chrome, Safari, Edge
            },
            px: 1,
            py: 1,
            gap: 2,
          }}
        >
          {priorityJobs.map((job) => (
            <Card
              key={job._id}
              sx={{
                minWidth: 240,
                maxWidth: 280,
                borderLeft: "4px solid",
                borderColor: "primary.main",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: 4,
                },
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    component="h3"
                    color="text.primary"
                    sx={{
                      fontWeight: 600,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      height: "50px",
                    }}
                  >
                    {job.title}
                  </Typography>
                  <StarIcon
                    sx={{ color: theme.palette.warning.main, fontSize: "1rem" }}
                  />
                </Box>

                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 1 }}
                >
                  {job.department}
                </Typography>

                <Box
                  sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mb: 1 }}
                >
                  <Chip
                    label={job.jobType}
                    size="small"
                    sx={{
                      fontSize: "0.7rem",
                      height: "20px",
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.primary.contrastText,
                    }}
                  />
                  <Chip
                    label={job.location}
                    size="small"
                    sx={{
                      fontSize: "0.7rem",
                      height: "20px",
                      backgroundColor: theme.palette.secondary.light,
                      color: theme.palette.secondary.contrastText,
                    }}
                  />
                </Box>

                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    display: "block",
                    mb: 2,
                    fontStyle: "italic",
                  }}
                >
                  Experience: {job.experience}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    Openings: {job.numberOfOpenings || 1}
                  </Typography>
                </Box>
              </CardContent>

              <Box sx={{ p: 1, pt: 0 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() => handleApply(job)}
                  size="small"
                >
                  Apply Now
                </Button>
              </Box>
            </Card>
          ))}
        </Box>

        <IconButton
          onClick={handleScrollRight}
          sx={{
            position: "absolute",
            right: -16,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
            backgroundColor: "background.paper",
            boxShadow: 2,
            "&:hover": {
              backgroundColor: "primary.light",
            },
          }}
          size="small"
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>
    </Paper>
  );
};

// Fixed More Jobs Sidebar that shows random jobs
const MoreJobsSidebar = ({ allJobs, handleApply }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [randomJobs, setRandomJobs] = useState([]);
  const [refreshRandom, setRefreshRandom] = useState(0);

  // Moved useEffect before early return to fix the React Hook rule violation
  useEffect(() => {
    // Function to get random jobs
    const getRandomJobs = () => {
      const shuffled = [...allJobs].sort(() => 0.5 - Math.random());
      setRandomJobs(shuffled.slice(0, 15)); // Show 15 random jobs
    };

    getRandomJobs();
  }, [allJobs, refreshRandom]);

  // If no desktop view or no jobs, return null
  if (!isDesktop || allJobs.length === 0) {
    return null;
  }

  const handleRefreshRandomJobs = () => {
    setRefreshRandom((prev) => prev + 1);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        borderRadius: 2,
        backgroundColor: "background.paper",
        position: "sticky",
        top: "100px", // This will keep the element 100px from the top when scrolling
        maxHeight: "calc(100vh - 120px)", // Limit height to viewport minus some space
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h6"
        color="primary"
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontWeight: 600,
          borderBottom: "2px solid",
          borderColor: "primary.main",
          pb: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <WorkOutlineIcon sx={{ mr: 1 }} />
          More Jobs
        </Box>
        <IconButton
          size="small"
          onClick={handleRefreshRandomJobs}
          title="Refresh random jobs"
          sx={{
            p: 0.5,
            "&:hover": {
              backgroundColor: "primary.light",
              color: "white",
            },
          }}
        >
          <ShuffleIcon sx={{ fontSize: "1rem" }} />
        </IconButton>
      </Typography>

      <Box
        sx={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 200px)", // Adjust based on your layout
          pr: 1,
          flexGrow: 1,
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "primary.light",
            borderRadius: "3px",
          },
        }}
      >
        {randomJobs.map((job) => (
          <React.Fragment key={job._id}>
            <MUILink
              component={Link}
              to={`/apply/${job._id}`}
              sx={{
                display: "block",
                p: 1,
                borderRadius: 1,
                transition: "all 0.3s ease",
                "&:hover": {
                  backgroundColor: "action.hover",
                  transform: "translateX(5px)",
                  textDecoration: "none",
                },
              }}
            >
              <Typography
                variant="body2"
                color="text.primary"
                sx={{
                  fontWeight: 500,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {job.title}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    display: "block",
                    fontSize: "0.7rem",
                  }}
                >
                  {job.department}
                </Typography>
                <Chip
                  label={job.jobType}
                  size="small"
                  sx={{
                    fontSize: "0.6rem",
                    height: "16px",
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.contrastText,
                  }}
                />
              </Box>
            </MUILink>
            <Divider light />
          </React.Fragment>
        ))}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<ShuffleIcon />}
          onClick={handleRefreshRandomJobs}
        >
          Show More
        </Button>
      </Box>
    </Paper>
  );
};

const CareerPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [countries, setCountries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const jobsPerPage = 25; // Updated to 25 jobs per page

  // Filter states
  const [search, setSearch] = useState("");
  const [experience, setExperience] = useState("");
  const [skillset, setSkillset] = useState("");
  const [location, setLocation] = useState("");
  const [jobType, setJobType] = useState("");
  const [department, setDepartment] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const handleApply = (job) => {
    navigate(`/apply/${job._id}`, { state: { job } });
  };

  // Fetch jobs function
  const fetchJobs = () => {
    fetch(`${process.env.REACT_APP_BACKEND_BASEURL}/api/jobs`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setJobs(data.data);
          applyFilters(data.data);
        } else {
          console.error("Failed to fetch jobs:", data.message);
        }
      })
      .catch((err) => {
        console.error("Error fetching jobs:", err);
        setJobs([]);
        setFilteredJobs([]);
      });
  };

  useEffect(() => {
    fetchJobs();
    const refreshInterval = setInterval(() => {
      fetchJobs();
    }, 60000);
    return () => clearInterval(refreshInterval);
  }, [refreshTrigger]);

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((res) => res.json())
      .then((data) => {
        const countryNames = data.map((country) => country.name.common).sort();
        setCountries(countryNames);
      })
      .catch((err) => {
        console.error("Error fetching countries:", err);
        setCountries([]);
      });
  }, []);

  const experiences = [
    "Fresher",
    "Early Professional",
    "Mid Level",
    "Senior",
    "Super Senior",
  ];
  const skillsets = [
    "Java Dev",
    "Python Dev",
    "Full Stack Dev",
    "Frontend Dev",
    "Backend Dev",
    "ML Eng",
    "DevOps",
    "Cyber Sec",
    "Customer Service",
    "Operations",
    "Marketing",
    "Sales",
  ];
  const jobTypeOptions = [
    "Full Time",
    "Part Time",
    "Contract",
    "Consultant",
    "Freelancer",
    "Internship",
  ];
  const departmentOptions = [
    "HR",
    "Accounting Finance",
    "IT Tech Services",
    "Software Development",
    "AI & Automation",
    "Marketing",
    "Sales",
    "Education",
    "Telecom",
  ];

  const applyFilters = (jobsToFilter = jobs) => {
    let filtered = jobsToFilter.filter((job) => {
      const searchTerms = search.toLowerCase().trim();
      const searchMatch = !searchTerms
        ? true
        : [
            job.title,
            job.experience,
            job.skillset,
            job.location,
            job.jobType,
            job.department,
          ].some(
            (field) =>
              field && field.toString().toLowerCase().includes(searchTerms)
          );

      const experienceMatch = !experience || job.experience === experience;
      const skillsetMatch =
        !skillset || (job.skillset && job.skillset.includes(skillset));
      const locationMatch = !location || job.location === location;
      const jobTypeMatch = !jobType || job.jobType === jobType;
      const departmentMatch = !department || job.department === department;

      return (
        searchMatch &&
        experienceMatch &&
        skillsetMatch &&
        locationMatch &&
        jobTypeMatch &&
        departmentMatch
      );
    });

    if (sortOrder === "newest") {
      filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (sortOrder === "oldest") {
      filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    }

    setFilteredJobs(filtered);
  };

  const clearFilters = () => {
    setSearch("");
    setExperience("");
    setSkillset("");
    setLocation("");
    setJobType("");
    setDepartment("");
    setSortOrder("");
    setFilteredJobs(jobs);
    setCurrentPage(1);
  };

  useEffect(() => {
    applyFilters();
  }, [search, experience, skillset, location, jobType, department, sortOrder]);

  const triggerRefresh = () => {
    setRefreshTrigger((prev) => prev + 1);
  };

  // Pagination logic
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
  const totalJobs = filteredJobs.length; // Get the total number of jobs
  const totalPages = Math.ceil(totalJobs / jobsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Fragment>
      <SEO
        title="Explore Career Opportunities in Software Development & Cybersecurity"
        description="Join UFS Networks and grow your career in software development, AI, DevOps, and cybersecurity."
        url="https://www.ufsnetworks.com/career"
        image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
        canonicalUrl="https://www.ufsnetworks.com/career"
      />
      <Header2 />
      <PageTitle
        pageTitle={"Explore Career Opportunities"}
        pagesub={"Careers"}
        pageTop={"Join Our"}
      />

      <Container maxWidth="xl" sx={{ mt: 4, mb: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} lg={2}>
            {/* Sticky Filter Section */}
            <Box
              sx={{
                position: "sticky",
                top: "70px", // This will keep the element 100px from the top when scrolling
                // maxHeight: 'calc(100vh - 120px)', // Limit height to viewport minus some space
                maxHeight: "100%",
              }}
            >
              <SearchFilterSection
                search={search}
                setSearch={setSearch}
                experience={experience}
                setExperience={setExperience}
                skillset={skillset}
                setSkillset={setSkillset}
                location={location}
                setLocation={setLocation}
                jobType={jobType}
                setJobType={setJobType}
                department={department}
                setDepartment={setDepartment}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                applyFilters={() => applyFilters()}
                clearFilters={clearFilters}
                experiences={experiences}
                skillsets={skillsets}
                countries={countries}
                jobTypeOptions={jobTypeOptions}
                departmentOptions={departmentOptions}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={8} lg={8}>
            <Search onSearchResults={setFilteredJobs} />

            {/* Display Total Jobs Available */}
            <Typography variant="h6" sx={{ mb: 2 }}>
              Total Job Openings: {totalJobs}
            </Typography>

            {/* Priority Jobs Recommendation Section */}
            <PriorityJobsRecommendation jobs={jobs} handleApply={handleApply} />

            <JobListingSection
              jobs={currentJobs}
              handleApply={handleApply}
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              onRefreshNeeded={triggerRefresh}
            />
          </Grid>

          {/* More Jobs Sidebar - now sticky with the component directly rather than in a Grid */}
          {isDesktop && jobs.length > 0 && (
            <Grid item xs={12} md={2} lg={2}>
              <MoreJobsSidebar allJobs={jobs} handleApply={handleApply} />
            </Grid>
          )}
        </Grid>
      </Container>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default CareerPage;
