import React, { Fragment } from "react";
import Header2 from "../../components/Header2/Header2";
import Hero2 from "../../components/hero2/hero2";
import FeaturePartners from "../../components/software-company-components/FeaturePartners";
import ServiceSection from "../../components/software-company-components/ServiceSection/ServiceSection";
import About from "../../components/software-company-components/about/about";
import ProcessTechnology from "../../components/software-company-components/ProcessTechnology/ProcessTechnology";
import FaqSection from "../../components/software-company-components/FaqSection/FaqSection";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { HelmetProvider } from "react-helmet-async";
import SEO from "../../components/SEO";
import ContactSection from "../../components/software-company-components/ContactSection";
import Footer from "../../components/software-company-components/Footer/Footer";

const HomePage2 = () => {
  return (
    <Fragment>
      <SEO
        title="UFS Networks | Reliable IT & Network Solutions for Businesses"
        description="UFS Networks provides reliable IT and network solutions, including cybersecurity, cloud services, and managed IT support to help businesses stay connected and secure."
        url="https://www.ufsnetworks.com/"
        image="https://texora.s3.ap-south-1.amazonaws.com/ufs_logo.jpg"
        canonicalUrl="https://www.ufsnetworks.com/"
      />
      <div>
        <Header2 />
        <main className="page_content">
          <Hero2 />
          <FeaturePartners />
          <ServiceSection />
          <About />
          <ProcessTechnology />
          <FaqSection />
          {/* <BlogSection /> */}
          <ContactSection />
        </main>
        <Footer />
        <Scrollbar />
      </div>
    </Fragment>
  );
};
export default HomePage2;
