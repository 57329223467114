import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "./AuthContext";
import { Visibility, VisibilityOff } from "@mui/icons-material"; // 👈 Import icons
import IconButton from "@mui/material/IconButton"; // 👈 Import button

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // 👈 State for toggle
  const navigate = useNavigate();
  const { login: authLogin } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      console.log("Sending request:", { email, password });
      console.log("Backend URL:", process.env.REACT_APP_BACKEND_BASEURL);

      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASEURL}/api/auth/login`,
        { email, password }
      );
      console.log("Response:", res.data);
      authLogin(res.data.token);
      toast.success("Login Successful");
      navigate("/dashboard");
    } catch (err) {
      console.error("Error:", err.response ? err.response.data : err.message);
      toast.error(err.response?.data?.msg || "Invalid credentials");
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="login-title">Company Login</h2>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              className="input-field"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="input-group" style={{ position: "relative" }}>
            <label>Password</label>
            <input
              type={showPassword ? "text" : "password"} // 👈 Toggle type
              className="input-field"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {/* 👁 Toggle Button */}
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              sx={{
                position: "absolute",
                right: 10,
                top: "60%",
                transform: "translateY(-50%)",
                color: "#666",
              }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </div>

          <button type="submit" className="login-btn">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
