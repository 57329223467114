import pImg1 from "../images/portfolio/portfolio_item_image_1.webp";
import pImg2 from "../images/portfolio/portfolio_item_image_2.webp";

// import pImg4 from "../images/case/case_image_1.webp";
import pImg5 from "../images/case/case_image_2.webp";
import pImg6 from "../images/case/case_image_3.webp";

import pImg7 from "../images/portfolio/portfolio_item_image_4.webp";
import pImg8 from "../images/portfolio/portfolio_item_image_5.webp";
import pImg9 from "../images/portfolio/portfolio_item_image_6.webp";
// import pImg10 from "../images/portfolio/portfolio_item_image_7.webp";
// import pImg11 from "../images/portfolio/portfolio_item_image_8.webp";
// import pImg12 from "../images/portfolio/portfolio_item_image_9.webp";
// import pImg13 from "../images/portfolio/portfolio_item_image_10.webp";
// import pImg14 from "../images/portfolio/portfolio_item_image_11.webp";
// import pImg114 from "../images/portfolio/CRM-email.webp";
import pImg15 from "../images/portfolio/CRM-features.webp";

// import tImg1 from "../images/case/icon_angular.svg";
// import tImg2 from "../images/case/icon_elephent.svg";
// import tImg3 from "../images/case/icon_netcore.svg";
// import tImg4 from "../images/case/vuejs.svg";
// import tImg5 from "../images/case/icon_python.svg";
import tImg6 from "../images/case/icon_react.svg";
import tImg7 from "../images/case/icon_ts.svg";
import tImg8 from "../images/case/icon_php.svg";
import tImg9 from "../images/case/icon_bootstrap.svg";

const Project = [
  {
    Id: "1",
    pImg: pImg1,
    title: "Mobile App Design",
    slug: "Mobile-App-Design",
    sub: "App Design",
    description:
      "Our all-encompassing IELTS Coaching curriculum encompasses every aspect...",
  },
  {
    Id: "2",
    pImg: pImg2,
    title: "TOEFL Coaching",
    slug: "TOEFL-Coaching",
    sub: "",
    description:
      "Our experienced coaches are not just educators; they are partners in your journey..",
  },

  {
    Id: "4",
    pImg: pImg1,
    title: "Mobile App Design",
    slug: "Mobile-App-Design",
    sub: "App Design",
    description:
      "Our all-encompassing IELTS Coaching curriculum encompasses every aspect...",
  },
  {
    Id: "5",
    pImg: pImg2,
    title: "TOEFL Coaching",
    slug: "TOEFL-Coaching",
    sub: "",
    description:
      "Our experienced coaches are not just educators; they are partners in your journey..",
  },

  {
    Id: "7",
    pImg: pImg15,
    title: "CRM & HRMS",
    slug: "Astarte-Medical",
    sub: "Computer Software",
    Industry: "Computer software",
    Country: "New Delhi, India",
    Technologies1: tImg6,
    Technologies2: tImg7,
    description:
      "The CRM project is a feature-rich platform offering role-based access control, task and project management, admin-approved user registration, and team collaboration tools. It includes dynamic employee data management, real-time notifications, and professional-grade invoice generation.",
  },
  {
    Id: "8",
    pImg: pImg5,
    title: "RIGZONE AI",
    slug: "CAE-Blue-Phantom",
    sub: "Ed-Tech",
    Industry: "Recruitement & Education",
    Country: "Muscat, Oman",
    Technologies1: tImg8,
    Technologies2: tImg9,
    description:
      "Rigzone AI offers high-quality, flexible online training to enhance skills and advance careers. With courses in fields like HSE and AI, it provides up-to-date knowledge and practical skills as well as provide platform for job-seekers.",
  },
  {
    Id: "9",
    pImg: pImg6,
    title: "BIDZBAY",
    slug: "Liberkeys",
    sub: "Online Marketplace",
    Industry: "E-Commerce",
    Country: "Muscat, Oman",
    Technologies1: tImg6,
    Technologies2: tImg7,
    description:
      "Bidzbay connects people and communities, creating global economic opportunities. The platform supports buyers with personalized shopping experiences and offers sellers tools to grow their businesses. By fostering real connections, Bidzbay drives meaningful change and adds value to customers, communities, and the planet.",
  },
  {
    Id: "10",
    pImg: pImg7,
    title:
      "Revolutionizing Business Efficiency: Discover the Power of CRM and ERP Solutions",
    slug: "Revolutionizing Business Efficiency: Discover the Power of CRM and ERP Solutions",
    thumb1: "CRM",
    thumb2: "Computer Software",
    category: "technology",
  },
  {
    Id: "17",
    pImg: pImg8,
    title:
      "BIDZBAY: Empowering Communities Through Global Connections and Economic Opportunities",
    slug: "BIDZBAY: Empowering Communities Through Global Connections and Economic Opportunities",
    thumb1: "Online Marketplace",
    thumb2: "E-Commerce",
    category: "e-commerce",
  },
  {
    Id: "18",
    pImg: pImg9,
    title:
      "RIGZONE AI: Transforming Careers with Flexible Online Training and Job Opportunities",
    slug: "RIGZONE AI: Transforming Careers with Flexible Online Training and Job Opportunities",
    thumb1: "Ed-Tech",
    thumb2: "Recruitement & Education",
    category: "education",
  },
  // {
  //   Id: "19",
  //   pImg: pImg10,
  //   title: "Tech Triumphs Celebrating Our Achievements in IT Solutions",
  //   slug: "Tech-Triumphs-Celebrating-Our-Achievements-in-IT-Solutions",
  //   thumb1: "Web Design",
  //   thumb2: "Logistic",
  //   category: "technology",
  // },
  // {
  //   Id: "20",
  //   pImg: pImg11,
  //   title:
  //     "Revolutionizing IT Strategies A Closer Look at Our Dynamic IT Solutions",
  //   slug: "Revolutionizing-IT-Strategies-A-Closer-Look-at-Our-Dynamic-IT-Solutions",
  //   thumb1: "Web Design",
  //   thumb2: "Fution",
  //   category: "analysis",
  // },
  // {
  //   Id: "21",
  //   pImg: pImg12,
  //   title: "Cloud Migration and Integration Project IT Solutions Portfolio",
  //   slug: "Cloud-Migration-and-Integration-Project-IT-Solutions-Portfolio",
  //   thumb1: "Web Design",
  //   thumb2: "Energy",
  //   category: "helpdesk",
  // },
  // {
  //   Id: "22",
  //   pImg: pImg13,
  //   title: "Pioneering Progress Exploring the Evolution and Impact of",
  //   slug: "Pioneering-Progress-Exploring-the-Evolution-and-Impact-of",
  //   thumb1: "Web Design",
  //   thumb2: "Health",
  //   category: "marketing",
  // },
  // {
  //   Id: "23",
  //   pImg: pImg14,
  //   title: "Unlocking Potential Explore Our Comprehensive IT Portfolio",
  //   slug: "Unlocking-Potential-Explore-Our-Comprehensive-IT-Portfolio",
  //   thumb1: "Web Design",
  //   thumb2: "Industry",
  //   category: "technology",
  // },
];

export default Project;
