import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../Login/AuthContext"; 

const Sidebar = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); 
    navigate("/login"); 
  };

  const sidebarStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "250px",
    height: "100vh",
    background: "linear-gradient(135deg, #2c2c2c, #1a1a1a)", // Dark grey gradient
    padding: "20px",
    flexDirection: "column",
    borderRight: "1px solid rgba(255, 255, 255, 0.1)",
    boxShadow: "2px 0 10px rgba(0, 0, 0, 0.3)", // Soft shadow effect
    zIndex: 1000,
    overflowY: "auto",
    display: "flex",
    justifyContent: "space-between", // 🔥 Logout button bottom me push hoga
  };

  const linkStyle = {
    textDecoration: "none",
    color: "white",
    padding: "10px",
    fontSize: "14px",
    borderRadius: "8px",
    marginBottom: "8px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    background: "rgba(255, 255, 255, 0.1)", // White transparent glass
    border: "1px solid rgba(255, 255, 255, 0.15)",
    backdropFilter: "blur(8px)",
    WebkitBackdropFilter: "blur(8px)",
  };

  return (
    <div style={sidebarStyle}>
      <div>
        <h2
          style={{
            fontSize: "18px",
            marginBottom: "15px",
            color: "#fff",
            textAlign: "center",
          }}
        >
          Admin Panel
        </h2>

        {/* 🏠 Admin Dashboard Link */}
        <Link to="/dashboard" style={{ ...linkStyle, fontWeight: "bold" }}>
          🏠 Admin Dashboard
        </Link>

        {[
          { path: "/dashboard/contact-data", label: "📩 Contact Form Data" },
          { path: "/dashboard/newsletter-data", label: "📰 Newsletter Data" },
          { path: "/dashboard/post-job", label: "📌 Post Job" },
          { path: "/dashboard/manage-jobs", label: "📋 Manage Jobs" },
          { path: "/dashboard/manage-applications", label: "📋 Manage Applications" },
          { path: "/dashboard/post-blog", label: "📌 Post Blog" },
        ].map((item, index) => (
          <Link key={index} to={item.path} style={linkStyle}>
            {item.label}
          </Link>
        ))}
      </div>

      {/* 🚪 Logout Button - */}
      <button
  onClick={handleLogout}
  style={{
    width: "100%",
    background: "#ff4d4d", 
    color: "white",
    border: "none",
    cursor: "pointer",
    textAlign: "center",
    justifyContent: "center",
    padding: "10px",
    borderRadius: "8px",
    fontSize: "12px",
    transition: "background 0.3s ease", // 🔥 Smooth Hover Effect
  }}
  onMouseOver={(e) => (e.target.style.background = "#cc0000")} 
  onMouseOut={(e) => (e.target.style.background = "#ff4d4d")} 
>
  🚪 Logout
</button>

    </div>
  );
};

export default Sidebar;

