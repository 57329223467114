import Sidebar from "../Sidebar/Sidebar.js";

const DashboardLayout = ({ children }) => {
  return (
    <div style={{ display: "flex" }}>
      {/* Sidebar (Fixed Width) */}
      <div style={{ 
        width: "250px", 
        position: "fixed", 
        height: "100vh", 
        background: "#1e293b" ,
        }}>

        <Sidebar />
      </div>

      {/* Main Content (Adjusted for Sidebar) */}
      <div style={{ 
        flex: 1, 
        padding: "20px", 
        background: "#f1f5f9", 
        minHeight: "100vh", 
        marginLeft: "250px",
        overflow: "auto"
        }}>

        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;


///////////////////////////////////////////////////

// import Sidebar from "../Sidebar/Sidebar.js";

// const DashboardLayout = ({ children }) => {
//   return (
//     <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
//       {/* Sidebar (Fixed Width) */}
//       <div
//         style={{
//           width: "250px",
//           position: "fixed",
//           height: "100vh",
//           background: "#1e293b",
//           overflowY: "auto", // Enables vertical scrolling if sidebar content exceeds the height
//         }}
//       >
//         <Sidebar />
//       </div>

//       {/* Main Content (Adjusted for Sidebar) */}
//       <div
//         style={{
//           flex: 1,
//           background: "#f1f5f9",
//           minHeight: "100vh",
//           marginLeft: "250px",
//           overflow: "auto", // Enables scrolling on both x and y axes if content overflows
//           whiteSpace: "nowrap", // Prevents text/content from wrapping in case of horizontal overflow
//         }}
//       >
//         {children}
//       </div>
//     </div>
//   );
// };

// export default DashboardLayout;
