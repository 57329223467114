import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

import cnIcon1 from "../../../images/icons/icon_javascript.svg";
import cnIcon2 from "../../../images/icons/icon_typescript.svg";
import cnIcon3 from "../../../images/icons/icon_react_js.svg";
import cnIcon4 from "../../../images/icons/icon_angular.svg";
import cnIcon5 from "../../../images/icons/icon_vue_js.svg";
import cnIcon6 from "../../../images/icons/icon_python.svg";
import cnIcon7 from "../../../images/icons/icon_php.svg";
//import cnIcon7 from "../../../images/icons/icon_g318.svg";
import cnIcon8 from "../../../images/icons/icon_ruby.svg";
import cnIcon9 from "../../../images/icons/icon_node_js.svg"
import cnIcon10 from "../../../images/icons/icon_express_js.svg"
import cnIcon11 from "../../../images/icons/icon_django.svg";
import cnIcon12 from "../../../images/icons/icon_flask.svg";
import cnIcon13 from "../../../images/icons/icon_laravel.svg";
import cnIcon14 from "../../../images/icons/icon_next_js.svg";
import cnIcon15 from "../../../images/icons/icon_spring_boot.svg";
import cnIcon16 from "../../../images/case/icon_elephent.svg";
import cnIcon17 from "../../../images/icons/icon_mysql.svg";
import cnIcon18 from "../../../images/icons/icon_mongodb.svg";
import cnIcon19 from "../../../images/icons/icon_firebase.svg";
import cnIcon20 from "../../../images/icons/icon_snowflake.svg";
import cnIcon21 from "../../../images/icons/icon_aws.svg";
import cnIcon22 from "../../../images/icons/icon_microsoft_azure.svg";
import cnIcon23 from "../../../images/icons/icon_docker.svg";
import cnIcon24 from "../../../images/icons/icon_kubernetes.svg";
import cnIcon25 from "../../../images/icons/icon_jenkins.svg";
import cnIcon26 from "../../../images/icons/icon_gitlab.svg";
import cnIcon27 from "../../../images/icons/icon_grafana.svg";
import cnIcon28 from "../../../images/icons/icon_git.svg";
import cnIcon29 from "../../../images/icons/icon_github.svg";
import cnIcon30 from "../../../images/icons/icon_bitbucket.svg";
import cnIcon31 from "../../../images/icons/icon_google_cloud.svg";
import cnIcon32 from "../../../images/icons/icon_ibm_cloud.svg";
import cnIcon33 from "../../../images/icons/icon_terraform.svg";
import cnIcon34 from "../../../images/icons/icon_swift_ios.svg";
import cnIcon35 from "../../../images/icons/icon_kotlin.svg";
import cnIcon36 from "../../../images/icons/icon_java.svg";
import cnIcon37 from "../../../images/icons/icon_react_native.svg";
import cnIcon38 from "../../../images/icons/icon_flutter.svg";
import cnIcon39 from "../../../images/icons/icon_apache_cordova.svg";
import cnIcon40 from "../../../images/icons/icon_c_plus.svg";
import cnIcon41 from "../../../images/icons/icon_scala.svg"
import cnIcon42 from "../../../images/icons/icon_go.svg";
import cnIcon43 from "../../../images/icons/icon_rust.svg";
import cnIcon44 from "../../../images/icons/icon_selenium.svg";
import cnIcon45 from "../../../images/icons/icon_tensorflow.svg";
import cnIcon46 from "../../../images/icons/icon_pytorch.svg";
import cnIcon47 from "../../../images/icons/icon_keras.svg";
import cnIcon48 from "../../../images/icons/icon_opencv.svg";
import Bg from "../../../images/shapes/shape_title_under_line.svg";

const TechnologyList = [
  //Web Platform Technologies
  { 
    Id: "1",
    sIcon: cnIcon1,
    title: "JavaScript",
  },
  {
    Id: "2",
    sIcon: cnIcon2,
    title: "Typescript",
  },
  {
    Id: "3", 
    sIcon: cnIcon3,
    title: "React JS",
  },
  {
    Id: "4", 
    sIcon: cnIcon4,
    title: "Angular",
  },
  {
    Id: "5",
    sIcon: cnIcon5,
    title: "Vue.js",
  },
  {
    Id: "6",
    sIcon: cnIcon6,
    title: "Python",
  },
  {
    Id: "7",
    sIcon: cnIcon7,
    title: "PHP",
  },
  {
    Id: "8", 
    sIcon: cnIcon8,
    title: "Ruby",
  },
  {
    Id: "9",
    sIcon: cnIcon9,
    title: "Node.js",
  },
  {
    Id: "10", 
    sIcon: cnIcon10,
    title: "Express.js",
  },
  {
    Id: "11",
    sIcon: cnIcon11,
    title: "Django",
  },
  {
    Id: "12", 
    sIcon: cnIcon12,
    title: "Flask",
  },
  {
    Id: "13", 
    sIcon: cnIcon13,
    title: "Laravel",
  },
  {
    Id: "14", 
    sIcon: cnIcon14,
    title: "Next.js",
  },
  {
    Id: "15", 
    sIcon: cnIcon15,
    title: "Spring Boot",
  },

  //Databases 
  {
    Id: "16", 
    sIcon: cnIcon16,
    title: "PostgreSQL",
  },
  {
    Id: "17", 
    sIcon: cnIcon17,
    title: "MySQL",
  },
  {
    Id: "18", 
    sIcon: cnIcon18,
    title: "MongoDB",
  },
  {
    Id: "19", 
    sIcon: cnIcon19,
    title: "Firebase Realtime",
  },
  {
    Id: "20", 
    sIcon: cnIcon20,
    title: "Snowflake",
  },

  //Cloud and DevOps
  {
    Id: "21", 
    sIcon: cnIcon21,
    title: "AWS",
  },
  {
    Id: "22", 
    sIcon: cnIcon22,
    title: "Microsoft Azure",
  },
  {
    Id: "23", 
    sIcon: cnIcon23,
    title: "Docker",
  },
  {
    Id: "24", 
    sIcon: cnIcon24,
    title: "Kubernetes",
  },
  {
    Id: "25", 
    sIcon: cnIcon25,
    title: "Jenkins",
  },
  {
    Id: "26", 
    sIcon: cnIcon26,
    title: "GitLab",
  },
  {
    Id: "27", 
    sIcon: cnIcon27,
    title: "Grafana",
  },
  {
    Id: "28", 
    sIcon: cnIcon28,
    title: "Git",
  },
  {
    Id: "29", 
    sIcon: cnIcon29,
    title: "GitHub",
  },
  {
    Id: "30", 
    sIcon: cnIcon30,
    title: "Bitbucket",
  },
  {
    Id: "31", 
    sIcon: cnIcon31,
    title: "Google Cloud Platform",
  },
  {
    Id: "32", 
    sIcon: cnIcon32,
    title: "IBM Cloud",
  },
  {
    Id: "33", 
    sIcon: cnIcon33,
    title: "Terraform",
  },

  //Mobile Apps
  {
    Id: "34", 
    sIcon: cnIcon34,
    title: "Swift (iOS)",
  },
  {
    Id: "35", 
    sIcon: cnIcon35,
    title: "Kotlin",
  },
  {
    Id: "36", 
    sIcon: cnIcon36,
    title: "Java",
  },
  {
    Id: "37", 
    sIcon: cnIcon37,
    title: "React Native",
  },
  {
    Id: "38", 
    sIcon: cnIcon38,
    title: "Flutter",
  },
  {
    Id: "39", 
    sIcon: cnIcon39,
    title: "Apache Cordova",
  },

  //Other Frameworks
  {
    Id: "40", 
    sIcon: cnIcon40,
    title: "C++",
  },
  {
    Id: "41", 
    sIcon: cnIcon41,
    title: "Scala",
  },
  {
    Id: "42", 
    sIcon: cnIcon42,
    title: "Go",
  },
  {
    Id: "43", 
    sIcon: cnIcon43,
    title: "Rust",
  },
  {
    Id: "44", 
    sIcon: cnIcon44,
    title: "Selenium",
  },
  {
    Id: "45", 
    sIcon: cnIcon45,
    title: "TensorFlow",
  },
  {
    Id: "46", 
    sIcon: cnIcon46,
    title: "PyTorch",
  },
  {
    Id: "47", 
    sIcon: cnIcon47,
    title: "Keras",
  },
  {
    Id: "48", 
    sIcon: cnIcon48,
    title: "OpenCV",
  }

];

const TechnologySection = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="section_space">
      <div className="heading_block text-center">
        <div
          className="heading_focus_text has_underline d-inline-flex"
          style={{ backgroundImage: `url(${Bg})` }}
        >
          Our Technologies
        </div>
        <h2 className="heading_text mb-0">
          We Use <mark>Technologies</mark>
        </h2>
      </div>

      <div className="tab_block_wrapper">
        <Nav tabs className="nav justify-content-center">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Web Platform
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Databases
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              Cloud & DevOps
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => {
                toggle("4");
              }}
            >
              Mobile Apps
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "5" })}
              onClick={() => {
                toggle("5");
              }}
            >
              Other Frameworks
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="web_development_technologies row justify-content-center">
              {TechnologyList.slice(0, 15).map((technology, cnt) => (
                <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={cnt}>
                  <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                    <div className="iconbox_icon">
                      <img src={technology.sIcon} alt="" />
                    </div>
                    <div className="iconbox_content">
                      <h3 className="iconbox_title mb-0">{technology.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="web_development_technologies row justify-content-center">
              {TechnologyList.slice(15, 20).map((technology, cnt) => (
                <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={cnt}>
                  <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                    <div className="iconbox_icon">
                      <img src={technology.sIcon} alt="" />
                    </div>
                    <div className="iconbox_content">
                      <h3 className="iconbox_title mb-0">{technology.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tabId="3">
            <div className="web_development_technologies row justify-content-center">
              {TechnologyList.slice(20, 33).map((technology, cnt) => (
                <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={cnt}>
                  <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                    <div className="iconbox_icon">
                      <img src={technology.sIcon} alt="" />
                    </div>
                    <div className="iconbox_content">
                      <h3 className="iconbox_title mb-0">{technology.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tabId="4">
            <div className="web_development_technologies row justify-content-center">
              {TechnologyList.slice(33, 39).map((technology, cnt) => (
                <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={cnt}>
                  <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                    <div className="iconbox_icon">
                      <img src={technology.sIcon} alt="" />
                    </div>
                    <div className="iconbox_content">
                      <h3 className="iconbox_title mb-0">{technology.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tabId="5">
            <div className="web_development_technologies row justify-content-center">
              {TechnologyList.slice(39, 48).map((technology, cnt) => (
                <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={cnt}>
                  <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                    <div className="iconbox_icon">
                      <img src={technology.sIcon} alt="" />
                    </div>
                    <div className="iconbox_content">
                      <h3 className="iconbox_title mb-0">{technology.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default TechnologySection;
