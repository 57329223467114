import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  FiDownload,
  FiTrash2,
  FiFilter,
  FiFileText,
  FiSearch,
  FiXCircle,
  FiX,
} from "react-icons/fi";
import DashboardLayout from "./DashboardLayout";
import * as XLSX from "xlsx";
import "../../css/manage-applications.css";

// Inline Paging Component
const Paging = ({ totalPages, currentPage, setCurrentPage }) => {
  if (totalPages <= 1) return null;
  return (
    <div className="pagination-container">
      <button
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
        className="pagination-button"
      >
        Prev
      </button>
      {Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index + 1}
          onClick={() => setCurrentPage(index + 1)}
          className={`pagination-button ${
            currentPage === index + 1 ? "active" : ""
          }`}
        >
          {index + 1}
        </button>
      ))}
      <button
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="pagination-button"
      >
        Next
      </button>
    </div>
  );
};

const ManageApplications = () => {
  // State variables
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useState({
    jobTitle: "",
    location: "",
    city: "",
    noticePeriod: "",
    currentCTC: "",
    expectedCTC: "",
    referralEmail: "",
    dateRange: { start: "", end: "" },
  });
  const [selectedApplications, setSelectedApplications] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  // Content style (for main content area)
  const contentStyle = {
    marginLeft: "250px",
    padding: "20px",
    // overflowX: "auto",
    overflowX: "hidden", // Change from auto to hidden
    maxWidth: "calc(100vw - 270px)", // Add max-width to prevent overflow
    width: "100%", // Ensure full width
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [applications, searchTerm, filters]);

  const fetchApplications = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Authentication required");
        setLoading(false);
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASEURL}/api/applications/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();

      if (response.ok) {
        setApplications(data.data);
        setFilteredApplications(data.data);
      } else {
        setError(data.message || "Failed to fetch applications");
      }
    } catch (err) {
      console.error("Error fetching applications:", err);
      setError("An error occurred while fetching applications");
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = () => {
    let result = [...applications];

    // Apply search term
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      result = result.filter(
        (app) =>
          app.applicantName.toLowerCase().includes(term) ||
          app.jobTitle.toLowerCase().includes(term) ||
          app.email.toLowerCase().includes(term) ||
          app.location.toLowerCase().includes(term) ||
          (app.noticePeriod && app.noticePeriod.toLowerCase().includes(term)) ||
          (app.currentCTC && app.currentCTC.toLowerCase().includes(term)) ||
          (app.expectedCTC && app.expectedCTC.toLowerCase().includes(term)) ||
          (app.referralEmail && app.referralEmail.toLowerCase().includes(term))
      );
    }

    // Apply filters
    if (filters.jobTitle) {
      result = result.filter((app) =>
        app.jobTitle.toLowerCase().includes(filters.jobTitle.toLowerCase())
      );
    }

    if (filters.location) {
      result = result.filter((app) =>
        app.location.toLowerCase().includes(filters.location.toLowerCase())
      );
    }

    if (filters.city) {
      result = result.filter((app) =>
        app.location.toLowerCase().includes(filters.city.toLowerCase())
      );
    }

    if (filters.noticePeriod) {
      result = result.filter((app) =>
        app.noticePeriod
          ?.toLowerCase()
          .includes(filters.noticePeriod.toLowerCase())
      );
    }

    if (filters.currentCTC) {
      result = result.filter((app) =>
        app.currentCTC?.toLowerCase().includes(filters.currentCTC.toLowerCase())
      );
    }

    if (filters.expectedCTC) {
      result = result.filter((app) =>
        app.expectedCTC
          ?.toLowerCase()
          .includes(filters.expectedCTC.toLowerCase())
      );
    }

    if (filters.referralEmail) {
      result = result.filter((app) =>
        app.referralEmail
          ?.toLowerCase()
          .includes(filters.referralEmail.toLowerCase())
      );
    }

    if (filters.dateRange.start && filters.dateRange.end) {
      const startDate = new Date(filters.dateRange.start);
      const endDate = new Date(filters.dateRange.end);
      endDate.setHours(23, 59, 59);

      result = result.filter((app) => {
        const appliedDate = new Date(app.appliedAt);
        return appliedDate >= startDate && appliedDate <= endDate;
      });
    }

    setFilteredApplications(result);
    setCurrentPage(1); // Reset pagination on filter change
  };

  // Pagination Logic
  const totalPages = Math.ceil(filteredApplications.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredApplications.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Other handlers (download, delete, export, select etc.)
  const handleDownloadResume = async (application) => {
    try {
      console.log("Full Application Object:", application);
      console.log("Resume URL Raw:", application.resume);

      const resumeUrl =
        application.resume ||
        application.resumeUrl ||
        application.resumePath ||
        null;

      if (!resumeUrl) {
        console.error("No resume found in application object:", {
          resume: application.resume,
          resumeUrl: application.resumeUrl,
          resumePath: application.resumePath,
        });
        alert(
          "No resume is available for this application. Please check the application details."
        );
        return;
      }

      const token = localStorage.getItem("token");
      if (!token) {
        alert("Authentication required");
        return;
      }

      let key = resumeUrl;
      if (resumeUrl.startsWith("http")) {
        try {
          const url = new URL(resumeUrl);
          key = url.pathname.substring(1);
        } catch (urlError) {
          console.warn("Invalid URL parsing:", urlError);
        }
      }

      if (!key.startsWith("Resume/")) {
        key = `Resume/${key}`;
      }

      console.log("Processed Key:", key);
      const encodedKey = encodeURIComponent(key);
      const downloadUrl = `${process.env.REACT_APP_BACKEND_BASEURL}/api/applications/resume/${encodedKey}`;

      console.log("Download URL:", downloadUrl);

      const response = await fetch(downloadUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/octet-stream",
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `HTTP error! status: ${response.status}, message: ${errorText}`
        );
      }

      const blob = await response.blob();
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = "resume.pdf";

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        );
        if (filenameMatch) {
          filename = filenameMatch[1].replace(/['"]/g, "");
        }
      } else {
        filename = key.split("/").pop() || "resume.pdf";
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      // Cleanup
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 100);
    } catch (err) {
      console.error("Comprehensive Resume Download Error:", {
        message: err.message,
        stack: err.stack,
        name: err.name,
      });

      if (err.message.includes("404")) {
        alert("Resume file not found. It may have been deleted or moved.");
      } else if (err.message.includes("403")) {
        alert("You do not have permission to download this resume.");
      } else {
        alert(`Failed to download resume: ${err.message}`);
      }
    }
  };

  const handleDeleteApplication = async (applicationId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("Authentication required");
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASEURL}/api/applications/${applicationId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setApplications(
          applications.filter((app) => app.applicationId !== applicationId)
        );
        setConfirmDelete(null);
        alert("Application deleted successfully");
      } else {
        const errorData = await response.json();
        throw new Error(
          errorData.message || `HTTP error! status: ${response.status}`
        );
      }
    } catch (err) {
      console.error("Error deleting application:", err);
      alert(`Failed to delete application: ${err.message}`);
    }
  };

  const handleBulkDeleteApplications = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("Authentication required");
        return;
      }

      const deletePromises = selectedApplications.map((appId) =>
        fetch(
          `${process.env.REACT_APP_BACKEND_BASEURL}/api/applications/${appId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
      );

      const results = await Promise.allSettled(deletePromises);
      const successCount = results.filter(
        (r) => r.status === "fulfilled" && r.value.ok
      ).length;

      if (successCount > 0) {
        setApplications(
          applications.filter(
            (app) => !selectedApplications.includes(app.applicationId)
          )
        );
        setSelectedApplications([]);
        setSelectAll(false);
        setConfirmDelete(null);
        alert(`${successCount} applications deleted successfully`);
      }

      if (successCount < selectedApplications.length) {
        alert(
          `Warning: ${
            selectedApplications.length - successCount
          } applications could not be deleted.`
        );
      }
    } catch (err) {
      console.error("Error deleting applications:", err);
      alert(`Failed to delete applications: ${err.message}`);
    }
  };

  const handleExportToExcel = () => {
    // Prepare data for export
    const exportData = filteredApplications.map((app, index) => ({
      "S.No.": index + 1,
      "Applicant Name": app.applicantName,
      Email: app.email,
      Phone: app.phone,
      "Notice Period": app.noticePeriod || "N/A",
      "Current CTC": app.currentCTC || "N/A",
      "Expected CTC": app.expectedCTC || "N/A",
      "Referral Email": app.referralEmail || "N/A",
      Qualifications:
        app.qualifications
          .map(
            (qual) =>
              `${qual.schoolName}, ${qual.degree} in ${qual.specialization}`
          )
          .join(" | ") || "N/A",
      Experience: app.experience,
      Skills: app.skills.join(", ") || "N/A",
      "Job Title": app.jobTitle,
      "Job ID": app.jobId,
      "Current Location": app.location,
      City: app.location?.split(",")?.[0] || "N/A",
      "Applied Date": format(new Date(app.appliedAt), "MMM dd, yyyy"),
      Remarks: "",
      Status: "Yes", // Default value for dropdown
    }));

    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Set column widths
    const colWidths = [
      { wch: 8 }, // S.No.
      { wch: 35 }, // Applicant Name
      { wch: 35 }, // Email
      { wch: 20 }, // Phone
      { wch: 15 }, // Notice Period
      { wch: 15 }, // Current CTC
      { wch: 15 }, // Expected CTC
      { wch: 35 }, // Referral Email
      { wch: 80 }, // Qualifications
      { wch: 15 }, // Experience Range
      { wch: 60 }, // Skills
      { wch: 25 }, // Job Title
      { wch: 25 }, // Job ID
      { wch: 35 }, // Current Location
      { wch: 25 }, // City
      { wch: 20 }, // Applied Date
      { wch: 20 }, // Remarks
      { wch: 15 }, // Status
    ];

    ws["!cols"] = colWidths;

    // Add dropdown for Status column
    // First, find the column index for the Status field
    const range = XLSX.utils.decode_range(ws["!ref"]);
    let statusColIndex = -1;

    // Find the column index for "Status"
    const headerRow = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];
    statusColIndex = headerRow.findIndex((col) => col === "Status");

    if (statusColIndex !== -1) {
      // Convert to column letter (A, B, C, etc.)
      const statusCol = XLSX.utils.encode_col(statusColIndex);

      // Define validation for the Status column (from row 2 to last row)
      const validation = {
        type: "list",
        allowBlank: false,
        formula1: '"Yes,No,Maybe"',
        showDropDown: true,
      };

      // If validation property doesn't exist yet, create it
      if (!ws["!dataValidation"]) ws["!dataValidation"] = [];

      // Add validation to each cell in the Status column (except header)
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        const address = statusCol + (row + 1);
        ws["!dataValidation"].push({ sqref: address, ...validation });
      }
    }

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, "Applications");

    // Generate and download file
    XLSX.writeFile(
      wb,
      `Applications_${format(new Date(), "yyyy-MM-dd_HH-mm-ss")}.xlsx`
    );
  };

  const handleSelectApplication = (applicationId) => {
    if (selectedApplications.includes(applicationId)) {
      setSelectedApplications(
        selectedApplications.filter((id) => id !== applicationId)
      );
    } else {
      setSelectedApplications([...selectedApplications, applicationId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedApplications([]);
    } else {
      setSelectedApplications(
        filteredApplications.map((app) => app.applicationId)
      );
    }
    setSelectAll(!selectAll);
  };

  const resetFilters = () => {
    setSearchTerm("");
    setFilters({
      jobTitle: "",
      location: "",
      city: "",
      noticePeriod: "",
      currentCTC: "",
      expectedCTC: "",
      referralEmail: "",
      dateRange: { start: "", end: "" },
    });
    setFilterOpen(false);
  };

  // Function to extract city from location string
  const extractCity = (location) => {
    if (!location) return "N/A";
    const parts = location.split(",");
    return parts[0] ? parts[0].trim() : "N/A";
  };

  if (loading) {
    return (
      <DashboardLayout>
        <div style={contentStyle} className="loading-container">
          <div className="loading-spinner"></div>
          <p>Loading applications...</p>
        </div>
      </DashboardLayout>
    );
  }

  if (error) {
    return (
      <DashboardLayout>
        <div style={contentStyle} className="error-message">
          <div className="error-icon">⚠️</div>
          <p>{error}</p>
          <button className="retry-button" onClick={fetchApplications}>
            Retry
          </button>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      {/* <div style={contentStyle}> */}
      <div style={{ overflow: "hidden", minHeight: "100vh", padding: "20px", background: "linear-gradient(135deg, #f3e5f5, #e1f5fe)", color: "#333" }}>
        <div className="applications-container">
          <div className="applications-header">
            <h2 className="applications-heading">📋 Manage Applications</h2>
            <div className="applications-actions">
              <button
                className="action-button excel-button"
                onClick={handleExportToExcel}
                disabled={filteredApplications.length === 0}
              >
                <FiFileText /> Export to Excel
              </button>
              {selectedApplications.length > 0 && (
                <button
                  className="action-button delete-button"
                  onClick={() => setConfirmDelete("bulk")}
                >
                  <FiTrash2 /> Delete Selected ({selectedApplications.length})
                </button>
              )}
            </div>
          </div>

          <div className="search-filter-container">
            <div className="search-box">
              <FiSearch className="search-icon" />
              <input
                type="text"
                placeholder="Search applications..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {searchTerm && (
                <FiXCircle
                  className="clear-search"
                  onClick={() => setSearchTerm("")}
                />
              )}
            </div>

            <button
              className={`filter-button ${filterOpen ? "active" : ""}`}
              onClick={() => setFilterOpen(true)}
            >
              <FiFilter /> Filter
            </button>

            {(filters.jobTitle ||
              filters.location ||
              filters.city ||
              filters.noticePeriod ||
              filters.currentCTC ||
              filters.expectedCTC ||
              filters.referralEmail ||
              filters.dateRange.start) && (
              <button className="reset-filter-button" onClick={resetFilters}>
                Clear Filters
              </button>
            )}
          </div>

          {/* Filter Modal Popup */}
          {filterOpen && (
            <div className="modal-overlay">
              <div className="filter-modal">
                <div className="modal-header">
                  <h3>Filter Applications</h3>
                  <FiX
                    className="close-icon"
                    onClick={() => setFilterOpen(false)}
                  />
                </div>
                <div className="modal-body">
                  <div className="filter-group">
                    <label>Job Title</label>
                    <input
                      type="text"
                      value={filters.jobTitle}
                      onChange={(e) =>
                        setFilters({ ...filters, jobTitle: e.target.value })
                      }
                      placeholder="Filter by job title"
                    />
                  </div>
                  <div className="filter-group">
                    <label>Location</label>
                    <input
                      type="text"
                      value={filters.location}
                      onChange={(e) =>
                        setFilters({ ...filters, location: e.target.value })
                      }
                      placeholder="Filter by location"
                    />
                  </div>
                  <div className="filter-group">
                    <label>City</label>
                    <input
                      type="text"
                      value={filters.city}
                      onChange={(e) =>
                        setFilters({ ...filters, city: e.target.value })
                      }
                      placeholder="Filter by city"
                    />
                  </div>
                  <div className="filter-group">
                    <label>Notice Period</label>
                    <input
                      type="text"
                      value={filters.noticePeriod}
                      onChange={(e) =>
                        setFilters({ ...filters, noticePeriod: e.target.value })
                      }
                      placeholder="Filter by notice period"
                    />
                  </div>
                  <div className="filter-group">
                    <label>Current CTC</label>
                    <input
                      type="text"
                      value={filters.currentCTC}
                      onChange={(e) =>
                        setFilters({ ...filters, currentCTC: e.target.value })
                      }
                      placeholder="Filter by current CTC"
                    />
                  </div>
                  <div className="filter-group">
                    <label>Expected CTC</label>
                    <input
                      type="text"
                      value={filters.expectedCTC}
                      onChange={(e) =>
                        setFilters({ ...filters, expectedCTC: e.target.value })
                      }
                      placeholder="Filter by expected CTC"
                    />
                  </div>
                  <div className="filter-group">
                    <label>Referral Email</label>
                    <input
                      type="text"
                      value={filters.referralEmail}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          referralEmail: e.target.value,
                        })
                      }
                      placeholder="Filter by referral email"
                    />
                  </div>
                  <div className="filter-group date-range">
                    <label>Application Date Range</label>
                    <div className="date-inputs">
                      <input
                        type="date"
                        value={filters.dateRange.start}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            dateRange: {
                              ...filters.dateRange,
                              start: e.target.value,
                            },
                          })
                        }
                      />
                      <span>to</span>
                      <input
                        type="date"
                        value={filters.dateRange.end}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            dateRange: {
                              ...filters.dateRange,
                              end: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="apply-button"
                    onClick={() => setFilterOpen(false)}
                  >
                    Apply Filters
                  </button>
                  <button className="reset-button" onClick={resetFilters}>
                    Reset
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="applications-stats">
            <div className="stat-item">
              <span className="stat-label">Total Applications</span>
              <span className="stat-value">{applications.length}</span>
            </div>
            {applications.length !== filteredApplications.length && (
              <div className="stat-item">
                <span className="stat-label">Filtered Results</span>
                <span className="stat-value">
                  {filteredApplications.length}
                </span>
              </div>
            )}
          </div>

          {filteredApplications.length > 0 ? (
            <div className="table-container">
              <table className="applications-table">
                <thead>
                  <tr>
                    <th className="checkbox-column">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th>Job Title</th>
                    <th>Applicant Name</th>
                    <th>Contact Details</th>
                    <th>Location</th>
                    <th>City</th>
                    <th>Notice Period</th>
                    <th>Current CTC</th>
                    <th>Expected CTC</th>
                    <th>Referral Email</th>
                    <th>Applied On</th>
                    <th>Resume</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((application) => (
                    <tr key={application.applicationId}>
                      <td className="checkbox-column">
                        <input
                          type="checkbox"
                          checked={selectedApplications.includes(
                            application.applicationId
                          )}
                          onChange={() =>
                            handleSelectApplication(application.applicationId)
                          }
                        />
                      </td>
                      <td className="job-title">{application.jobTitle}</td>
                      <td>{application.applicantName}</td>
                      <td>
                        <div className="contact-details">
                          <div className="email">{application.email}</div>
                          <div className="phone-number">
                            {application.phone}
                          </div>
                        </div>
                      </td>
                      <td>{application.location}</td>
                      <td>{extractCity(application.location)}</td>
                      <td>{application.noticePeriod || "N/A"}</td>
                      <td>{application.currentCTC || "N/A"}</td>
                      <td>{application.expectedCTC || "N/A"}</td>
                      <td>{application.referralEmail || "N/A"}</td>
                      <td>
                        {format(
                          new Date(application.appliedAt),
                          "MMM dd, yyyy"
                        )}
                      </td>
                      <td>
                        <button
                          className="download-button"
                          onClick={() => handleDownloadResume(application)}
                          title="Download Resume"
                        >
                          <FiDownload /> Resume
                        </button>
                      </td>
                      <td>
                        <button
                          className="delete-action-button"
                          onClick={() =>
                            setConfirmDelete(application.applicationId)
                          }
                          title="Delete Application"
                        >
                          <FiTrash2 />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="no-applications">
              <div className="empty-state-icon">📭</div>
              <p>
                No applications found
                {searchTerm ||
                filters.jobTitle ||
                filters.location ||
                filters.city ||
                filters.noticePeriod ||
                filters.currentCTC ||
                filters.expectedCTC ||
                filters.referralEmail ||
                filters.dateRange.start
                  ? " matching your filters"
                  : ""}
                .
              </p>
              {(searchTerm ||
                filters.jobTitle ||
                filters.location ||
                filters.city ||
                filters.noticePeriod ||
                filters.currentCTC ||
                filters.expectedCTC ||
                filters.referralEmail ||
                filters.dateRange.start) && (
                <button className="clear-filter-button" onClick={resetFilters}>
                  Clear Filters
                </button>
              )}
            </div>
          )}

          {/* Render Paging component if needed */}
          {filteredApplications.length > itemsPerPage && (
            <Paging
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>

      {confirmDelete && (
        <div className="delete-confirmation-modal">
          <div className="delete-confirmation-content">
            <h3>Confirm Deletion</h3>
            <p>
              {confirmDelete === "bulk"
                ? `Are you sure you want to delete ${selectedApplications.length} selected applications?`
                : "Are you sure you want to delete this application?"}
            </p>
            <p className="warning">This action cannot be undone.</p>
            <div className="delete-confirmation-actions">
              <button
                className="cancel-button"
                onClick={() => setConfirmDelete(null)}
              >
                Cancel
              </button>
              <button
                className="confirm-delete-button"
                onClick={() => {
                  if (confirmDelete === "bulk") {
                    handleBulkDeleteApplications();
                  } else {
                    handleDeleteApplication(confirmDelete);
                  }
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default ManageApplications;
